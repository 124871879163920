import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule } from 'ion2-calendar';
import { CancelBookingComponent } from './cancel-modal/cancel-booking.component';
import { CommonModule } from '@angular/common';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxMaskModule } from 'ngx-mask';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

import { BackButtonComponent } from './back-button/back-button.component';
import { BookingConfirmedComponent } from './booking-confirmed/booking-confirmed.component';
import { DeleteCreditCardComponent } from './delete-credit-card/delete-credit-card.component';
import { EditCreditCardComponent } from './edit-credit-card/edit-credit-card.component';
import { RateModalComponent } from './rate-modal/rate-modal.component';
import { RegistrationComponent } from './registration/registration.component';
import { UnlockOfficeModalComponent } from './unlock-office-modal/unlock-office-modal.component';
import { SearchComponent } from './search-component/search-component';
import { StripeModalComponent } from './stripe-modal/stripe-modal.component';
import { TermsComponent } from './terms/terms.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TypeOfRentComponent } from './type-of-rent/type-of-rent.component';
import { ExtendBookingComponent } from './extend-booking/extend-booking.component';
import { LikeComponent } from './like/like.component';
import { RatingComponent } from './rating/rating.component';
import { LoginComponent } from './login/login.component';
import { PhoneConfirmationModalComponent } from './phone-confirmation-modal/phone-confirmation-modal.component';
import { RequiredDataModalComponent } from './required-data-modal/required-data-modal.component';
import { DeleteAccountModalComponent } from './delete-account-modal/delete-account-modal.component';
import { AvatarInitialsComponent } from './avatar-initials/avatar-initials.component';
import { DownloadFromStorePopupComponent } from './download-from-store-popup/download-from-store-popup.component';
import { RefundBeforeDeleteAccAlertComponent } from './refund-before-delete-acc-alert/refund-before-delete-acc-alert.component';
import { BuildingsDropdownComponent } from './buildings-dropdown/buildings-dropdown.component';
import { OraHeaderComponent } from './ora-header/ora-header.component';

@NgModule({
    declarations: [
        TimePickerComponent,
        SearchComponent,
        LikeComponent,
        RatingComponent,
        TypeOfRentComponent,
        RegistrationComponent,
        LoginComponent,
        BookingConfirmedComponent,
        UnlockOfficeModalComponent,
        TermsComponent,
        RateModalComponent,
        StripeModalComponent,
        DeleteCreditCardComponent,
        EditCreditCardComponent,
        BackButtonComponent,
        CancelBookingComponent,
        ExtendBookingComponent,
        PhoneConfirmationModalComponent,
        RequiredDataModalComponent,
        DeleteAccountModalComponent,
        AvatarInitialsComponent,
        DownloadFromStorePopupComponent,
        RefundBeforeDeleteAccAlertComponent,
        BuildingsDropdownComponent,
        OraHeaderComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        CreditCardDirectivesModule,
        NgxStripeModule.forRoot('pk_live_51J5AwWBUUjkn5Dav00AEHyveUqVAkZwq0a8yemVauGP3x8WF1eothe5RHYCEWB67VYO3zlhfajRfGgCphey13HwA00XcVnVeN4'),
        NgxMaskModule.forRoot(),
        MatSelectModule,
        MatOptionModule
    ],
    exports: [
        TimePickerComponent,
        TypeOfRentComponent,
        SearchComponent,
        LikeComponent,
        RatingComponent,
        BackButtonComponent,
        CancelBookingComponent,
        DeleteAccountModalComponent,
        AvatarInitialsComponent,
        DownloadFromStorePopupComponent,
        RefundBeforeDeleteAccAlertComponent,
        BuildingsDropdownComponent,
        MatSelectModule,
        MatOptionModule,
        OraHeaderComponent
    ]
})
export class ComponentsModule { }
