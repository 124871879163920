/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  errorText;

  cardValidators = {
    validateCardHolderName: Validators.compose([Validators.required]),
    validateCCNumber: CreditCardValidators.validateCCNumber,
    validateExpDate: CreditCardValidators.validateExpDate,
    validateCvvCode: [Validators.required, Validators.minLength(3), Validators.maxLength(4)]
  };

  constructor() { }

  validatePassword() {
    return (control: UntypedFormControl) => {
      const regExpPassword = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      if (regExpPassword.test(control.value)) {
        return null;
      } else {
        return this.errorText = {
          passwordError: 'Must have at least one uppercase, one lowercase character and one number'
        };
      }
    };
  }

  validateEmail() {
    return (control: UntypedFormControl) => {
      const regExpEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regExpEmail.test(control.value)) {
        return null;
      } else {
        return this.errorText = {
          emailError: 'Email is invalid',
        };
      }
    };
  }

  validatePhone() {
    return (control: UntypedFormControl) => {
      const regExpPhone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

      if (regExpPhone.test(control.value)) {
        return null;
      } else {
        return this.errorText = {
          phoneError: 'Phone is invalid',
        };
      }
    };
  }

  validateConfirmPassword(controlName, matchingControlName) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmPasswordValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

}
