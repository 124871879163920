import { Component, OnInit } from '@angular/core';
import { StepSelectionService } from '../../services/step-selection/step-selection.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {

  constructor(
    public stepSelectionService: StepSelectionService
  ) { }

  ngOnInit() { }

}
