/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';

import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

  @Input() options: string;

  public descriptionItem = [];
  public prohibitedActivitiesList = [];
  public servicesList: {
    title: string;
    text: string;
    isOptional: boolean;
  }[] = [];
  public paymentsList: {
    title: string;
    text: string;
  }[] = [];
  public disclaimersList: {
    title: string;
    text: string;
  }[] = [];
  public limitationOfServicesList: {
    title: string;
    textStart: string;
    textEnd?: string;
    optionalList?: string[];
  }[] = [];
  public generalProvisionsList: {
    title: string;
    text: string;
  }[] = [];
  public personallyInformationList: {
    title: string,
    textStart: string;
    textEnd?: string;
    optionalList?: string[]
  }[] = [];
  public protectingInformationList: {
    title: string,
    textStart: string;
    textEnd?: string;
    optionalList?: string[]
  }[] = [];

  constructor(public modalService: ModalService) { }

  ngOnInit() {
    this.dataTermsList();
    this.dataPolicyList();
  }

  goBackToSignUp() {
    this.modalService.dismiss();
    import('../registration/registration.component').then((m) =>
      this.modalService.presentModal(m.RegistrationComponent, 'fixed-height')
    );
  }

  dataTermsList() {
    this.descriptionItem = [
      'This terms of service agreement ("Agreement") is between Ora App, LLC, a Delaware corporation ("we," "us," "our," "ourselves," or "Company") and any person or entity ("you" or "your") using our services; any of our websites or apps; or any features, products, graphics, text, images, photos, audio, video, location data, computer code, and all other forms of data and communications (collectively, "Services").',
      'BY USING THE SERVICES IN ANY WAY, YOU AGREE TO BE BOUND BY THIS AGREEMENT AND ITS TERMS IN FULL. If you do not accept and agree to be bound by all of the terms of this Agreement, including the Privacy Policy, posted at www.byoraprivateoffices.com/privacy-policy and incorporated by reference herein, you agree not use the Services.',
      'The terms of this Agreement are subject to amendment at any time. It is your responsibility to check periodically for any   amendments. Continued use of the Services shall constitute acceptance of any amendments made to this Agreement.'
    ];

    this.prohibitedActivitiesList = [
      'Reproduce any part of the Services in any form or by any means, except as expressly permitted in this Agreement or otherwise in writing by our authorized agent;',
      'Copy, modify, rent, lease, loan, sell, distribute, or create derivative works, products, or services based on the Services in any manner, and you shall not exploit the Services in any unauthorized way;',
      'Remove, alter, reproduce, copy, or obscure any copyright, trademark, service mark of Ora App, LLC;',
      'Use the Services for any unlawful activities or in violation of any laws, regulations, or contractual provisions, or to induce others to do or engage in the same;',
      'Use the Services to promote, describe, incite, or convey violence, degradation, subjugation, discrimination or hatred against individuals or groups based on race, ethnic origin, religion, disability, gender, age, veteran status, sexual orientation, or gender identity;',
      `Access another's account without permission of us or that person;`,
      'Publish or allow to be published malicious code intended to damage any mobile device, browser, computer, server, or network hardware;',
      'Upload any content (including profile photographs) that contains nudity, pornography, or salacious material;',
      'Upload any content that you are not the rightful owner of, or, if you are not the rightful owner, for which you have not obtained prior permission or license from the owner;',
      'Engage in unauthorized or unsolicited advertising, spamming comment sections, or sending bulk e- mails;',
      'Use manual or automated software, devices, or other processes to "crawl," "scrape," or "spider" any',
      'Decompile, reverse engineer, deactivate, bypass, disassemble, or otherwise attempt to obtain the source code of the Services;',
      `Solicit passwords or personal identifying information for commercial or unlawful purposes from others or disseminate another person's personal information without that person's permission;`,
      'Impersonate any person or entity, including (1) any Company employee or representative or (2) any other person;',
      'Engage in any activity that is commercial in nature (whether or not for profit) such as, without limitation, re-sale of the Services or letting other individuals who have not registered for the Services use the Services under your account; or',
      'Breach or break any applicable law or regulation, the rights of third-parties, or our Agreement, policies, or standards.',
    ];

    this.servicesList = [
      {
        title: 'Your Representations and Eligibility to Use Services',
        text: 'By using the Services, you represent and warrant: (i) you have the authority and capacity to enter this Agreement; (ii) you are at least 18 years old,*; and, (iii) you are not precluded or restricted in any way from using the Services, either by law or due to previous suspension from the Services.',
        isOptional: false,
      },
      {
        title: 'Truthfulness of Information',
        text: 'You represent and warrant that all information you submit when using the Services is complete, accurate, and truthful. You are responsible formaintaining the completeness, accuracy, and truthfulness of such information.',
        isOptional: false,
      },
      {
        title: 'Limited Use of Services; Our Intellectual Property',
        text: `The Services are only for the uses specified in this Agreement. You
        agree that you will not use our proprietary information or materials in
        any way whatsoever except for use of the Services in compliance with
        this Agreement. We reserve the right to investigate and take legal
        action in response to illegal and/or unauthorized uses of the Services
        including, without limitation, seeking injunctive relief without the
        necessity of posting a bond. Our Services and their contents, or any
        other materials produced by us, may in their entirety or in part be
        protected by patent, copyright, trademark, and/or other laws of the
        United States and other countries. You acknowledge and agree that our
        Services, website, app, and content, including all associated
        intellectual property rights, are the exclusive property of Ora App LLC
        and/or our licensors or authorizing third- parties. In no way should
        your use of the Services be construed to diminish our intellectual
        property rights or be construed as a license or the ability to use the
        Services in any context other than as expressly permitted under this
        Agreement. Any other trademarks, service marks, logos, trade names or
        other proprietary designations of third-parties used on or in connection
        with our Services are used for identification purposes only and may be
        the property of their respective owners.`,
        isOptional: false,
      },
      {
        title: 'Permitted Activities',
        text: 'Our Services may only be used by you in accordance with the terms and conditions herein.',
        isOptional: true,
      },
    ];

    this.paymentsList = [
      {
        title: 'Payments and Fees',
        text: 'Our Services are provided to you through the ORA app which you have contracted with directly. We reserve the right, from time-to-time and in our sole discretion, to release upgrades or add-ons for our Services payable directly by Members who wish to subscribe to the same. Should you choose to enroll in any Subscription Products, your payment and fees for those Subscription Products will be covered in separate terms and conditions.',
      },
      {
        title: 'Automatic Monthly Renewal',
        text: 'Once you sign up for our Services, we will automatically process your monthly subscription fee using the billing information on file. We will process your monthly subscription fee for each billing cycle at the then- current rates until you cancel in accordance with this Agreement. You will be billed each month on the day you sign up. If you sign up or are being billed on the 29th-31st, you will be billed on the last day of the next month and then that day thereafter (and, on and after February, the 28th of each month). You must cancel your subscription prior to your billing day.',
      },
      {
        title: 'Third-Party Payment Services; PSD2 Compliance',
        text: 'We utilize third-party payment services to handle transactions through our Services. If you have any issue with charges, those issues need to be addressed directly between yourself and the third-party payment service. We are not responsible for the payment processing or any related disputes. We do no issue refunds for our Services or Subscription Products—this includes where you pay for a subscription period then cancel that subscription during the subscription period.',
      },
    ];

    this.disclaimersList = [
      {
        title: 'No Guarantees, Endorsements, or Investigation',
        text: 'The Company does not provide any guarantees or endorsements of any third-party or user, or its content or links, provided through the Services. The Company does not investigate or otherwise review any user or third- party or its content. You accept all risks associated with any third-party, and its content, links, or related information. You agree not to hold the Company liable for any conduct, product, service, integration with, or content of third parties or other users of our Services.',
      },
      {
        title: 'Relationship between Us and Members.',
        text: 'We do not operate or control the spaces and cannot control those occupying those spaces. As such, you agree we are not and cannot be held responsible or liable, whether directly or indirectly, in any disputes, claims, damages, or causes of action you have with other Members, or other users of our Services.',
      },
      {
        title: 'Disclaimer of Warranties',
        text: 'All information and services are provided on an "as is" basis without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not make any warranty that access to the Services will be uninterrupted, secure, complete, error free, or meet your particular requirements. In addition, we do not make any warranty as to the quality or reliability of integrations of our Services into any third-party platforms, products, or services, nor do we guarantee any integrations will be continuous or error-free.',
      },
      {
        title: 'Waiver of Liability',
        text: 'You waive any liability of or claims against us for any injuries or damages (including compensatory, punitive, special, or consequential damages) you sustain as a result of or associated with using the Services, regardless of whether we were informed of the possibility of such injuries or damages and regardless of whether they were reasonably foreseeable. You waive any claim or liability stemming from our negligence. Where our Services incorporate or utilize any information, software, or content of a third party, you waive any liability or claim against us based upon that information, software, or content—including based upon the negligence of that third party.',
      },
      {
        title: 'Scope of Waiver',
        text: 'You understand and agree the above waiver extends to any claim of any nature or kind, known or unknown, suspected or unsuspected regardless of when the claim first existed This includes waiver of a claim of the type identified under California Civil Code, Section 1542, which provides: “A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor."',
      },
      {
        title: 'California Notice',
        text: 'The following notice is for California users: Pursuant to California Civil Code Section 1789.3: If you have a question or complaint about us, our products, or our Services please contact us at INSERT EMAIL. You may also contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer affairs by telephone at (800) 952-5210 or by mail at the Department of Consumer Affairs, Consumer Information Division, 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834.',
      },
      {
        title: 'Indemnification',
        text: `If we are subject to a complaint, a demand, mediation, arbitration, litigation, or other pursuit based upon your conduct relating to or from use of our Services or your dispute, claim, or cause of action from or against any third party or other user, you agree to indemnify us for all costs, expenses, and fees (including attorneys' fees by the attorneys of our choice) we expend in defending ourselves against or otherwise addressing such pursuit. Such conduct or use includes, but is not limited to, your violation of this Agreement; your use of the Services and any consequences that produces; your dispute, claim, or cause of action from or against any third party or other user; your use of intellectual property on or through the Services; and, any such actions performed by your agent or representative, or at your direction. You agree to indemnify us as soon as we incur the costs, expenses, and fees, whether those are billed by event, or weekly, monthly, or some other interval.`,
      },
    ];

    this.limitationOfServicesList = [
      {
        title: 'Right to Remove Content',
        textStart:
          'We reserve an unrestricted right to remove content at any time without advanced notice.',
      },
      {
        title: 'Right and Grounds to Terminate Access',
        textStart:
          'To protect us and our users, we reserve an unrestricted right to refuse, terminate, block, or cancel your application to, account with, or access to the Services at any time, with or without cause. You acknowledge here that you have no right: to use of the Services should we terminate or suspend your account. Primary reasons warranting termination include (and primary reasons leading to suspension pending investigation of claims or evidence of the following):',
        textEnd:
          'You neither possess nor retain any ownership of or rights to the Services, regardless of who generated the content. Upon termination, you have no right to access, retain, or have returned any such information.',
        optionalList: [
          'Upon submission, your feedback, submissions, and their contents along with all intellectual property rights ("Feedback") becomes the sole property of ORA App LLC;',
          'We may use or redistribute Feedback for any purpose and in any way on an unrestricted basis;',
          'We have no obligation to review Feedback; and,',
          'We have no obligation to keep any Feedback confidential.',
        ],
      },
      {
        title: 'Grant of License to User-Generated Content',
        textStart:
          'You hereby grant to us a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, modify, publish, translate and distribute any content that you submit in any form to our Services. You also grant to us the right to sub-license these rights, and the right to bring an action for infringement of these rights.',
      },
      {
        title: 'Feedback and Unsolicited Idea Submission Policy',
        textStart:
          'We encourage feedback and ideas for improvements to our Services. You agree that:',
      },
      {
        title: 'Survival',
        textStart:
          'After termination, we retain all rights to content as specified in this Agreement. Sections II—VI of this Agreement survive after termination.',
      },
    ];

    this.generalProvisionsList = [
      {
        title: 'DMCA Violations',
        text: 'If you believe any of our content infringes on your copyright, you may request removal by contacting the following address: EMAIL ADDRESS FOR DMCA COMPLAINT. We will respond to all requests promptly and to the best of our ability.',
      },
      {
        title: 'Successors and Assignees',
        text: 'We may assign this Agreement to an affiliate or in connection with a merger or sale of all or substantially all of our corresponding assets. You may not assign this Agreement. You agree that any waiver or protections afforded to us are also provided to our affiliates, directors, officers, principals, employees, agents, and successors in their roles and relationship with us. You also acknowledge that all waivers and agreements bind not only you, but any successors, heirs, agents, and other representatives.',
      },
      {
        title: 'Arbitration',
        text: 'The exclusive means of resolving any dispute or claim arising out of or relating to this Agreement (including any alleged breach thereof) or the Service shall be binding arbitration administered by the American Arbitration Association. You may not under any circumstances commence or maintain against us any class action, class arbitration, or other representative action or proceeding. You understand and accept that, by',
      },
      {
        title: 'Limitation of Liability',
        text: 'To the maximum extent permitted by law, in no event shall we or our agents be liable to you or any other person or entity for any direct, punitive, incidental, special, consequential, or exemplary damages, regardless of whether we were informed of the possibility of such damages and regardless of whether they were reasonably foreseeable. In no event shall our liability under this Agreement exceed the total amount of money paid to us by you under any subscription or fees for our Services in the 12 months immediately preceding the event giving rise to the damages.',
      },
      {
        title: 'Governing Law and Venue',
        text: 'This Agreement will be governed by and construed in accordance with the laws of the State of New York. The venue for any disputes including mediation, arbitration, or litigation shall be New York County, New York. You agree to waive the following defenses to any action brought in New York: forum non conveniens and lack of personal jurisdiction.',
      },
      {
        title: 'Waiver',
        text: 'If one party waives any term or provision of this Agreement at any time, that waiver will only be effective for the specific instance and specific purpose for which the waiver was given. If either party fails to exercise or delays exercising any of its rights or remedies under this Agreement, that party retains the right to enforce that term or provision at a later time.',
      },
      {
        title: 'Severability',
        text: 'If any provision of this Agreement is invalid or unenforceable, whether by the decision of an arbitrator or court, by passage of a new law, or otherwise, the remainder of this Agreement will remain in effort and be construed and enforced consistent with the purpose of this Agreement, to the fullest extent permitted by law. Furthermore, if a provision is deemed invalid or unenforceable, you agree that provision should be enforced to the fullest extent permitted under the law, consistent with its purpose.',
      },
      {
        title: 'Understanding of Agreement',
        text: 'You acknowledge that you understand the terms and conditions of this Agreement. You also acknowledge that you could discuss these provisions with a lawyer at your own expense prior to entering into this Agreement, and have either done so or chosen not to do so in entering this Agreement. Regardless of your choice, you intend to be fully bound by this Agreement.',
      },
      {
        title: 'Headings',
        text: 'The headings in this Agreement are for convenience or reference only and do not limit or otherwise affect the meaning hereof.',
      },
      {
        title: 'Entire Agreement',
        text: 'This Agreement together with the Privacy Policy constitutes the entire agreement between us and   supersedes all prior agreements, representations, and understandings, oral or written, between us.',
      },
    ];
  }

  dataPolicyList() {
    this.descriptionItem = [
      'Ora App LLC. is a coworking platform that allows residents of apartment buildings to book and use on demand hourly office space and conference room spaces in their apartment building amenity areas.',
      'This privacy policy sets forth the manner in which we collect, manage, store, and use your information.  By using, accessing or participating in our services, you agree to the terms of this Privacy Policy.  Capitalized terms not defined in this Privacy Policy have the meaning set forth in the Terms and Conditions of Use (located at www.byoraprivateoffices.com/terms-and-conditions-of-use)',
      'Our business and the services we provide are constantly evolving. We may change our privacy policy at any time and such changes will become effective immediately. We encourage you to periodically review this page for the latest information on our privacy practices.'
    ];

    this.personallyInformationList = [
      {
        title: 'Collection of Personally Identifiable Information',
        textStart: 'Personally identifiable information is data that can be used to contact or identify you. Examples include your name, phone number, email, address, and IP address. Through the registration process you may provide us with your  name, company affiliation, email address, telephone number, physical address, information about your booking (such as type of office, reservation times, and any details you submit), and other office-specific information that may be requested during the registration process on the service. This information may be collected in the following ways:',
        optionalList: [
          'Your name, email address, phone number and password are collected when you register for our services.',
          'Your access logs and internet usage are collected for security auditing purposes.',
          'Payment information is collected by our third party service provider, Stripe.',
          'Device addresses are collected when you access our website or use our mobile software.',
          'We collect and store certain information through the use of cookies. We do also log HTTP requests (IP address, user agent, page being requested, size of the response, time of the request).',
          'Conference room bookings and descriptions are collected to reserve your space.'
        ]
      },
      {
        title: 'Use of Personally  Identifiable Information',
        textStart: 'Ora App LLC uses your information in the following ways:',
        optionalList: [
          'We collect your password to authenticate you on registration.',
          'We collect your user ID to identify you.',
          'We use your information to provide our services, maintain the security and safety of our services, provide customer support, and improve our performance.'
        ]
      },
      {
        title: 'Sharing Your Information With Third Parties',
        textStart: 'Ora App LLC only shares your information with third parties in the following ways and for the following purposes:',
        optionalList: [
          'We share our customer database with a third party, currently Salesforce, to host our website and provide infrastructure on which we store our data.',
          'We share your name, address, and credit card, and other payment information with Stripe to process payments.',
          'We will share all collected information to the extent necessary and as required by law or to comply with any legal obligations, including defense of our company.',
          'We share your event information with Google when you enable calendar syncing to enable for reservation functionality.'
        ]
      },
      {
        title: 'Information Obtained From Third Parties',
        textStart: 'We also receive information from other sources, such as Stripe, the third party that processes users’ payments. We receive that information consistent with those companies’ privacy policies. For information about their privacy policies, please visit those sites.'
      },
      {
        title: 'Your Choices in What Information You Share',
        textStart: 'We do not require you to provide any personal data to browse our site. However, when browsing our site, any person is logged through our HTTP logs. These logs contain your IP address, what browser you’re using, what page you requested, and what time. Our process for collecting most types of information begins when you register for our service using your name, email address, and password. We will request your consent to use your data at that time. We require your personal data as part of your contract with us so we can provide you our services. We will not use your data for more than the purpose for which it was collected.'
      },
      {
        title: 'Non-Personally Identifiable Information',
        textStart: 'Non-personally identifiable information includes general details about your device and connection (including the type of computer/mobile device, operating system, web-browser or other software, language preference, and hardware); general information from the app store or referring website; the date and time of visit or use; and, internet content provider information. We collect your non-personally identifying information when you use our service.'
      },
      {
        title: 'Removal of Your Information',
        textStart: 'We will retain your Personally Identifiable Information until you request in writing that we delete or otherwise remove your Personally Identifiable Information. Upon your request, we will remove this information as soon as reasonably possible based on your account activity and in accordance with applicable law. We do retain information from closed accounts to comply with law, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigations, enforce our Terms of Use and take other actions otherwise permitted by law. We will retain Non-Personally Identifiable Information as part of our historical analysis of how our services are used, until such data no longer provides any historical use.'
      }
    ];

    this.protectingInformationList = [
      {
        title: 'Keeping Your Information Safe',
        textStart: 'Ora App LLC. makes reasonable and commercially feasible efforts to keep your information safe. We have appropriate security measures in place to prevent your information from being accidentally lost, used, or accessed in an unauthorized way. We restrict access to your personal information to those who need to know it, are subject to contractual confidentiality obligations, and may be disciplined or terminated if they fail to meet these obligations. Those processing your information will do so only in an authorized manner and are subject to a duty of confidentiality. We also:',
        textEnd: 'That said, no organization or business can guarantee 100% data protection. With that in mind, we also have procedures in place to deal with any suspected data security breach. We will inform both you and any applicable authorities of a suspected data security breach, as and when required by law.',
        optionalList: [
          'Encrypt many of our services using SSL.',
          'Offer you two-step verification when you access your account',
          'Regularly review our processes to consider if there are any additional improvements we can make to our systems.'
        ]
      },
      {
        title: 'Links to Other Websites',
        textStart: 'Our site has links to the sites, content, and services of other businesses, suppliers, advertisers, sponsors, and other third parties. Any products or services found through a link to any of these other sites are subject to separate privacy policies. Ora App LLC. is not liable for any content provided or actions  taken by such third parties.'
      },
      {
        title: 'Posting Content to Our Site or Social Media',
        textStart: 'Any content you post on this site or others, including through social media links, is likely to become publicly available and may not be fully revocable or erasable by Ora App LLC, because it will have become available to third parties. You can always ask us to delete information in our possession, but we cannot force anyone else to erase your information. Please be aware that you publish such information at your own risk.'
      },
      {
        title: 'Do Not Track Signals',
        textStart: 'Some technologies, such as web browsers or mobile devices, provide a setting that when turned on sends a Do Not Track (DNT) signal when browsing a website or app. We do not collect personally identifiable information from you to track you over time and across third party websites.  As a result, we do not specifically respond to web browser DNT signals.'
      },
      {
        title: 'Use by Minor',
        textStart: 'This website, its content, and our services are not intended for anyone under the age of 18. Ora App LLC. does not knowingly collect information from those under the age of 18. If Ora App LLC learns it has accidentally collected such information, it will be deleted.  If you believe that we might have any information from or about a minor under 18, please contact us at __________________________.'
      },
      {
        title: 'California Residents',
        textStart: 'The California Consumer Privacy Act (“CCPA”) provides some California residents with the additional rights listed below.  You may be able to exercise these rights.  Please contact us at support@byoraprivateoffices.com to learn more. '
      },
      {
        title: 'Right to Know',
        textStart: 'You may have the right to know and see what data we have collected about you over the past twelve months, including:',
        optionalList: [
          'The specific pieces of personal information we have collected about you.',
          'The categories of personal information we have collected about you.',
          'The categories of sources from which the personal information is collected.',
          'The business or commercial purpose for collecting your personal information.',
          'The categories of third parties with whom we have shared your personal information. '
        ]
      },
      {
        title: 'Right to Delete',
        textStart: 'You may have the right to delete the personal information we have collected from you (and direct our service providers to do the same).'
      },
      {
        title: 'Other Rights',
        textStart: 'You may be able to request certain information about our disclosure of personal information to third parties for their own direct marketing purposes during the preceding calendar year.  This request is free and may be made once a year.  You also have the right not to be discriminated against for exercising any of the rights listed above.'
      }
    ];

    this.disclaimersList = [
      {
        title: 'No Unsolicited Personal Information Requests',
        text: 'We will never ask you for your personal information in an unsolicited letter, call, or email. If you contact us, we will only use your personal information if necessary to fulfill your request.'
      },
      {
        title: 'Security',
        text: 'The security of our services is important to us. We use reasonable efforts to secure any information you provide to our systems. Your account information is located on a secured server behind a firewall.  Unfortunately, no method of data transmission over the Internet or method of electronic storage, can be guaranteed to be 100% secure. As a result, we cannot guarantee absolute security. As with disclosure of any information electronically, there is a risk that any information you disclose by use of the Services could be collected or used by others. This could result in unsolicited messages from third parties or use of such information by third parties for their own purposes, legal or illegal. We are not responsible for circumvention of any of our privacy settings or security measures. You hereby waive any claims against us and agree not to hold us liable for the theft or misuse of personal information.'
      },
      {
        title: 'Complaints',
        text: 'Ora App LLC regularly reviews this policy to ensure it complies with any applicable laws.  We value and respect your privacy rights. That is why we strive to only use your information when we think that doing so improves your experience in using our services. If you feel that we could improve in this mission in any way, or if you have a complaint or concern, please let us know by sending us your feedback support@byoraprivateoffices.com'
      },
      {
        title: 'Questions about this Policy',
        text: 'If you have any questions about this privacy policy, contact us at: support@byoraprivateoffices.com.  By accessing any of our Services or content, you are affirming that you understand and agree with the terms of our Privacy Policy.'
      }
    ];
  }
}
