import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { ModalController, NavParams } from '@ionic/angular';
import { CalendarModal, CalendarModalOptions } from 'ion2-calendar';


import { ModalService } from '../../../services/modal/modal.service';
import { RoomCreationService } from '../../services/room-creation/room-creation.service';
import { BuildingService } from '../../services/building/building.service';
import { FacilityService } from '../../services/facility/facility.service';
import { RoomUpdatingService } from '../../services/room-updating/room-updating.service';
import { RequestService } from 'src/app/services/request/request.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { DateService } from 'src/app/services/date/date.service';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-promocode-modal',
  templateUrl: './promocode-modal.component.html',
  styleUrls: ['./promocode-modal.component.scss'],
})
export class PromocodeModalComponent implements OnInit {

  @Input() promocode;
  @Input() isOpenEditPromocodeModal: boolean;
  @Output() create = new EventEmitter();
  @Output() delete: EventEmitter<number> = new EventEmitter();
  @Output() edit = new EventEmitter();

  public calendarOptions: CalendarModalOptions;
  public hoursAmount = 1;
  public promocodeForm: UntypedFormGroup;
  public promocodeDateFrom: string;
  public promocodeDateTo: string;
  public isCalendarOpen = false;
  public isDropdownLocationOpen = false;
  public buildings;
  public date: { from: string, to: string };
  private buildingId: number;
  private promocodeTypes = {
    amountOff: 'dollar',
    hoursOff: 'hour',
    percentOff: 'percent'
  };
  private promocodeType = '';
  private defaultPromocodePayload;
  calendarData = { start: null, end: null };
  options = this.params.get('options');

  constructor(
    public modalService: ModalService,
    public roomCreationService: RoomCreationService,
    public buildingService: BuildingService,
    public facilityService: FacilityService,
    public roomUpdatingService: RoomUpdatingService,
    public modalController: ModalController,
    public formBuilder: UntypedFormBuilder,
    private requestService: RequestService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private dateService: DateService,
    private params: NavParams,
    private events: EventsService
  ) { }

  async ngOnInit() {
    if (this.modalService.isMobileView) {
      this.promocode = this.options.promocode;
      this.isOpenEditPromocodeModal = this.options.isOpenEditPromocodeModal;
      this.promocode && (this.calendarData = {
        start: this.promocode.validFrom,
        end: this.promocode.validTo
      });
    }
    this.initForm();
    await this.buildingService.getBuildingNamesList();
  }

  initForm() {
    if (this.isOpenEditPromocodeModal) {
      this.promocodeType = this.getPromocodeType(this.promocode);
      this.buildingService.chosenBuilding = this.promocode.building.name;
      this.buildingService.buildingId = this.promocode.buildingId;

      this.promocodeForm = this.formBuilder.group({
        promocodeType: [this.promocodeType, Validators.compose([Validators.required])],
        type: this.formBuilder.group({
          amountOff: [{ value: this.promocode?.amountOff ? (this.promocode?.amountOff / 100) : null, disabled: this.promocodeType !== this.promocodeTypes.amountOff }],
          percentOff: [{ value: this.promocode?.percentOff, disabled: this.promocodeType !== this.promocodeTypes.percentOff }],
          hoursOff: [{ value: this.promocode?.hoursOff ? this.promocode?.hoursOff : 1, disabled: this.promocodeType !== this.promocodeTypes.hoursOff }],
        }, { validator: atLeastOne(Validators.required) }),
        timesPerUser: [this.promocode.timesPerUser, Validators.compose([Validators.required])],
        validFrom: ['', Validators.compose([Validators.required])],
        validTo: ['', Validators.compose([Validators.required])],
        coupon: [this.promocode.coupon, Validators.compose([Validators.required])],
      }, { updateOn: 'change' });

      this.date = {
        from: this.promocode.validFrom,
        to: this.promocode.validTo
      };

      this.formatDate();
      this.collectData();
    } else {
      this.promocodeForm = this.formBuilder.group({
        promocodeType: ['', Validators.compose([Validators.required])],
        type: this.formBuilder.group({
          amountOff: [{ value: '', disabled: true }],
          percentOff: [{ value: '', disabled: true }],
          hoursOff: [{ value: '', disabled: true }],
        }, { validator: atLeastOne(Validators.required) }),
        timesPerUser: ['', Validators.compose([Validators.required])],
        validFrom: ['', Validators.compose([Validators.required])],
        validTo: ['', Validators.compose([Validators.required])],
        coupon: ['', Validators.compose([Validators.required])],
      }, { updateOn: 'change' });
    };
  }

  collectData() {
    let payload: any = {
      buildingId: this.buildingService.buildingId,
      validFrom: this.promocodeForm.controls.validFrom?.value,
      validTo: this.promocodeForm.controls.validTo?.value,
      coupon: this.promocodeForm.controls.coupon?.value,
      timesPerUser: this.promocodeForm.controls.timesPerUser?.value
    };

    if (this.isOpenEditPromocodeModal) {
      payload = this.formattedPayload(payload);
      !this.defaultPromocodePayload && (this.defaultPromocodePayload = payload);
    }

    return payload;
  }

  async createPromocode() {

    let payload: any = {
      buildingId: this.buildingService.buildingId,
      validFrom: this.promocodeDateFrom,
      validTo: this.promocodeDateTo,
      coupon: this.promocodeForm.controls.coupon?.value,
      timesPerUser: this.promocodeForm.controls.timesPerUser?.value
    };

    payload = this.formattedPayload(payload);
    await this.sendPromocode(payload);
  }

  formattedPayload(payload) {
    switch (this.promocodeForm.controls.promocodeType.value) {
      case 'hour':
        return {
          ...payload,
          hoursOff: this.promocodeForm.controls.type['controls'].hoursOff?.value
        };
      case 'percent':
        return {
          ...payload,
          percentOff: this.promocodeForm.controls.type['controls'].percentOff?.value,
        };
      default:
        return {
          ...payload,
          amountOff: this.promocodeForm.controls.type['controls'].amountOff?.value ? (this.promocodeForm.controls.type['controls'].amountOff?.value * 100) : null,
        };
    };
  }

  async sendPromocode(payload) {
    await this.loaderService.presentLoader();

    try {
      const resp = await this.requestService.postAuthRequest('coupons', payload).toPromise();
      this.loaderService.dismissLoader();
      this.modalController.dismiss();
      this.create.emit(resp);
      this.dismissModalMobile(resp);
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
    }
  }

  async savePromocode() {
    await this.loaderService.presentLoader();
    let payload = this.collectData();

    payload = {
      ...payload,
      validFrom: this.promocodeDateFrom,
      validTo: this.promocodeDateTo,
    };

    this.changePromocode(payload);
  }

  async changePromocode(payload) {
    this.requestService.patchAuthRequest(`coupons/${this.promocode.id}`, payload).subscribe(
      async (resp) => {
        const dataToUpdateLocally = {
          ...resp,
          formattedValidFrom: this.dateService.formattedPromocodeDate(this.promocodeDateFrom),
          formattedValidTo: this.dateService.formattedPromocodeDate(this.promocodeDateTo),
          isEdit: true
        };

        this.edit.emit(dataToUpdateLocally);
        this.loaderService.dismissLoader();
        this.dismissModalMobile(dataToUpdateLocally);
      },
      (err) => {
        this.loaderService.dismissLoader();
        console.log(err);
      }
    )
  }

  dismissModalMobile(dataToUpdateLocally) {
    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      this.events.onCloseModal(dataToUpdateLocally);
    } else {
      this.modalService.closeDesktopModal('PromocodeModal');
    }
  }

  getPromocodeType(promocode): string {
    if (promocode.amountOff) {
      return this.promocodeTypes.amountOff;
    };

    if (promocode.hoursOff) {
      return this.promocodeTypes.hoursOff;
    };

    return this.promocodeTypes.percentOff;
  }

  checkDiscountType(type: string, input?: HTMLInputElement) {
    if (type === this.promocodeTypes.percentOff) {
      this.promocodeForm.controls.type['controls'].amountOff.disable();
      this.promocodeForm.controls.type['controls'].amountOff.reset();
      this.promocodeForm.controls.type['controls'].percentOff.enable();
    } else if (type === this.promocodeTypes.amountOff) {
      this.promocodeForm.controls.type['controls'].percentOff.disable();
      this.promocodeForm.controls.type['controls'].percentOff.reset();
      this.promocodeForm.controls.type['controls'].amountOff.enable();
    } else {
      this.promocodeForm.controls.type['controls'].amountOff.disable();
      this.promocodeForm.controls.type['controls'].amountOff.reset();
      this.promocodeForm.controls.type['controls'].percentOff.disable();
      this.promocodeForm.controls.type['controls'].percentOff.reset();
    }
    if (input) {
      if (this.isOpenEditPromocodeModal && this.promocodeForm.controls.type['controls'].hoursOff) {
        this.promocodeForm.controls.type['controls'].hoursOff.value = 1;
      }
      this.hoursAmount = 1;
      input.focus();
    }
  }

  async presentCalendarModal() {
    this.isDropdownLocationOpen = false;
    this.createCalendar();
    this.isCalendarOpen = true;
  }

  closeCalendarHandler(event) {
    this.isCalendarOpen = event;
  }

  onDateSelect() {
    this.isCalendarOpen = false;
    this.formatDate();
  }

  formatDate(promocode = null) {
    this.promocodeDateFrom = promocode ? new Date(promocode.validFrom).toISOString() : new Date(this.date?.from).toISOString();
    this.promocodeDateTo = promocode ? new Date(promocode.validTo).toISOString() : new Date(this.date?.to).toISOString();
    this.calendarData = { start: this.promocodeDateFrom, end: this.promocodeDateTo };

    const formattedValidFrom = this.dateService.formattedPromocodeDate(promocode ? promocode.validFrom : this.promocodeDateFrom);
    const formattedValidTo = this.dateService.formattedPromocodeDate(promocode ? promocode.validTo : this.promocodeDateTo)

    this.promocodeForm.controls.validFrom.patchValue(formattedValidFrom);
    this.promocodeForm.controls.validTo.patchValue(formattedValidTo);
  }

  onDateReset() {
    this.promocodeForm.controls.validFrom.reset();
    this.promocodeForm.controls.validTo.reset();
    this.date = undefined;
  }

  createCalendar() {
    this.calendarOptions = {
      pickMode: 'range',
      title: 'Choose date',
      weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthFormat: 'MMM, YYYY',
      weekStart: 1,
      step: 3,
      cssClass: 'modal-calendar',
      color: 'light',
      closeLabel: '',
      doneLabel: ' ',
      clearLabel: ' ',
      daysConfig: []
    };
  }

  changeHoursAmount(type) {
    if (this.isOpenEditPromocodeModal) {
      let hoursAmount = this.promocodeForm.controls.type['controls'].hoursOff.value;
      type === 'add' ? hoursAmount++ : hoursAmount > 1 && hoursAmount--;
      this.promocodeForm.controls.type['controls'].hoursOff.patchValue(hoursAmount);
    } else {
      type === 'add' ? this.hoursAmount++ : this.hoursAmount > 1 && this.hoursAmount--;
      this.promocodeForm.controls.type['controls'].hoursOff.patchValue(this.hoursAmount);
    };
  }

  closeCalendar() {
    this.isCalendarOpen = false;
  }

  toggleLocationDropdown() {
    this.isDropdownLocationOpen = !this.isDropdownLocationOpen;
  }

  closeDropDown() {
    this.isDropdownLocationOpen = false;
  }

  back() {
    if (this.options.isOpenEditPromocodeModal) {
      this.modalService.dismiss();
      import('../../components/room-building-details-admin/room-building-details-admin.component').then(m =>
        this.modalService.presentModal(m.RoomBuildingDetailsAdminComponent, 'auto-height', { building: this.promocode.building, isRoomDetails: false, promocode: this.promocode })
      )
    } else {
      this.modalService.dismiss();
    }
  }
}

export const atLeastOne = (validator: ValidatorFn) => (
  group: UntypedFormGroup,
): ValidationErrors | null => {
  const hasAtLeastOne =
    group &&
    group.controls &&
    Object.keys(group.controls).some(k => !validator(group.controls[k]));

  return hasAtLeastOne ? null : { atLeastOne: true };
};