import { Component, OnInit } from '@angular/core';

import { StepSelectionService } from '../../../services/step-selection/step-selection.service';
import { RoomCreationService } from '../../../services/room-creation/room-creation.service';

@Component({
  selector: 'app-add-lock-id',
  templateUrl: './add-lock-id.component.html',
  styleUrls: ['./add-lock-id.component.scss'],
})
export class AddLockIdComponent implements OnInit {

  lockValue;

  constructor(
    public stepSelectionService: StepSelectionService,
    private roomCreationService: RoomCreationService
  ) { }

  ngOnInit() { }

  getInputValue(event) {
    const targetValue = parseInt(event.target.value, 10);
    (targetValue !== 0) && (this.lockValue = targetValue);
  }

  nextStep() {
    this.roomCreationService.roomData.lockId = this.lockValue;
    this.stepSelectionService.nextStep();
  }

}
