import { Component, OnInit } from '@angular/core';

import { ModalService } from '../../services/modal/modal.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss'],
})
export class DeleteAccountModalComponent implements OnInit {

  constructor(
    public modalService: ModalService,
    public authService: AuthService
  ) { }

  ngOnInit() { }

}
