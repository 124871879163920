import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { RequestService } from 'src/app/services/request/request.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss'],
})
export class CancelBookingComponent implements OnInit {

  @Input() booking;

  textDueBooking = {
    title: {
      ongoingText: 'This is an ongoing reservation',
      refundValidText: 'Want to cancel this reservation?',
      refundNotValidText: 'Want to cancel this reservation?',
    },
    description: {
      ongoingText: 'Please note that you can not cancel a reservation that has already started.',
      refundValidText: 'Please note that this action cannot be undone. You will be refunded in 5-7 business days.',
      refundNotValidText: 'You are canceling 24h or less before the session. This reservation will not be refunded.',
    },
    button: {
      ongoingText: 'Got it',
      refundValidText: 'Cancel Reservation',
      refundNotValidText: 'Cancel anyway',
    }
  }

  constructor(
    public modalController: ModalController,
    private requestService: RequestService,
    private toastService: ToastService,
    public loader: LoaderService
  ) { }

  ngOnInit() { }

  cancelBooking() {
    if (this.booking.isOngoing) { this.modalController.dismiss(); return; }

    this.loader.presentLoader();
    this.requestService.patchAuthRequest(`bookings/${this.booking.id}/cancel`, null)
      .subscribe(
        (resp: any) => {
          this.loader.dismissLoader();
          this.toastService.presentToast(resp.message);
          this.modalController.dismiss(this.booking.id);
        },
        err => {
          console.log(err);
          this.loader.dismissLoader();
          this.toastService.presentToast(err.message, 'error');
        }
      );
  }

  checkElementForTextValue(isTitle = false, isBtn = false) {
    return isTitle
      ? this.setTextDueBookingParams(this.textDueBooking.title)
      : (isBtn ? this.setTextDueBookingParams(this.textDueBooking.button) : this.setTextDueBookingParams(this.textDueBooking.description));
  }

  setTextDueBookingParams(textDueBooking) {
    return this.booking.isOngoing
      ? textDueBooking.ongoingText
      : (this.booking.isRefundValid ? textDueBooking.refundValidText : textDueBooking.refundNotValidText);
  }
}
