import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { ModalService } from '../../services/modal/modal.service';
import { ValidatorsService } from 'src/app/services/validators/validators.service';

@Component({
  selector: 'app-edit-credit-card',
  templateUrl: './edit-credit-card.component.html',
  styleUrls: ['./edit-credit-card.component.scss'],
})
export class EditCreditCardComponent implements OnInit {

  paymentForm: UntypedFormGroup = new UntypedFormGroup({
    cardholderName: new UntypedFormControl(''),
    cardNumber: new UntypedFormControl(''),
    date: new UntypedFormControl(''),
    cvvCode: new UntypedFormControl('')
  });

  constructor(
    public modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private validatorsService: ValidatorsService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.paymentForm = this.formBuilder.group({
      cardholderName: ['', this.validatorsService.cardValidators.validateCardHolderName],
      cardNumber: ['', [this.validatorsService.cardValidators.validateCCNumber]],
      date: ['', [this.validatorsService.cardValidators.validateExpDate]],
      cvvCode: ['', this.validatorsService.cardValidators.validateCvvCode],
    }, { updateOn: 'change' });
  }

  onSubmit(data) {
  }

  checkMaxLength(event) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    event.target.value.length > 4 && (event.target.value = event.target.value.slice(0, 4));
  }

  goToDeleteCard() {
    this.modalService.dismiss();
    import('../delete-credit-card/delete-credit-card.component').then(m =>
      this.modalService.presentModal(m.DeleteCreditCardComponent, 'auto-height-notifications')
    );
  }

  save() {
    this.modalService.dismiss();
  }

  clear() {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => input.value = '');
  }

}
