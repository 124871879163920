import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StepSelectionService {

  step = 1;
  progressBarValue = 0.17;

  constructor() { }

  nextStep() {
    this.step++;
    this.progressBarValue += 0.17;
  }

  previousStep() {
    this.step--;
    this.progressBarValue -= 0.17;
  }
}
