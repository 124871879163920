import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ModalService } from '../../../services/modal/modal.service';
import { RequestService } from 'src/app/services/request/request.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { BuildingService } from '../../services/building/building.service';
import { EventsService } from '../../../services/events/events.service';
import { ImageConversionService } from '../../../services/image-conversion/image-conversion.service';
import { NavParams } from '@ionic/angular';

import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-building-photo-modal',
  templateUrl: './add-building-photo-modal.component.html',
  styleUrls: ['./add-building-photo-modal.component.scss'],
})
export class AddBuildingPhotoModalComponent implements OnInit {

  @Input() building;
  @Output() close = new EventEmitter();

  localBuildingPictures = [];
  pictureToAdd;
  pictureLink;
  awsBaseUrl = environment.awsBaseUrl;

  status;
  file;
  savedPic;

  constructor(
    public modalService: ModalService,
    private requestService: RequestService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private buildingService: BuildingService,
    private params: NavParams,
    private events: EventsService,
    private imgConversion: ImageConversionService
  ) { }

  ngOnInit() {
    if (this.modalService.isMobileView) {
      const options = this.params.get('options');
      this.building = options.building;
    }
  }

  convertPictureFromInput(event) {
    if (this.localBuildingPictures.length) { return; }

    this.file = event.target.files[0];

    if (this.file.size > 10000000) { this.toastService.presentToast('Maximum upload file size 10 MB', 'error'); event.target.value = ''; return; }
    const reader = new FileReader();

    reader.onload = async (ev) => {
      if (this.imgConversion.isHeicImgType(this.file.type)) {
        const conversionResponse = await this.imgConversion.convertHeic2Jpeg(reader, this.file.name);
        this.putPicturesLocally(this.localBuildingPictures, conversionResponse[1], this.file.name);
        this.file = conversionResponse[0];
        this.pictureToAdd = this.file;
        this.setWrapperStatus(false);
      } else {
        this.putPicturesLocally(this.localBuildingPictures, reader.result, this.file.name);
        this.pictureToAdd = this.file;
        this.setWrapperStatus(false);
      }
    }
    reader.readAsDataURL(this.file);
    event.target.value = '';
  }

  setWrapperStatus(status) {
    this.status = status;
  }

  putPicturesLocally(pictures, result, name) {
    pictures.push({ data: result, name: name });
  }

  deletePictures(localPics, picture) {
    this[localPics] = this[localPics].filter(item => item !== picture);
    this.pictureToAdd = null;
  }

  deleteCurrentPicture(pic) {
    this.savedPic = pic;
    this.building.picture = '';
  }

  back(isBackToEdit = false) {
    this.savedPic && (this.building.picture = this.savedPic);

    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      this.events.onCloseModal(false);
      isBackToEdit && import('../edit-building-modal/edit-building-modal.component').then(m =>
        this.modalService.presentModal(m.EditBuildingModalComponent, 'auto-height', { building: this.building })
      );
    } else {
      this.modalService.closeDesktopModal('AddBuildingPhotoModal');
      this.modalService.openDesktopModal('EditBuildingModal');
    }
  }

  async uploadPicture() {
    await this.loaderService.presentLoader();
    const response = await this.getUploadUrl();

    if (response) {
      try {
        await this.requestService.uploadRequest(response.uploadURL, this.pictureToAdd).toPromise();
        this.pictureLink = response.Key.split('/')[2];;
      } catch (err) {
        this.loaderService.dismissLoader();
        this.toastService.presentToast(err.message, 'error');
      }
    }
  }

  async getUploadUrl() {
    try {
      return await this.requestService.getRequest(`file-uploader?path=buildings/${this.building.id}`).toPromise() as any;
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      return null;
    }
  }

  async onSubmit() {
    await this.uploadPicture();
    await this.buildingService.updateBuilding(this.building.id, { picture: this.pictureLink });

    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      this.events.onCloseModal(false);
    } else {
      this.modalService.closeDesktopModal('AddBuildingPhotoModal');
      this.modalService.closeDesktopModal('EditBuildingModal');
      this.close.emit();
    }
  }

}
