import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ModalService } from 'src/app/services/modal/modal.service';
import { GroupService } from 'src/app/services/group/group.service';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-delete-member-modal',
  templateUrl: './delete-member-modal.component.html',
  styleUrls: ['./delete-member-modal.component.scss'],
})
export class DeleteMemberModalComponent implements OnInit {

  options = this.params.get('options');

  constructor(
    private params: NavParams,
    private modalService: ModalService,
    private groupService: GroupService,
    private events: EventsService
  ) { }

  ngOnInit() {}

  async deleteMemberInGroup() {
    const response = await this.groupService.deleteGroupMember(this.options.groupId, this.options.userId) as any;
    this.checkCurrentError(response);
    this.events.onCloseGroupModal();
    this.modalService.dismiss();
  }

  checkCurrentError(response) {
    if (response.isError) {
      (response.message && response.message.includes('ongoing')) && this.openErrorModal(false, true); 
      return;
    } else {
      response.message && this.openErrorModal(false, true, response.message); 
    }
  }

  openErrorModal(isDeleteBookingInGroup = false, isDeleteUserInGroup, message = '') {
    this.modalService.dismiss();
    import('../ongoing-group-reservation-err-modal/ongoing-group-reservation-err-modal.component').then(m =>
      this.modalService.presentModal(m.OngoingGroupReservationErrModalComponent, 'auto-height notifications', {
        isDeleteBookingInGroup, isDeleteUserInGroup, message
      })
    );
  }
  
  back() {
    this.modalService.dismiss();
  }

}
