import { AfterViewInit, Component, OnInit } from '@angular/core';

import { StatsService } from '../../services/stats/stats.service';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements AfterViewInit {

  constructor(public statsService: StatsService) { }

  ngAfterViewInit() {
    this.statsService.createRoundDonutChart();
  }
}
