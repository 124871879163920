import { Injectable } from '@angular/core';

import { RequestService } from 'src/app/services/request/request.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';

import { mergeMap } from 'rxjs/operators';

import { NavController } from '@ionic/angular';
import { GroupModel } from 'src/app/models/group/group.model';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  name = '';
  membersToShow = [];
  groupsToBooking = [];
  groups;
  bookingId;
  createdGroup;
  groupMembersNum;
  adminGroups;
  bookedGroups;

  fromRoomsPage = false;
  fromRoomsGroupCreation = false;
  isAddBookingToGroupCreationFlow = false;
  fromBookingFlow = false;

  constructor(
    private requestService: RequestService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private navController: NavController
  ) { }

  clearState() {
    this.name = '';
    this.membersToShow = [];
  }

  filterAdminGroups() {
    this.adminGroups = this.adminGroups.filter(group => !this.bookedGroups.some(bookedGroup => group.id === bookedGroup.id));
  }

  clearBoolState() {
    this.fromRoomsPage = this.fromRoomsGroupCreation = this.fromBookingFlow = this.isAddBookingToGroupCreationFlow = false;
  }

  async createGroup() {
    await this.loaderService.presentLoader();
    const emailsArr = this.membersToShow.map(member => member.email);

    try {
      const response = await this.requestService.postAuthRequest('groups', { title: this.name }).pipe(
        mergeMap((response: GroupModel) => {
          this.createdGroup = { ownerId: response.ownerId, title: response.title, id: response.id };
          return this.usersToGroupRequest(response, emailsArr);
        })
      ).toPromise();

      this.groupsToBooking = [this.createdGroup.id];
      (this.fromBookingFlow || this.isAddBookingToGroupCreationFlow) && await this.addGroupsToBooking(false);
      this.loaderService.dismissLoader();
      return response;
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
      return null;
    }
  }

  usersToGroupRequest(response, emailsArr) {
    return this.requestService.postAuthRequest(`groups/${response.id}/users`, { emails: emailsArr });
  }

  async getGroups(isLoaderRequired = true) {
    isLoaderRequired && await this.loaderService.presentLoader();

    try {
      const response = await this.requestService.getAuthRequest('groups').toPromise();
      isLoaderRequired && this.loaderService.dismissLoader();
      return response;
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
      return null;
    }
  }

  async getUsersByGroup(groupId?, fromGroupDetails = false) {
    await this.loaderService.presentLoader();

    try {
      const response = await this.requestService.getAuthRequest(`groups/${groupId ? groupId : this.createdGroup.id}/users`).toPromise();
      this.loaderService.dismissLoader();
      return response;
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
      if (fromGroupDetails && err && err.message.includes('Only owner or user in this group')) { 
        this.navController.navigateBack(['rooms'], { queryParams: { isUserReservations: false } });
      }
      return null;
    }
  }

  async addGroupsToBooking(isLoaderRequired = true) {
    isLoaderRequired && await this.loaderService.presentLoader();

    try {
      const response = await this.requestService.postAuthRequest(`bookings/${this.bookingId}/groups`, { groupIds: this.groupsToBooking }).toPromise();
      isLoaderRequired && this.loaderService.dismissLoader();
      return response;
    } catch (err) {
      isLoaderRequired && this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
      return null;
    }
  }

  async getGroupBookings(isLoaderRequired = true) {
    isLoaderRequired && await this.loaderService.presentLoader();

    try {
      const response = await this.requestService.getAuthRequest('groups/bookings').toPromise();
      isLoaderRequired && this.loaderService.dismissLoader();
      return response;
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
      return null;
    }
  }

  async deleteGroup(groupId) {
    await this.loaderService.presentLoader();
    try {
      const response = await this.requestService.deleteAuthRequest(`groups/${groupId}`).toPromise();
      this.loaderService.dismissLoader();
      return response;
    } catch (err) {
      this.loaderService.dismissLoader();
      console.log(err);
      err && (err.isError = true);
      return err;
    }
  }

  async getGroupsByBooking() {

    try {
      return await this.requestService.getAuthRequest(`bookings/${this.bookingId}/groups`).toPromise();
    } catch (err) {
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
      return null;
    }
  }

  async getGroupById(groupId) {
    try {
      return await this.requestService.getAuthRequest(`groups/${groupId}`).toPromise();
    } catch (err) {
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
      return null;
    }
  }

  async deleteBookingInGroup(groupId, bookingId) {
    await this.loaderService.presentLoader();

    try {
      const response = await this.requestService.deleteAuthRequest(`groups/${groupId}/booking/${bookingId}`).toPromise();
      this.loaderService.dismissLoader();
      return response;
    } catch (err) {
      this.loaderService.dismissLoader();
      console.log(err);
      err && (err.isError = true);
      return err;
    }
  }

  async deleteGroupMember(groupId: number, userId: number) {
    await this.loaderService.presentLoader();

    try {
      const response = await this.requestService.deleteAuthRequest(`groups/${groupId}/users/${userId}`).toPromise();
      this.loaderService.dismissLoader();
      this.toastService.presentToast('Member was successfully removed');
      return response;
    } catch (err) {
      this.loaderService.dismissLoader();
      console.log(err);
      err && (err.isError = true);
      return err;
    }
  }

  async updateGroupName(groupId, title) {
    await this.loaderService.presentLoader();

    try {
      const response = await this.requestService.putAuthRequest(`groups/${groupId}`, { title }).toPromise();
      this.loaderService.dismissLoader();
      this.toastService.presentToast('Group was successfully updated');
      return response;
    } catch (err) {
      console.log(err);
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      return null;
    }
  }
}
