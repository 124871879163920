import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalService } from 'src/app/services/modal/modal.service';
import { ValidatorsService } from 'src/app/services/validators/validators.service';
import { AuthService } from 'src/app/services/auth/auth.service'
import { Subscription } from 'rxjs';

@Component({
  selector: 'required-data-modal',
  templateUrl: './required-data-modal.component.html',
  styleUrls: ['./required-data-modal.component.scss'],
})
export class RequiredDataModalComponent implements OnInit, OnDestroy {

  private form$: Subscription;

  isUpdated = {
    firstName: true,
    lastName: true,
    phone: true
  };

  savedValues = {
    firstName: '',
    lastName: '',
    phone: ''
  }

  userForm: UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl(''),
    lastName: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
  });

  constructor(
    public modalController: ModalController,
    private modalService: ModalService,
    public formBuilder: UntypedFormBuilder,
    private validatorsService: ValidatorsService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      firstName: [this.auth.user?.firstName || '', Validators.compose([Validators.required])],
      lastName: [this.auth.user?.lastName || '', Validators.compose([Validators.required])],
      phone: [this.auth.user?.phone || '', Validators.compose([Validators.required, this.validatorsService.validatePhone()])]
    }, { updateOn: 'change' });
    this.form$ = this.userForm.valueChanges.subscribe(res => {
      for (const property in this.isUpdated) {
        if (this.isUpdated[property] && this.savedValues[property] !== this.userForm.get(property).value) {
          this.isUpdated[property] = false;
        }
      }
    });
  }

  async onSubmit(userData) {
    for (const property in this.userForm.controls) {
      if (this.userForm.controls[property].errors) {
        this.savedValues[property] = userData[property];
        this.userForm.controls[property].markAsDirty();
        this.isUpdated[property] = true;
      }
    }
    if (!this.userForm.invalid) {
      await this.auth.updateUser(userData, true);

      if (this.auth.user.phone && !this.auth.user.phoneVerified) {
        this.openPhoneConfirmationModal();
      }
    }
  }

  openPhoneConfirmationModal() {
    import('../../components/phone-confirmation-modal/phone-confirmation-modal.component').then(m => {
      this.modalService.presentModal(m.PhoneConfirmationModalComponent, 'fixed-height')
    });
  }

  ngOnDestroy(): void {
    this.form$.unsubscribe();
  }
}
