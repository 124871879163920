import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import { RequestService } from '../request/request.service';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  platform = Capacitor.getPlatform();

  appUpdate = {
    currentVersion: (this.platform !== 'web') && this.appVersion(),
    majorMsg: {
      title: 'Important App update',
      message: 'Please update your app to the latest version to continue using it.',
      btn: 'Download'
    },
    minorMsg: {
      title: 'App update available',
      message: "There's a new version available. Would you like to update?",
      btn: 'Download'
    }
  }

  constructor(
    private requestService: RequestService,
    private alertService: AlertService
  ) { }

  async updateVersion() {
    if (this.platform === 'web') { return; }

    try {
      const response = await this.requestService.postAuthRequest('app-version', await this.appUpdate.currentVersion).toPromise();
    } catch (err) {
      console.log(err)
    }
  }

  async checkForUpdate() {
    if (this.platform === 'web') { return; }

    try {
      const response = await this.versionRequest() as any;
      this.checkVersions(response.version, await this.appUpdate.currentVersion);
    } catch (err) {
      console.log(err)
    }
  }

  checkVersions(serverVersion, currentVersion) {
    if (currentVersion === serverVersion) { return; }

    serverVersion = serverVersion.split('.');
    currentVersion = currentVersion.split('.');

    if (+serverVersion[0] < +currentVersion[0]
      || +serverVersion[1] < +currentVersion[1]
      || +serverVersion[2] < +currentVersion[2]
    ) { return; }

    if (+serverVersion[0] > +currentVersion[0]) {
      this.alertService.presentVersionAlert(this.appUpdate.majorMsg.title, this.appUpdate.majorMsg.message, this.appUpdate.majorMsg.btn);
    } else {
      this.alertService.presentVersionAlert(this.appUpdate.minorMsg.title, this.appUpdate.minorMsg.message, this.appUpdate.minorMsg.btn, true);
    }
  }

  async appVersion() {
    const info = await App.getInfo();
    return info.version;
  }

  async versionRequest() {
    return await this.requestService.getRequest('app-version').toPromise();
  }
}
