import { Component, OnInit } from '@angular/core';

import { StepSelectionService } from '../../../services/step-selection/step-selection.service'
import { RoomCreationService } from '../../../services/room-creation/room-creation.service';
import { FacilityService } from '../../../services/facility/facility.service';

@Component({
  selector: 'app-choose-facilities',
  templateUrl: './choose-facilities.component.html',
  styleUrls: ['./choose-facilities.component.scss'],
})
export class ChooseFacilitiesComponent implements OnInit {

  chairsAmount = 1;

  constructor(
    public stepSelectionService: StepSelectionService,
    public roomCreationService: RoomCreationService,
    public facilityService: FacilityService
  ) { }

  async ngOnInit() {
    await this.facilityService.getFacilities();
  }

  checkDisabled() {
    return !this.chairsAmount || !this.roomCreationService.roomData.facilities.length;
  }

  changeAmount(type) {
    if (type === 'add') {
      this.chairsAmount++;
    } else {
      this.chairsAmount > 1 && this.chairsAmount--;
    }
  }

  goToNextStep() {
    this.roomCreationService.roomData.chair = this.chairsAmount;
    this.stepSelectionService.nextStep();
  }
}
