/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit, ViewChild } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { StripePaymentElementComponent } from 'ngx-stripe';
import { BookingService } from 'src/app/services/booking/booking.service';
import { PaymentService } from 'src/app/services/payment/payment.service';

@Component({
  selector: 'app-stripe-modal',
  templateUrl: './stripe-modal.component.html',
  styleUrls: ['./stripe-modal.component.scss'],
})
export class StripeModalComponent implements OnInit {

  @ViewChild(StripePaymentElementComponent) paymentElement: StripePaymentElementComponent;

  constructor(
    public modalController: ModalController,
    public bookingService: BookingService,
    public paymentService: PaymentService
  ) { }

  ngOnInit() { }

}
