import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { GroupService } from 'src/app/services/group/group.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-group-created-modal',
  templateUrl: './group-created-modal.component.html',
  styleUrls: ['./group-created-modal.component.scss'],
})
export class GroupCreatedModalComponent implements OnInit {

  groupData = {
    membersToShow: [] as any
  }

  constructor(
    public groupService: GroupService,
    private modalService: ModalService,
    private navCtrl: NavController
  ) { }

  async ngOnInit() {
    this.groupData.membersToShow = await this.groupService.getUsersByGroup();
  }

  async closeModal(isBack = false) {
    this.modalService.dismiss();
    this.modalService.isConfirmedModalOpened = false;

    if (isBack) {
      import('../group-members-adding-modal/group-members-adding-modal.component').then(m =>
        this.modalService.presentModal(m.GroupMembersAddingModalComponent, 'auto-height notifications', { isGroupWasCreated: true, isResizeRequired: true })
      );
    } else {
      if (this.groupService.fromRoomsPage) {
        this.groupService.adminGroups = await this.groupService.getGroups();
        this.groupService.filterAdminGroups();
      }
      this.groupService.clearState();
      this.groupService.clearBoolState();
      this.navCtrl.navigateForward(['rooms'], { queryParams: { isUserReservations: false } });
    }
  }

}
