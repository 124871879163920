import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { BuildingService } from '../../services/building/building.service';
import { RoomsService } from 'src/app/services/rooms/rooms.service';
import { ModalService } from '../../../services/modal/modal.service';
import { SelectService } from 'src/app/services/select/select.service';
import { InputSearchService } from 'src/app/services/input-search/input-search.service';

import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-building-filter-select',
  templateUrl: './building-filter-select.component.html',
  styleUrls: ['./building-filter-select.component.scss'],
})
export class BuildingFilterSelectComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input() isReservationsPage;
  @Input() isOfficesListPage;
  @Input() isUsersPage;
  @Input() activeRoomsFilterValue;
  @Input() fromFilterModal;
  @Input() selectedText;
  @Input() isFilterWithPagination = false;
  @Input() fromBuildingManagersModal;
  @Input() selectedBuildingId;
  @Input() isWithoutMargin;
  @Input() startEndReservationQuery;

  @Output() filterEvent = new EventEmitter();
  @Output() buildingFilterChange = new EventEmitter();

  @ViewChild('allSelected') allSelected: MatOption;
  @ViewChild('buildingsSelect') buildingsSelect: MatSelect;
  @ViewChild('searchInput') searchInput;
  searchBuildingForm: FormGroup;
  selectedBuildingNamesStr;

  isCalendarOpen = false;
  cancelBtnClicked = false;

  buildingIds;
  calendarData;
  buildingsToShow;

  selectedBuilding;

  constructor(
    public buildingService: BuildingService,
    private roomsService: RoomsService,
    public modalService: ModalService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public selectService: SelectService,
    public inputSearch: InputSearchService
  ) { }

  async ngOnInit() {
    this.searchBuildingForm = this.fb.group({
      buildingIds: new FormControl('')
    });

    this.setPreviousBuildingNamesTitle();

    await this.buildingService.getBuildingNamesList();
    this.buildingIds = this.buildingService.buildingListNames && this.buildingService.buildingListNames.map((building) => building.id);
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  setPreviousBuildingNamesTitle() {
    if (this.fromBuildingManagersModal) {
      this.selectedBuildingNamesStr = this.buildingService.buildingListNames.find(building => building.id === this.selectedBuildingId).name;
      this.selectedBuildingId && this.searchBuildingForm.get('buildingIds').setValue([this.selectedBuildingId]);
      return;
    }

    if (!this.isMobileFilterModalWithPreSelect()) { return; }
    this.selectAllPreviousSelected();
  }

  isMobileFilterModalWithPreSelect() {
    return this.fromFilterModal && this.selectedText && this.buildingService.idsToPreSelect;
  }

  selectAllPreviousSelected() {
    this.selectedBuildingNamesStr = this.selectedText;

    this.searchBuildingForm.get('buildingIds')
      .patchValue(this.buildingService.buildingListNames.map(item => this.buildingService.idsToPreSelect.find(id => id === item.id)));

    if (this.searchBuildingForm.controls.buildingIds.value.length === this.buildingService.idsToPreSelect.length - 1) {
      setTimeout(() => {
        this.allSelected.select();
      });
    }
  }

  closeCalendarHandler(event) {
    this.isCalendarOpen = event;
  }

  async filterByBuilding(searchInput) {
    this.hideShowSelects();
    this.buildingsSelect.close();

    searchInput.value = '';
    this.inputSearch.isInputChange = false;

    this.buildingIds = this.searchBuildingForm.controls.buildingIds.value; 

    if (this.fromBuildingManagersModal || this.fromFilterModal || this.isFilterWithPagination) {
      this.buildingIds && this.buildingFilterChange.emit(this.buildingIds); return;
    }
    let buildingIdsQuery = this.buildingIds && this.buildingIds.map(buildingId => `buildingId[]=${buildingId}&`).join('');
    this.removePreviousBuildingIdsFromQuery();
    let fullQuery = this.isReservationsPage ? `${buildingIdsQuery}${this.startEndReservationQuery}` : buildingIdsQuery;
    const response = await this.setRequest(fullQuery);

    this.setDataAfterFilter(response);
    !this.isOfficesListPage && this.filterEvent.next(this.isReservationsPage ?  { response, buildingIdsQuery } : response);
  }

  removePreviousBuildingIdsFromQuery() {
    if (!this.isReservationsPage || !this.startEndReservationQuery.includes('buildingId')) { return; }
    this.startEndReservationQuery = `start${this.startEndReservationQuery.split('&start').at(-1)}`;
  }

  async setRequest(query) {

    if (this.isOfficesListPage) {
      return await this.buildingService.filterRequest(query, null, null, false, true);
    } else if (this.isUsersPage) {
      return await this.buildingService.filterRequest(query, null, null, false, false, true);
    } else {
      return await this.buildingService.filterRequest(query, null, null, true);
    }
  }

  setDataAfterFilter(response) {
    if (this.isOfficesListPage && this.buildingService.isBuildingAdmin()) {
      this.buildingService.buildingAdminRooms = response;
    } else if (this.isOfficesListPage) {
      this.roomsService.rooms = response;
    } else {
      return response;
    }
  }

  selectedValue(event: MatSelectChange) {
    this.selectedBuildingNamesStr = event.source.triggerValue;
  }

  clearSelection(searchInput) {
    this.searchBuildingForm.controls.buildingIds.patchValue([]);
    this.buildingIds = [];
    searchInput.value = '';
    this.inputSearch.isInputChange = false;
  }

  hideShowSelects(isHide = false) {
    if (this.modalService.isMobileView && (this.fromBuildingManagersModal || this.fromFilterModal)) {
      const selects = document.querySelectorAll('mat-select');
      selects.forEach((elem: any) => elem.style.visibility = isHide ? 'hidden' : 'visible');
    } else {
      return;
    }
  }

  setPanelClass() {
    if (this.fromBuildingManagersModal) { return 'building-managers-panel'; }
    if (this.fromFilterModal) { return 'from-filter-panel' }
  }

  ngOnDestroy() {
    this.allSelected && this.allSelected.deselect();
  }

}
