import { Component, OnInit } from '@angular/core';
import { NavParams, NavController } from '@ionic/angular';

import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-ongoing-group-reservation-err-modal',
  templateUrl: './ongoing-group-reservation-err-modal.component.html',
  styleUrls: ['./ongoing-group-reservation-err-modal.component.scss'],
})
export class OngoingGroupReservationErrModalComponent implements OnInit {

  options = this.params.get('options');

  constructor(
    private modalService: ModalService,
    private params: NavParams
  ) { }

  ngOnInit() {}

  back() {
    this.modalService.dismiss();
  }

  setErrorTitle() {
    if (this.options.message) {

      if (this.options.isDeleteUserInGroup) {
        return 'Group member deleted successfully';
      }

      if (this.options.isDeleteBookingInGroup) {
        return 'Booking deleted successfully'
      } else {
        return 'Group deleted successfully';
      }
    }

    return this.options.isDeleteUserInGroup
      ? 'This user has an ongoing reservation'
      : 'This group has an ongoing reservation';
  }

  setErrorDescription() {
    if (this.options.message) { return this.options.message; }

    if (this.options.isDeleteUserInGroup) {
      return 'You can delete this user once their reservation is over.';
    }

    if (this.options.isDeleteBookingInGroup) {
      return 'You can remove a group from their reservation only before the start of a session.';
    } else {
      return 'You can delete this group once their reservation is over.';
    }
  }

}
