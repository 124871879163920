import { Component, OnInit, Input, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';

import { ModalController, AlertController } from '@ionic/angular';

import { BookingService } from './../../services/booking/booking.service';
import { RoomsService } from './../../services/rooms/rooms.service';
import { DateService } from './../../services/date/date.service';
import { ToastService } from './../../services/toast/toast.service';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {

  @ViewChildren('firstPickerOptions') firstPickerOptions;
  @ViewChildren('secondPickerOptions') secondPickerOptions;
  @Input() options;

  startTime = null;
  endTime = null;
  selectedTime = null;
  selectedTimeForView;

  categoriesToDisable;
  selectedCategoriesArr = [];
  sortedCategoriesArr = [];
  bookedCategories;
  formattedTimeCategories = [];

  startTExtend = null;

  endTimeCalled = false;
  isBookedCategoryInRange = false;

  pickerValues = [
    '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
    '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM', '12 AM'
  ];

  categoryValues = [
    { disabled: false, value: '6:00 AM – 9:00 AM', selected: false },
    { disabled: false, value: '9:00 AM – 12:00 PM', selected: false },
    { disabled: false, value: '12:00 PM – 3:00 PM', selected: false },
    { disabled: false, value: '3:00 PM – 6:00 PM', selected: false },
    { disabled: false, value: '6:00 PM – 9:00 PM', selected: false },
    { disabled: false, value: '9:00 PM – 12:00 AM', selected: false },
  ];

  alertOptions = {
    header: 'Select time'
  };
  disabledOptions = 18;
  peopleAmount = 1;

  selectedStartTime;
  startTimeOptions = [];
  endTimeOptions = [];
  convertedStartTime;

  constructor(
    public modalController: ModalController,
    public alertController: AlertController,
    public router: Router,
    public bookingService: BookingService,
    private dateService: DateService,
    private roomsService: RoomsService,
    private location: LocationStrategy,
    private toastService: ToastService
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit() { }

  ionViewDidEnter() {
    if (this.bookingService.city) {
      this.options.selectedDate && (this.bookingService.selectedDateObj = this.options.selectedDate);
    }
  }

  ionViewWillEnter() {
    this.setBookedTimeForMultiDate();
    this.disableBookedCategories();

    // Previous logic for time picker

    // this.getExtendDisabledOptions();
    // this.startTimeOptions = this.firstPickerOptions._results.map(el => el.el);
    // this.endTimeOptions = this.secondPickerOptions._results.map(el => el.el);
    // this.getExtendDisabledOptions();
    // this.checkStartTime();
  }

  setBookedTimeForMultiDate() {
    if (this.bookingService.multiDatesArr.length) {
      this.categoryValues.forEach(elem => elem.disabled = true);
      this.bookingService.timeStart = '6 AM';
      this.bookingService.timeEnd = '12 AM';
    }
  }

  disableBookedCategories() {
    this.disableExtendCategory();

    if (!this.bookingService.selectedDateObj) { return; }

    this.formatTimeCategoriesToDisable();

    this.bookedCategories = this.categoryValues.filter(category => {
      return this.bookingService.selectedDateObj.selectedTimeCategories.includes(this.formatCategoryTime(category.value)) ||
        this.formattedTimeCategories.includes(this.dateService.formatIn24(category.value.split(' – ')[0]));
    });

    this.bookedCategories.forEach(elem => elem.disabled = true);
  }

  formatTimeCategoriesToDisable() {
    this.formattedTimeCategories = this.bookingService.selectedDateObj.selectedTimeCategories &&

      this.bookingService.selectedDateObj.selectedTimeCategories.flatMap(category => {

        let hoursRange = this.bookingService.getValuesBetweenHours(
          this.dateService.formatIn24(category.split(' – ')[0]),
          this.dateService.formatIn24(category.split(' – ')[1])
        );

        if (hoursRange.length > 4) return hoursRange.slice(0, -1);
      });
  }

  disableExtendCategory() {
    if (this.bookingService.isExtend && this.bookingService.city) {

      const categoryToDisable = this.categoryValues.find(category => {
        return this.formatCategoryTime(category.value).includes(
          this.dateService.timePickerCategory(this.bookingService.timeStart, this.bookingService.timeEnd)
        );
      });
      categoryToDisable && (categoryToDisable.disabled = true);
    }
  }

  selectChange(selectedValue) {
    const alreadySelectedCategory = this.selectedCategoriesArr.length >= 2
      && this.selectedCategoriesArr.find(category => category.value === selectedValue);

    if (alreadySelectedCategory) { this.clear(); }
    this.categoryValues.filter(category => category.value === selectedValue).forEach(category => category.selected = true);
    this.selectedCategoriesArr = this.categoryValues.filter(category => category.selected);
    this.setSelectedRange(selectedValue);

    this.isMultiCategoriesSelected()
      ? this.formatTimeForBookingService(null)
      : this.formatTimeForBookingService(selectedValue);

    // Previous logic for time picker

    // const startIndex = (selectedValue && this.pickerValues.indexOf(selectedValue));
    // const endIndex = this.pickerValues.indexOf(this.endTime);
    // this.disabledOptions = startIndex;
    // this.recalculateEndTime(startIndex, endIndex);
    // this.checkEndTime();
  }

  setSelectedRange(selectedValue) {
    if (this.selectedCategoriesArr.length >= 2) {
      const startIndex = this.categoryValues.indexOf(this.selectedCategoriesArr[0])
      const endIndex = this.categoryValues.indexOf(this.selectedCategoriesArr[this.selectedCategoriesArr.length - 1]);
      if (startIndex <= -1 || endIndex <= -1) { return; }

      this.selectedCategoriesArr = this.categoryValues.slice(startIndex, endIndex + 1);
      this.checkBookedCategories(selectedValue);
      this.selectedCategoriesArr.forEach(category => category.selected = true);
    }
  }

  checkBookedCategories(selectedValue) {
    this.isBookedCategoryInRange = this.selectedCategoriesArr.some(category => category.disabled);

    this.isBookedCategoryInRange && (
      this.clear(),
      this.categoryValues.find(category => category.value === selectedValue).selected = true
    );
  }

  formatTimeForBookingService(selectedValue) {
    this.sortCategoriesDueSelection();

    const startVal = this.formatCategoryTime(
      this.isMultiCategoriesSelected()
        ? this.sortedCategoriesArr && this.sortedCategoriesArr[0].category.value.split(' – ')[0]
        : selectedValue.split(' – ')[0]
    );

    const endVal = this.formatCategoryTime(
      this.isMultiCategoriesSelected()
        ? this.sortedCategoriesArr && this.sortedCategoriesArr[this.sortedCategoriesArr.length - 1].category.value.split(' – ')[1]
        : selectedValue.split(' – ')[1]
    );

    this.selectedTimeForView = `, ${startVal} – ${endVal}`;
    this.bookingService.timeStart = startVal;
    this.bookingService.timeEnd = endVal;
  }

  sortCategoriesDueSelection() {
    if (!this.isMultiCategoriesSelected()) return;

    this.sortedCategoriesArr = this.selectedCategoriesArr.map(category => {
      return {
        hours: this.dateService.formatIn24(this.formatCategoryTime(category.value.split(' – ')[0])),
        category
      };
    }).sort((a, b) => a.hours - b.hours);
  }

  isMultiCategoriesSelected() {
    return this.selectedCategoriesArr && this.selectedCategoriesArr.length >= 2;
  }

  formatCategoryTime(value) {
    return value.split(':00').join('');
  }

  formatTime(pickerValue) {
    const pickerValueArray = pickerValue.split(' ');
    pickerValueArray.splice(1, 0, ':00 ');
    return pickerValueArray.join('');
  }

  changeAmountOfPeople(type) {
    if (type === 'add') {
      this.peopleAmount++;
    } else {
      this.peopleAmount > 1 && this.peopleAmount--;
    }
  }

  clear() {
    this.isBookedCategoryInRange = false;
    !this.bookingService.multiDatesArr.length && this.categoryValues.forEach(category => { category.disabled = false; category.selected = false });
    this.bookedCategories && this.bookedCategories.forEach(elem => elem.disabled = true);
    this.disableBookedCategories();
    this.selectedCategoriesArr = [];

    this.endTimeCalled = false;
    !this.bookingService.multiDatesArr.length && (this.bookingService.timeStart = null);
    !this.bookingService.multiDatesArr.length && (this.bookingService.timeEnd = null);
    this.selectedStartTime = null;
    this.selectedTimeForView = '';
    this.peopleAmount = 1;
  }

  async goToResults() {
    this.collectData();
    // this.bookingService.calcHours();
    if (!this.bookingService.room) {
      this.bookingService.fromLiked ? await this.roomsService.getlikedAvailableRooms() : await this.roomsService.getAvailableRooms();
      if (!this.roomsService.roomsByBuilding.length) { this.toastService.presentToast('Sorry, there are no free rooms matching your request', 'error'); return; }
    }

    this.modalController.dismiss();

    if (this.roomsService.historyRooms.length) {
      this.router.navigate([`/room-details/${this.bookingService.room.id}`], { queryParams: { backUrl: 'history' } });
    } else if (this.bookingService.fromLiked && this.bookingService.room) {
      this.router.navigate([`/room-details/${this.bookingService.room.id}`], { queryParams: { backUrl: 'liked' } });
    } else if (this.bookingService.room) {
      this.bookingService.isExtend ? this.router.navigate(['booking-details']) : this.router.navigate([`/room-details/${this.bookingService.room.id}`]);
    } else if (this.bookingService.fromLiked) {
      this.router.navigate(['liked/results'], { queryParams: { fromBookingsPage: true } });
    } else {
      this.router.navigate(['search/results']);
    }
  }

  dismissModal(modal, direction) {
    this.modalController.dismiss({ modal, direction });
    this.roomsService.roomsByBuilding = [];
    this.bookingService.timeStart = null;
    this.bookingService.timeEnd = null;
  }

  collectData() {
    this.bookingService.persons = this.peopleAmount;
    // this.bookingService.timeStart = this.startTime;
    // this.bookingService.timeEnd = this.endTime;
  }

  setTextDueCurrentTime() {
    if (this.bookingService.multiDatesArr.length) { return; }
    if (this.startTime && this.endTime) {
      return `, ${this.startTime} - ${this.endTime}`;
    } else {
      return '';
    }
  }

  checkStartTime() {
    if (this.bookingService.multiDatesArr.length) {
      this.startTime = '6 AM';
      this.endTime = '12 AM';
    }
    this.startTimeOptions.forEach((option, index) => {
      if (this.bookingService.city && this.bookingService.checkAvailableHours(index, true)) {
        option.disabled = true;
      } else if (this.bookingService.checkCurrentDateAndHours(index)) {
        option.disabled = true;
      } else if (index === this.pickerValues.length - 1) {
        option.disabled = true;
      } else if (this.bookingService.isExtend && index <= this.disabledOptions) {
        option.disabled = true;
      } else {
        option.disabled = false;
      }
    });
  }

  checkEndTime() {
    this.endTimeCalled = true;
    let startTime = this.selectedStartTime && this.dateService.formatIn24(this.selectedStartTime);
    this.convertedStartTime = startTime;

    let nextBooking = this.bookingService.selectedDateObj && this.bookingService.selectedDateObj.hours.find(h => h.from > startTime);

    this.endTimeOptions.forEach((option) => {
      option.disabled = nextBooking
        ? this.dateService.formatIn24(option.value) > nextBooking.from || this.dateService.formatIn24(option.value) <= startTime
        : this.dateService.formatIn24(option.value) <= startTime
    });
  }

  recalculateEndTime(startIndex, endIndex) {
    let isToDisable = this.bookingService.selectedDateObj && this.bookingService.selectedDateObj.hours.some((hour) => this.convertedStartTime >= hour.from);
    if (this.endTimeCalled && !isToDisable) {
      (startIndex >= endIndex) && (this.endTime = this.pickerValues[startIndex + 1]);
    } else if (this.endTimeCalled && isToDisable) {
      this.endTime = this.pickerValues[startIndex + 1];
    } else {
      return;
    }
  }

  getExtendDisabledOptions() {
    if (this.bookingService.isExtend && this.bookingService.city) {
      this.startTExtend = this.dateService.formatTime12Hours(this.bookingService.timeStart);
      const startIndex = (this.startTExtend && this.pickerValues.indexOf(this.startTExtend));
      this.disabledOptions = startIndex;
    }
  }

}
