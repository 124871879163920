import { Injectable } from '@angular/core';

import { LoaderService } from 'src/app/services/loader/loader.service';
import { RequestService } from 'src/app/services/request/request.service';
import { RoomCreationService } from '../room-creation/room-creation.service';
import { RoomUpdatingService } from '../room-updating/room-updating.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  facilities;
  facilitiesRequest = this.requestService.getRequest('facilities');
  room;

  constructor(
    private requestService: RequestService,
    private loaderService: LoaderService,
    private roomCreationService: RoomCreationService,
    private roomUpdatingService: RoomUpdatingService,
    private toastService: ToastService
  ) { }

  async getFacilities() {
    await this.loaderService.presentLoader();
    this.facilitiesRequest.subscribe(
      response => {
        this.facilities = response;
        this.loaderService.dismissLoader();
      },
      err => {
        this.toastService.presentToast(err.message, 'error');
        this.loaderService.dismissLoader();
        console.log(err)
      }
    );
  }

  addFacility(event, facilityId, type) {
    if (type === 'create') {
      event.target.checked
        ? this.roomCreationService.roomData.facilities.push(facilityId)
        : this.roomCreationService.roomData.facilities = this.roomCreationService.roomData.facilities.filter(id => id !== facilityId);
    } else {
      event.target.checked
        ? this.roomUpdatingService.roomData.facilities.push(facilityId)
        : this.roomUpdatingService.roomData.facilities = this.roomUpdatingService.roomData.facilities.filter(id => id !== facilityId);
    }
  }

  pushFacilityToRoomData(room) {
    room.facilities.forEach(facility => {
      this.facilities.forEach(fac => {
        if (facility.id === fac.id) {
          this.roomUpdatingService.roomData.facilities = this.roomUpdatingService.roomData.facilities.filter(id => id !== facility.id);
          this.roomUpdatingService.roomData.facilities.push(facility.id);
        }
      });
    });
  }

  clearState() {
    this.roomUpdatingService.roomData.facilities = [];
  }
}
