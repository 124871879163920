import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

import { RequestService } from '../../../services/request/request.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { ModalService } from '../../../services/modal/modal.service';
import { BuildingService } from '../building/building.service';
import { ToastService } from '../../../services/toast/toast.service';
import { EventsService } from '../../../services/events/events.service';
import { forkJoin, from } from 'rxjs';
import { concatMap, delay, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoomUpdatingService {

  imagesToUpload = [];
  localPlanPictures = [];
  localRoomPictures = [];
  imagesToDelete = [];

  imageToUpdate;
  updatedRoomResponse;

  roomData = {
    name: '',
    buildingId: 0,
    price: 0,
    chair: 0,
    description: '',
    floor: 0,
    number: 0,
    lockId: 0,
    serviceFee: {},
    facilities: []
  };

  awsBaseUrl = environment.awsBaseUrl;

  close;

  constructor(
    private requestService: RequestService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private buildingService: BuildingService,
    private toastService: ToastService,
    private events: EventsService
  ) { }

  putPicturesLocally(pictures, result, name, type) {
    pictures.push({ storage: result, name: name, type: type });
  }

  putFiles(files, file) {
    files.push({ photoFile: file, name: file.file.name });
  }

  checkImageAlreadyPosted() {
    this.imagesToDelete = this.imagesToDelete.filter(item => item.roomId);
  }

  async updateRoomData(data, room) {
    this.roomData = data;
    this.checkImageAlreadyPosted();

    await this.loaderService.presentLoader();

    try {
      this.updatedRoomResponse = await this.requestService.patchAuthRequest(`rooms/${room.id}`, this.roomData).toPromise();
      this.parseUpdateResponse(room, this.updatedRoomResponse);
      this.createRequestDueImages(room)
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  async createRequestDueImages(room) {
    if (this.imagesToUpload.length && this.imagesToDelete.length && this.imageToUpdate) {
      await this.uploadImagesToAws(room);
      await this.updateCoverImage();
      await this.deleteImages();
    } else if (this.imagesToUpload.length && this.imagesToDelete.length) {
      await this.uploadImagesToAws(room);
      await this.deleteImages();
    } else if (this.imagesToUpload.length) {
      await this.uploadImagesToAws(room, true);
    } else if (this.imagesToDelete.length && this.imageToUpdate) {
      await this.updateCoverImage();
      await this.deleteImages();
    } else if (this.imagesToDelete.length) {
      await this.deleteImages();
    } else {
      this.loaderService.dismissLoader();
      this.buildingService.clearState();
      this.clearState();
      this.closeModal();
      return;
    }
  }

  imagesKeysRequest(room) {
    return this.requestService.getRequest(`file-uploader?path=rooms/${room.id}`) as any;
  }

  awsUploadRequest(uploadUrl, file) {
    return this.requestService.uploadRequest(uploadUrl, file);
  }

  async uploadImagesToAws(room, isLoaderDismissRequired = false) {

    try {
      const fileUploaderResponse = await from(this.imagesToUpload.map(() => this.imagesKeysRequest(room))).pipe(
        concatMap(item => item.pipe(delay(100))),
        toArray()
      ).toPromise();
      const dataToUpload = this.createDataToUpload(fileUploaderResponse);

      await forkJoin(dataToUpload.map((img) => this.awsUploadRequest(img.uploadURL, img.file))).toPromise();
      await this.uploadImagesToServer(fileUploaderResponse, room.id, isLoaderDismissRequired);
    } catch (err) {
      console.log(err);
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  async uploadImagesToServer(fileUploaderResponse, roomId, isLoaderDismissRequired = false) {
    const keys = fileUploaderResponse.map((imgData: any) => imgData.Key.split('/')[2]);

    const images = this.imagesToUpload.map((image, index) => {
      return { type: image.photoFile.type, storage: keys[index] };
    });

    try {
      await this.requestService.postAuthRequest('images', { roomId, images }).toPromise();
      if (!isLoaderDismissRequired) { return; }
      this.loaderService.dismissLoader();
      this.clearState();
      this.buildingService.clearState();
      this.closeModal();
    } catch (err) {
      console.log(err);
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  createDataToUpload(fileUploaderResponse) {
    const dataToUpload = this.imagesToUpload.map((img, index) => {
      return { file: img.photoFile.file, uploadURL: fileUploaderResponse[index].uploadURL }
    });

    return dataToUpload;
  }

  async updateCoverImage() {
    try {
      await this.requestService.patchAuthRequest(`images/${this.imageToUpdate.id}`, { type: 'cover' }).toPromise();
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  async deleteImages() {
    const imagesIdsToDelete = this.imagesToDelete.map(image => image.id);

    try {
      await this.requestService.deleteAuthRequest('images', { imageIds: imagesIdsToDelete }).toPromise();
      this.loaderService.dismissLoader();
      this.buildingService.clearState();
      this.clearState();
      this.closeModal();
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  clearState() {
    this.imagesToUpload = [];
    this.imagesToDelete = [];
    this.localPlanPictures = [];
    this.localRoomPictures = [];
    this.roomData.facilities = [];
  }

  addImagesLocally(images, room) {
    images.length && (room.images = room.images.concat(images));
  }

  deleteImagesLocally(images, room) {
    images.length && images.forEach(img => {
      room.images = room.images.filter(image => image.id !== img.id);
    });
  }

  closeModal() {
    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      this.events.onCloseModal(true);
    } else {
      this.modalService.closeDesktopModal('RoomModal');
      this.close.emit();
    }

  }

  parseUpdateResponse(room, response) {
    for (let key in room) {
      for (let propt in response) {
        if (key === propt) {
          room[key] = response[propt];
        }
      }
    }
  }
}
