import { Injectable } from '@angular/core';
import { DateService } from '../../services/date/date.service';
import { DatePipe } from '@angular/common';
import { RequestService } from '../../services/request/request.service';
import { LoaderService } from '../../services/loader/loader.service';
import { EventsService } from '../events/events.service';

import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  city;
  building;
  date;
  timeStart;
  timeEnd;
  persons;
  hours = 0;
  room;
  tax = 0.15;
  totalPrice;
  selectedDateObj;
  currentRoomBookings = [];
  hoursBetween = [];
  multiDatesArr = [];
  reservationBookingData;
  roomsWithBookings;
  bookedRoomsResponse;
  bookedGroupsData;
  processedBookings = [] as any[];
  userBookings;

  days;

  fromLiked = false;
  isExtend = false;
  fromPayment = false;

  currentHours = parseInt(new Date().toLocaleString('en-US', { hour: 'numeric', hour12: false }), 10);
  currentDate = `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`;
  pickerDate;

  isCurrentDate = false;
  datePipe = new DatePipe('en-US');

  bookingDataExtend = {
    modalToShow: '',
    roomId: '',
    bookingId: '',
    bookingStart: '',
    bookingEnd: ''
  }

  constructor(
    private dateService: DateService,
    private requestService: RequestService,
    private loaderService: LoaderService,
    private events: EventsService
  ) { }

  reset() {
    this.city = this.building = this.date = this.timeStart = this.timeEnd = this.persons = this.room = null;
    this.fromLiked = false;
    this.isExtend = false;
  }

  calcHours() {
    this.hours = parseInt(this.dateService.formatIn24(this.timeEnd), 10) - parseInt(this.dateService.formatIn24(this.timeStart), 10);
  }

  checkCurrentDateAndHours(i) {
    (this.currentHours === 24 || (this.currentHours <= 6 && this.currentHours >= 1)) && this.isCurrentDate && (this.currentHours = 5);
    return (i + 6) <= this.currentHours && this.isCurrentDate;
  }

  checkAvailableHours(i, isFirstTimePicker) {
    if (this.selectedDateObj) {
      return this.selectedDateObj.hours.some(h => {
        this.hoursBetween = isFirstTimePicker ? this.getValuesBetweenHours(h.from, (h.to - 1)) : this.getValuesBetweenHours((h.from + 1), h.to);
        return this.hoursBetween.some(h => h === i + 6);
      });
    }
  }

  getValuesBetweenHours(min, max, i = 1) {
    return [...Array(max - min + i).keys()].map(i => (i + min));
  }

  returnTransformedDate() {
    if (this.multiDatesArr.length) {
      this.days = parseInt(this.datePipe.transform(new Date(this.multiDatesArr[1].time), 'd'), 10) - parseInt(this.datePipe.transform(new Date(this.multiDatesArr[0].time), 'd'), 10);
      return this.transformDateWithPipe(new Date(this.multiDatesArr[0].time), new Date(this.multiDatesArr[1].time));
    } else {
      return this.datePipe.transform(this.date, 'MMM d');
    }
  }

  returnTransformedDateFullMonth() {
    if (this.multiDatesArr.length) {
      this.days = parseInt(this.datePipe.transform(new Date(this.multiDatesArr[1].time), 'd'), 10) - parseInt(this.datePipe.transform(new Date(this.multiDatesArr[0].time), 'd'), 10);
      return this.transformDateWithPipeFullMonth(new Date(this.multiDatesArr[0].time), new Date(this.multiDatesArr[1].time));
    } else {
      return this.datePipe.transform(this.date, 'MMMM d, YYYY');
    }
  }

  transformDateWithPipe(dateStart, dateEnd) {
    const isMonthEqual = dateStart.getMonth() === dateEnd.getMonth();
    const isDaysEqual = dateStart.getDate() === dateStart.getDate();
    const isYearEqual = dateStart.getFullYear() === dateEnd.getFullYear();

    if (isMonthEqual && isDaysEqual && !isYearEqual) {
      return `${this.datePipe.transform(dateStart, 'MMM d, YYYY')} - ${this.datePipe.transform(dateEnd, 'MMM d, YYYY')}`;
    } else if (isMonthEqual) {
      return `${this.datePipe.transform(dateStart, 'MMM d')} - ${this.datePipe.transform(dateEnd, 'd, YYYY')}`;
    } else if (!isYearEqual) {
      return `${this.datePipe.transform(dateStart, 'MMM d, YYYY')} - ${this.datePipe.transform(dateEnd, 'MMM d, YYYY')}`;
    } else {
      return `${this.datePipe.transform(dateStart, 'MMM d')} - ${this.datePipe.transform(dateEnd, 'MMM d, YYYY')}`;
    }
  }

  transformDateWithPipeFullMonth(dateStart, dateEnd) {
    const isMonthEqual = dateStart.getMonth() === dateEnd.getMonth();
    const isDaysEqual = dateStart.getDate() === dateStart.getDate();
    const isYearEqual = dateStart.getFullYear() === dateEnd.getFullYear();

    if (isMonthEqual && isDaysEqual && !isYearEqual) {
      return `${this.datePipe.transform(dateStart, 'MMMM d, YYYY')} - ${this.datePipe.transform(dateEnd, 'MMMM d, YYYY')}`;
    } else if (isMonthEqual) {
      return `${this.datePipe.transform(dateStart, 'MMMM d')} - ${this.datePipe.transform(dateEnd, 'd, YYYY')}`;
    } else if (!isYearEqual) {
      return `${this.datePipe.transform(dateStart, 'MMMM d, YYYY')} - ${this.datePipe.transform(dateEnd, 'MMMM d, YYYY')}`;
    } else {
      return `${this.datePipe.transform(dateStart, 'MMMM d')} - ${this.datePipe.transform(dateEnd, 'MMMM d, YYYY')}`;
    }
  }

  setTextDueCurrentTime(startTime, endTime) {
    if (this.multiDatesArr.length) { return; }

    if (this.isExtend && startTime && endTime) {
      return `, ${this.dateService.formatTime12Hours(startTime)} - ${this.dateService.formatTime12Hours(endTime)}`;
    } else if (startTime && endTime) {
      return `, ${startTime} - ${endTime}`;
    } else {
      return '';
    }
  }

  async getBookingsById(bookingIds = null, isMulty = false) {
    let bookingRequests = bookingIds && bookingIds.map(id => this.requestService.getAuthRequest(`bookings/${id}`));

    try {
      const response = isMulty
        ? await forkJoin(bookingRequests).toPromise()
        : await this.requestService.getAuthRequest(`bookings/${bookingIds[0]}`).toPromise();

      return isMulty ? response : [response];
    } catch (err) {
      console.log(err);
    }
  }

  async getBookings() {
    const requests = {
      myBookingsRequest: this.requestService.getAuthRequest('booked-rooms'),
      groupBookingsRequest: this.requestService.getAuthRequest('groups/bookings')
    };

    try {
      const response = await forkJoin([requests.myBookingsRequest, requests.groupBookingsRequest]).toPromise() as any;
      this.bookedRoomsResponse = response[0];
      this.bookedGroupsData = response[1];
      return this.bookingsInProcess();
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  bookingsInProcess() {
    this.userBookings = Object.values(this.bookedRoomsResponse).flatMap((booking) => booking);
    
    const groupBookings = Object.values(this.bookedGroupsData.bookings);
    groupBookings.forEach((booking: any) => booking.isGroupBooking = true);

    this.userBookings.filter(booking => groupBookings.some((gBooking: any) => booking.id == gBooking.id)).forEach(booking => {
      booking.isGroupAdmin = true;
      booking.isGroupBooking = true;
    });

    const allUserBookings = groupBookings.concat(this.userBookings);
    const bookingsToFilter = [...new Map(allUserBookings.map((booking) => [booking['id'], booking])).values()];
    return this.ongoingBookings(bookingsToFilter);
  }

  ongoingBookings(bookings) {
    return bookings.filter(booking => booking.isOngoing);
  }

  checkBookingsInterval() {
    if (!this.userBookings?.length) { return; }
    const nextBookingStartTime = new Date(this.userBookings[0].bookingStart).getTime();
    const intervalToNextBooking = nextBookingStartTime - this.currentTimeWithBuildingTimeZone(this.userBookings[0].room.building.timeZone);

    if (intervalToNextBooking < 0) { return; }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, intervalToNextBooking);
    })
  }

  currentTimeWithBuildingTimeZone(timeZone) {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + (timeZone));
    return currentDate.getTime();
  }
}
