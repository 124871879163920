import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ModalService } from '../../../services/modal/modal.service';
import { RequestService } from '../../../services/request/request.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { BuildingService } from '../../services/building/building.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { EventsService } from '../../../services/events/events.service';
import { ModalController, NavParams } from '@ionic/angular';

import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-edit-building-manager',
  templateUrl: './edit-building-manager.component.html',
  styleUrls: ['./edit-building-manager.component.scss']
})
export class EditBuildingManagerComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Input() user;

  showAddingBuilding = false;

  adminsToDelete = [];
  userToShow;

  constructor(
    public modalService: ModalService,
    private requestService: RequestService,
    private loaderService: LoaderService,
    public buildingService: BuildingService,
    private toastService: ToastService,
    public modalController: ModalController,
    private navParams: NavParams,
    private events: EventsService
  ) { }

  async ngOnInit() {
    this.getNavOptions();
    this.setUserBuildings();
    await this.buildingService.getBuildingNamesList();
    this.filterListDueAdminBuildings();
  }

  setUserBuildings() {
    this.user.adminsToDelete && (this.adminsToDelete = this.user.adminsToDelete);
  }

  getNavOptions() {
    if (!this.modalService.isMobileView) { return; }
    const options = this.navParams.get('options');
    this.user = options.user;
  }

  filterListDueAdminBuildings() {
    this.user.buildings.forEach(b => {
      this.buildingService.buildingListNames = this.buildingService.buildingListNames.filter(building => building.id !== b.buildingId);
    });
  }

  removeAdmin(userId, building, isSingleAdmin = false) {
    if (isSingleAdmin) {
      this.adminsToDelete.push({ userId, buildingId: building.buildingId });
      building = null;
      return;
    }
    const index = this.user.buildings.indexOf(building);
    index > -1 && this.user.buildings.splice(index, 1);

    this.adminsToDelete.push({ userId, buildingId: building.buildingId });
  }

  async deleteBuildingAdmins() {
    await this.loaderService.presentLoader();

    try {
      await this.deleteAdminsRequest().toPromise();
      this.loaderService.dismissLoader();
      this.toastService.presentToast('Building deleted successfully.');
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  async updateAdmins(buildingId) {
    await this.loaderService.presentLoader();
    const body = [{ userId: this.user.id, buildingId }];

    if (this.adminsToDelete.length) {
      try {
        await forkJoin([this.deleteAdminsRequest(), this.addAdminsRequest(body)]).toPromise();
        this.toastService.presentToast('Building updated successfully.');
        this.loaderService.dismissLoader();
      } catch (err) {
        this.loaderService.dismissLoader();
        this.toastService.presentToast(err.message, 'error');
      }
    } else {
      await this.addBuildingAdmins(body);
    }
  }

  async addBuildingAdmins(body) {

    try {
      await this.addAdminsRequest(body).toPromise();
      this.toastService.presentToast('Building added successfully.');
      this.loaderService.dismissLoader();
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  deleteAdminsRequest() {
    return this.requestService.deleteAuthRequest('building-admins', this.adminsToDelete);
  }

  addAdminsRequest(body) {
    return this.requestService.postAuthRequest('building-admins', body);
  }

  async saveChanges(isMobileView = false) {
    if (this.showAddingBuilding || this.buildingService.chosenBuilding) {
      const buildingIdToAdd = this.buildingService.buildingListNames.find(building => building.name.trim() === this.buildingService.chosenBuilding).id;
      buildingIdToAdd && await this.updateAdmins(buildingIdToAdd);
    } else {
      await this.deleteBuildingAdmins();
    }
    this.closeModalsClearState(isMobileView, true);
  }

  closeModalsClearState(isMobileView = false, closeEmmiterRequired = false) {
    this.modalService.closeDesktopModal('EditBuildingManagerModal');
    this.buildingService.chosenBuilding = null;
    this.adminsToDelete = [];
    this.buildingService.buildingListNames = [];

    if (isMobileView) {
      closeEmmiterRequired && this.events.onCloseModal(true);
      this.modalController.dismiss();
    } else {
      closeEmmiterRequired && this.close.emit();
    }
  }

  back() {
    this.modalService.dismiss();
    this.adminsToDelete.length && (this.user.adminsToDelete = this.adminsToDelete);
    import('../../components/user-details-modal/user-details-modal.component').then(m =>
      this.modalService.presentModal(m.UserDetailsModalComponent, 'auto-height', { user: this.user })
    );
  }

  openAddingModal() {
    this.modalService.dismiss();
    this.user.adminsToDelete = this.adminsToDelete;
    import('../../components/manager-building-adding-mobile/manager-building-adding-mobile.component').then(m =>
      this.modalService.presentModal(m.ManagerBuildingAddingMobileComponent, 'auto-height', { isResizeRequired: true, user: this.user })
    );
  }

}
