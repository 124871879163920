import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

import { CalendarModal, CalendarComponentOptions, CalendarModalOptions } from 'ion2-calendar';
import { ModalController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { DateService } from 'src/app/services/date/date.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { BookingService } from 'src/app/services/booking/booking.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {

  @Input() isCalendarOpen;
  @Input() buildingIds;
  @Input() calendarData;
  @Input() promocode;
  @Input() isFromPromocode;

  @Output() closeEvent = new EventEmitter();
  @Output() filterEvent = new EventEmitter();
  @Output() onPromoDateChange = new EventEmitter();

  date;
  calendarComponentOptions: CalendarComponentOptions;
  calendarOptions: CalendarModalOptions;
  dateOnChange;
  isDateSetted;
  public dateForm: UntypedFormGroup;
  dateToShow;

  modal;
  clearListener;
  continueListener;
  continueButton;

  constructor(
    private modalController: ModalController,
    public dateService: DateService,
    private formBuilder: UntypedFormBuilder,
    public modalService: ModalService,
    private renderer: Renderer2,
    private bookingService: BookingService,
  ) { }

  async ngOnInit() {
    this.isDateSetted = false;
    this.initForm();

    this.setPreviousSelectedDate();

    if (this.modalService.isMobileView) {
      this.checkPromoOnInit();
      this.createOptionsMobile();
      await this.presentCalendarModalMobile();
    } else {
      this.isCalendarOpen && await this.presentCalendarModal();
    }
  }

  checkPromoOnInit() {
    (this.isFromPromocode && !this.promocode.validFrom) && (this.calendarData = { start: null, end: null })
  }

  async presentModal(component, cssClass = 'auto-height', options = null) {
    this.modal = await this.modalController.create({
      component,
      cssClass,
      backdropDismiss: false,
      componentProps: { options }
    });
    this.modal.onDidDismiss().then((data) => {
      this.modalService.removeListenersFromKeyboard();
      (data.role === 'cancel' || data.role === 'done') && this.closeEvent.emit(false);
    });
    return await this.modal.present().then((data) => { });
  }

  setPreviousSelectedDate() {
    this.date = (this.calendarData?.start && this.calendarData?.end)
      ? { from: this.calendarData.start.split('T')[0], to: this.calendarData.end.split('T')[0] }
      : null;
  }

  initForm() {
    this.dateForm = this.formBuilder.group({
      from: [
        {
          value: this.calendarData?.start
            ? this.dateService.dateForStatsView(this.calendarData.start)
            : '',
          disabled: true
        }, Validators.compose([Validators.required])],
      to: [
        {
          value: this.calendarData?.end
            ? this.dateService.dateForStatsView(this.calendarData.end)
            : '',
          disabled: true
        }, Validators.compose([Validators.required])],
    }, { updateOn: 'change' });
  }

  async presentCalendarModal() {
    this.createCalendarOptions();

    const modal = await this.modalController.create({
      component: CalendarModal
    });
  }

  onDateSelect() {
    if (!this.date) { this.setDataForEmitters(false); return; }
    const start = new Date(this.date.from).toISOString();
    const end = new Date(this.date.to).toISOString();

    this.setDataForEmitters(true, start, end);
    this.setChangedDatesForPromocode(start, end);
  }

  setChangedDatesForPromocode(start, end) {
    if (!this.isFromPromocode) { return; }

    this.promocode.validFrom = start;
    this.promocode.validTo = end;
    this.onPromoDateChange.emit(this.promocode);
  }

  onSelectStart(date) {
    this.dateForm.controls.from.patchValue(this.dateService.dateForStatsView(new Date(date.time)));
  }

  setDataForEmitters(isDateExists, start = null, end = null) {

    if (isDateExists) {
      this.isDateSetted = true;
      this.filterEvent.emit({ detail: { value: this.buildingIds }, start, end, isDateChanged: true });
    } else {
      this.filterEvent.emit({ detail: { value: this.buildingIds }, isReset: true });
    }

    this.closeEvent.emit(false);
  }

  onSelectEnd(date) {
    this.dateForm.controls.to.patchValue(this.dateService.dateForStatsView(new Date(date.time)));
  }

  onDateReset() {
    this.resetDates();
  }

  closeCalendar() {
    this.isDateSetted && this.resetDates();
    this.closeEvent.emit(false);
  }

  resetDates() {
    this.calendarData = null;
    this.dateForm.controls.from.reset();
    this.dateForm.controls.to.reset();
    this.date = null;
  }

  createCalendarOptions() {
    this.calendarComponentOptions = {
      from: new Date(2021, 11, 1),
      showMonthPicker: true,
      pickMode: 'range',
      weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthFormat: 'MMMM, YYYY',
      weekStart: 1,
      color: 'light',
      daysConfig: []
    }
  }

  createOptionsMobile() {
    this.calendarOptions = {
      pickMode: 'range',
      title: this.isFromPromocode && this.promocode.building ? this.promocode.building : 'Back',
      weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthFormat: 'MMMM, YYYY',
      weekStart: 1,
      step: 3,
      cssClass: 'modal-calendar',
      color: 'light',
      closeLabel: '',
      doneLabel: ' ',
      clearLabel: ' ',
      defaultDateRange: (this.calendarData?.start && this.calendarData?.end) && { from: this.calendarData.start.split('T')[0], to: this.calendarData.end.split('T')[0] },
      daysConfig: [],
      from: this.isFromPromocode ? new Date() : new Date(2021, 11, 1),
      defaultScrollTo: (this.calendarData?.start && this.calendarData?.end) ? this.calendarData.start.split('T')[0] : new Date()
    };
  }

  async presentCalendarModalMobile() {
    await this.presentModal(CalendarModal, 'custom-calendar admin', this.calendarOptions);
    this.addButtons();
  }

  addButtons() {
    const ionBtn = document.querySelector('.buttons-first-slot ion-button');
    const calendarContent = document.querySelector('.calendar-page');
    const buttonsWrapper = this.renderer.createElement('div');

    const backBtn = this.renderer.createElement('ion-icon');
    this.renderer.addClass(backBtn, 'back-button');
    this.renderer.setAttribute(backBtn, 'src', '../../../assets/back-button.svg');

    const style = this.renderer.createElement('style');
    this.renderer.addClass(buttonsWrapper, 'calendar-buttons-wrapper');
    buttonsWrapper.innerHTML = `
    <div class="calendar-buttons-wrapper">
      <button class="btn white-button" id="clearButton">Clear</button>
      <button class="btn black-button" id="continueButton" disabled>Set date</button>
    </div>`;
    style.innerHTML = `
      .calendar-buttons-wrapper {
        position: fixed;
        transform: translate3d(0, 0, 0);
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 9px 21px calc(9px + env(safe-area-inset-bottom)) 21px;
        background: #FEFEFE;
        z-index: 30000;
      }

      .back-button {
        height: 12px;
        width: 6px;
        color: #282828;
        margin-right: 22px;
        z-index: 30000;
      }
    `;
    this.renderer.appendChild(calendarContent, style);
    this.renderer.appendChild(calendarContent, buttonsWrapper);
    this.renderer.appendChild(ionBtn, backBtn);
    this.addButtonsListeners();
    this.addDateInHeader();
  }

  addButtonsListeners() {
    const clearButton: any = document.querySelector('.calendar-buttons-wrapper .white-button');
    const continueButton = document.getElementById('continueButton');
    const calendarHeader = document.querySelector('ion-toolbar');
    const clearButtonNative = calendarHeader.querySelectorAll('ion-button')[1];
    const continueButtonNative = calendarHeader.querySelectorAll('ion-button')[2];
    const clickEvent = new Event('click');
    clearButtonNative.style.display = 'none';
    continueButtonNative.style.display = 'none';

    this.onClear(calendarHeader, clearButtonNative, clickEvent);
    this.onContinue(clearButton, continueButton);

    clearButton.addEventListener('click', this.clearListener);
    continueButton.addEventListener('click', this.continueListener);
    this.continueButton = continueButton;
  }

  removeListeners(clearButton, continueButton) {
    clearButton.removeEventListener('click', this.clearListener);
    continueButton.removeEventListener('click', this.continueListener);
  }

  addDateInHeader() {
    const calendarHeader = document.querySelector('ion-toolbar');
    this.setHeaderDateDuePreviousSelection(calendarHeader);

    CalendarModal.prototype.onChange = ($event) => {
      if ($event[1] && $event[0].time !== $event[1].time) {
        this.formatDateForMobile($event);
        this.patchValuesForCalendar($event);
        this.createDateRangeWithPipe($event, calendarHeader);
        this.continueButton.disabled = false;
      } else if ($event[1] && $event[0].time === $event[1].time) {
        this.formatDateForMobile($event);
        this.updateCalendarHeader($event[0], calendarHeader);
        this.continueButton.disabled = false;
      } else if ($event[1]) {
        this.formatDateForMobile($event);
        this.patchValuesForCalendar($event);
        this.createDateRangeWithPipe($event, calendarHeader);
        this.continueButton.disabled = false;
      } else {
        this.updateCalendarHeader($event[0], calendarHeader);
        this.continueButton.disabled = true;
      }
    }
  }

  setHeaderDateDuePreviousSelection(calendarHeader) {
    if (this.calendarData?.start && this.calendarData?.end) {
      if (this.calendarData?.start === this.calendarData?.end) {
        this.updateCalendarHeader(this.calendarData?.start, calendarHeader, true);
      } else {
        const pipeRange = this.bookingService.transformDateWithPipe(new Date(this.calendarData?.start), this.checkEndTimeForPromo());
        calendarHeader.setAttribute('date', pipeRange);
      }
      this.continueButton.disabled = false;
    }
  }

  checkEndTimeForPromo() {
    if (this.calendarData?.end.includes('23:59:59')) {
      const endDate = new Date(this.calendarData?.end)
      endDate.setDate(new Date(endDate).getDate() - 1);
      return endDate;
    } else {
      return new Date(this.calendarData?.end);
    }
  }

  onClear(calendarHeader, clearButtonNative, clickEvent) {
    this.clearListener = () => {
      if (this.calendarData?.start && this.calendarData?.end) {
        this.calendarData = null;
        this.date = null;
        calendarHeader.setAttribute('date', '');
        clearButtonNative.dispatchEvent(clickEvent);
        this.continueButton.disabled = false;
        clearButtonNative.disabled = true;
        return;
      }

      if (this.dateToShow) {
        this.dateToShow = null;
        clearButtonNative.disabled = false;
        this.continueButton.disabled = true;
        calendarHeader.setAttribute('date', '');
        clearButtonNative.dispatchEvent(clickEvent);
      } else {
        return;
      }
    };
  }

  onContinue(clearButton, continueButton) {
    this.continueListener = () => {
      this.onDateSelect();
      this.removeListeners(clearButton, continueButton);
      this.modalService.dismiss();
      this.modalService.removeListenersFromKeyboard();
      this.closeEvent.emit(false);
    };
  }

  patchValuesForCalendar(data) {
    this.dateForm.controls.from.patchValue(this.dateService.dateForStatsView(new Date(data[0].time)));
    this.dateForm.controls.to.patchValue(this.dateService.dateForStatsView(new Date(data[1].time)));
  }

  createDateRangeWithPipe(data, element) {
    const pipeRange = this.bookingService.transformDateWithPipe(new Date(data[0].time), new Date(data[1].time));
    element.setAttribute('date', pipeRange);
  }

  updateCalendarHeader(data, calendarHeader, isPreviousSelection = false) {
    this.dateToShow = isPreviousSelection ? new Date(data) : new Date(data.time);
    calendarHeader.setAttribute('date', this.bookingService.datePipe.transform(this.dateToShow, 'MMM dd'));
  }

  formatDateForMobile(dateArr) {
    const timeStart = new Date(dateArr[0].time);
    const timeEnd = new Date(dateArr[1].time);

    let selectedMonthStart = new Date(timeStart).getMonth() + 1;
    let selectedMonthEnd = new Date(timeEnd).getMonth() + 1;

    let selectedDateStart = new Date(timeStart).getDate();
    let selectedDateEnd = new Date(timeEnd).getDate();

    this.date = {
      from: `${timeStart.getFullYear()}-${this.checkSelectedMonthDate(selectedMonthStart)}-${this.checkSelectedMonthDate(selectedDateStart)}`,
      to: `${timeEnd.getFullYear()}-${this.checkSelectedMonthDate(selectedMonthEnd)}-${this.checkSelectedMonthDate(selectedDateEnd)}`
    }
  }

  checkSelectedMonthDate(selectedItem) {
    return selectedItem < 10 ? `0${selectedItem}` : selectedItem;
  }

}
