import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { BookingService } from '../../services/booking/booking.service';

@Component({
  selector: 'app-extend-booking',
  templateUrl: './extend-booking.component.html',
  styleUrls: ['./extend-booking.component.scss'],
})
export class ExtendBookingComponent implements OnInit {

  @Input() options: {
    roomId: number,
    bookingStart: string,
    bookingEnd: string
  };

  constructor(
    public modalController: ModalController,
    private navController: NavController,
    private bookingService: BookingService
  ) { }

  ngOnInit() {
  }

  close() {
    this.modalController.dismiss();
    this.navController.navigateBack('/search');
  }

  goToRoomDetails() {
    this.setDateToExtend();
    this.modalController.dismiss();
    this.navController.navigateForward([`/room-details/${+this.options?.roomId}`]);
  }

  setDateToExtend() {
    this.bookingService.date = new Date(+this.options?.bookingStart);
    this.bookingService.isExtend = true;
    this.bookingService.timeStart = new Date(+this.options?.bookingStart);
    this.bookingService.timeEnd = new Date(+this.options?.bookingEnd);
  }
}
