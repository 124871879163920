import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {

  @Input() bookingsByWeekHours: {
    [key: string]: [{ [key: number]: number }]
  } = {};

  @ViewChild('dayOfWeek') dayOfWeek: ElementRef;

  public barChartType: Chart.ChartType = 'bar';
  public barChartLegend = false;
  public barChartLabels: Label[] = [];
  public daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  public barChartData: ChartDataSets[] = [];
  public selectedDay: string;
  public filteredBarChartLabels: Label[];
  public maxTicksYAxes: number = 20;

  public barChartOptions: ChartOptions = {};

  private getBookingInDay = [];
  private redChartColor = 'rgba(190, 88, 69, 1)';
  private greenChartColor = 'rgba(92, 117, 84, 1)';
  private blackChartColor = 'rgba(40, 40, 40, 1)';
  private chartColors: string[] = [];
  private hoverChartColors: string[] = [];
  private maxBookings: number[];

  constructor(public modalService: ModalService) { }

  ngOnInit() {
    this.checkMaxTicksYAxes();
    this.openChartForLabel('Mon', 0);
  }

  openChartForLabel(day: string, dayIndex: number) {
    this.selectedDay = day;
    this.setBarChartOptions(this.maxBookings[dayIndex]);

    const getAmountBookingsInDay = this.bookingsByWeekHours[day.toLowerCase()];
    const isFilterLabel = Object.keys(getAmountBookingsInDay).length > 8;

    this.barChartLabels = Object.keys(getAmountBookingsInDay).map((el: string, index: number) => {

      let timeOfDay = +el >= 12 ? 'PM' : 'AM';
      if (+el === 24) {
        timeOfDay = 'AM';
      }
      let hours = +el > 12 ? +el - 12 : +el;

      return isFilterLabel && index % 2 !== 0 ? '' : `${hours} ${timeOfDay}`;
    });

    this.getBookingInDay = Object.values(getAmountBookingsInDay);

    const max = Math.max.apply(Math, this.getBookingInDay);

    this.getBookingInDay.forEach((value: number, index: number) => {
      if (value / max < 0.33) {
        this.chartColors[index] = this.redChartColor;
        this.hoverChartColors[index] = this.redChartColor;
      } else if (value / max > 0.33 && value / max < 0.65) {
        this.chartColors[index] = this.blackChartColor;
        this.hoverChartColors[index] = this.blackChartColor;
      } else if (value / max > 0.65) {
        this.chartColors[index] = this.greenChartColor;
        this.hoverChartColors[index] = this.greenChartColor;
      }
    })
    this.barChartData = [
      {
        data: this.getBookingInDay,
        label: day,
        backgroundColor: this.chartColors,
        hoverBackgroundColor: this.hoverChartColors,
        barPercentage: 0.5
      }
    ];
  }

  public setBarChartOptions(maxTicksYAxes: number) {
    this.barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 0,
      legend: {
        labels: {
          fontFamily: 'OpenSans-Regular',
          fontSize: 14,
          fontColor: '#787586'
        }
      },
      scales: {
        xAxes: [
          {
            ticks: {
              display: true,
              autoSkip: true,
              padding: this.modalService.isMobileView ? 5 : 20,
            },
            gridLines: {
              drawBorder: false,
              display: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              display: true,
              beginAtZero: true,
              stepSize: maxTicksYAxes > 30 ? maxTicksYAxes : 5,
              min: 0,
              padding: 10
            },
            gridLines: {
              display: true,
              drawBorder: false,
              color: '#FAFAFA'
            },
          },
        ],
      },
    };
  }

  checkMaxTicksYAxes() {
    this.maxBookings = this.daysOfWeek.map((dayOfWeek) => {
      const bookingsByHours = this.bookingsByWeekHours[dayOfWeek.toLowerCase()];
      return Math.max.apply(null, Object.values(bookingsByHours));
    })
  }
}
