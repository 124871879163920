import { Component, OnInit } from '@angular/core';

// import { StepSelectionService } from '../../../services/step-selection/step-selection.service'
// import { RoomCreationService } from '../../../services/room-creation/room-creation.service'

@Component({
  selector: 'app-add-room-photo',
  templateUrl: './add-room-photo.component.html',
  styleUrls: ['./add-room-photo.component.scss'],
})
export class AddRoomPhotoComponent implements OnInit {

  // status;

  constructor(
    // public stepSelectionService: StepSelectionService,
    // public roomCreationService: RoomCreationService
  ) { }

  ngOnInit() { }

  // convertPicturesFromInput(event) {
  //   const files = event.target.files;
  //   Object.keys(files).forEach(i => {
  //     const file = files[i];
  //     if (!file.type.match(/image/)) { return; }
  //     const reader = new FileReader();
  //     reader.onload = (ev) => {
  //       this.roomCreationService.putPicturesLocally(this.roomCreationService.localRoomPictures, reader.result, file.name);
  //       this.roomCreationService.putFiles(this.roomCreationService.images, { file: file, type: 'cover' });
  //       this.setWrapperStatus(false);
  //     }
  //     reader.readAsDataURL(file);
  //   })
  // }

  // setWrapperStatus(status) {
  //   this.status = status;
  // }

}
