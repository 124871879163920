import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AlertController, Platform } from '@ionic/angular';

import { AngularFireMessaging } from '@angular/fire/messaging';

import { RequestService } from '../request/request.service';
import { ToastService } from '../../services/toast/toast.service';
import { EventsService } from '../events/events.service';
import { BookingService } from '../../services/booking/booking.service';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  deviceToken;
  platform = Capacitor.getPlatform();
  notification = JSON.parse(localStorage.getItem('smartLockPushData'));
  isMobileWeb = false;

  isPushEnabled;
  needUpdateUser;

  constructor(
    public alertController: AlertController,
    public requestService: RequestService,
    private toastService: ToastService,
    private events: EventsService,
    private bookingService: BookingService,
    private angularFireMessaging: AngularFireMessaging,
    private ionPlatform: Platform,
    public route: ActivatedRoute
  ) {
    this.ionPlatform.ready().then(() => {
      if (this.ionPlatform.is('mobileweb')) { this.isMobileWeb = true; return; }
    });

    // Handle background web notification & parse route url
    this.route.queryParams.subscribe(params => {
      params.notification && this.publishDataAfterPushClicked(JSON.parse(params.notification).data);
    });

    // Handle click on web push notifications
    const isSafari = /^((?!chrome|android|crios|fxios).)*safari/i.test(navigator.userAgent);
    if (isSafari || this.platform === 'ios') { return; }

    const webPushChannel = new BroadcastChannel('webPushChannel');
    webPushChannel.onmessage = (message) => {
      this.publishDataAfterPushClicked(message.data);
    }
  }

  async checkPushRegisteringOnAuth(userData) {
    !this.deviceData('deviceToken') ? await this.registerPush() : this.addPushListeners();
    userData.deviceToken = this.deviceData('deviceToken');
    userData.platform = this.deviceData('platform');
  }

  async registerPush(fromNativeSettings = false) {
    if (this.isMobileWeb) { return; }

    if (this.platform === 'web') {
      await this.requestWebPermissions();
      (fromNativeSettings && this.deviceToken) && (this.needUpdateUser = true);
    } else {
      await this.requestUserPermissions(fromNativeSettings);
    };
  }

  async requestWebPermissions() {
    try {
      this.deviceToken = await this.angularFireMessaging.requestToken.pipe(take(1)).toPromise();
      if (!this.deviceToken) { this.toastService.presentToast('Please check permissions in your browser settings.', 'error'); this.isPushEnabled = false; return; }
      this.handleWebPush();
    } catch (error) {
      this.toastService.presentToast('Sorry, your browser doesn`t support push notifications.', 'error');
    }
  }

  handleWebPush() {
    // Handle foreground web push notifications
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.showWebPushInForeground(payload);
      }, error => {
        console.log('Error in handleWebPush', error);
      });
  }

  showWebPushInForeground(payload) {
    const NotificationOptions = {
      body: payload.data.body,
      data: payload.data,
      icon: payload.notification.icon,
      badge: payload.notification.icon,
      silent: false
    }
    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
      registration.showNotification(payload.data.title, NotificationOptions);
    });
  }

  async requestUserPermissions(fromNativeSettings = false) {
    const permissionsResponse = await PushNotifications.requestPermissions();

    if (permissionsResponse.receive === 'granted') {
      await PushNotifications.register();
      await this.addRegistrationListener();
      this.addPushListeners();
      this.needUpdateUser = true;
    } else if (permissionsResponse.receive === 'denied' && fromNativeSettings) {
      this.needUpdateUser = false;
    }
    // TODO: add android
  }

  async addRegistrationListener() {
    return new Promise<void>(async (resolve, reject) => {
      await PushNotifications.addListener('registration', (token: Token) => {
        token && resolve();
        this.deviceToken = token.value;
        console.log('PUSH REGISTERED: ' + token.value);
      });
    });
  }

  addPushListeners() {
    if (this.platform === 'web') { return; }

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.publishDataAfterPushClicked(notification.notification.data.aps.alert);
      },
    );
  }

  async removePushListeners() {
    await PushNotifications.removeAllListeners();
  }

  deviceData(fieldName) {
    return this.notification ? this.notification[fieldName] : this[fieldName];
  }

  async updateUserDevice(user) {
    const notificationData = {
      deviceToken: this.deviceData('deviceToken'),
      platform: this.deviceData('platform')
    };

    try {
      const response = await this.requestService.patchAuthRequest(`users/${user.id}`, notificationData).toPromise();
      this.saveNotification(response);
      this.isPushEnabled = true;
    } catch (error) {
      this.toastService.presentToast(error.message, 'error');
    }
  }

  saveNotification(response) {
    if (!response) { return; }

    if (response.deviceToken || response.userDevices) {
      const userDevicesArr = response.userDevices;
      const notificationData = {
        deviceToken: userDevicesArr ? userDevicesArr[userDevicesArr.length - 1].token : response.deviceToken,
        platform: this.platform
      };

      localStorage.setItem('smartLockPushData', JSON.stringify(notificationData));
    }
  }

  async deleteUserDevice() {
    try {
      await this.requestService.deleteAuthRequest('auth', { deviceToken: this.deviceData('deviceToken') }).toPromise();
    } catch (error) {
      this.toastService.presentToast(error.message, 'error');
    }
  }

  async publishDataAfterPushClicked(data) {
    let bookingsToUnlockModal;
    data.modalToShow === 'unlock' && (bookingsToUnlockModal = await this.checkMultyOrSingleBookings(data));

    this.events.publishRoomId({
      roomId: data.room,
      bookingId: data.bookingId,
      modalToShow: data.modalToShow,
      bookingEnd: data.bookingEnd ? data.bookingEnd : '',
      bookingStart: data.bookingStart ? data.bookingStart : '',
      bookingsToUnlockModal
    });
  }

  async checkMultyOrSingleBookings(pushData) {
    let bookingsToUnlockModal;

    if (pushData.booking.includes(',')) {
      let bookingIds = pushData.booking.split(',').map(bookingId => +bookingId);
      bookingsToUnlockModal = await this.bookingService.getBookingsById(bookingIds, true);
    } else {
      bookingsToUnlockModal = await this.bookingService.getBookingsById([pushData.booking]);
    }
    return bookingsToUnlockModal;
  }

  isPushExists() {
    if (JSON.parse(localStorage.getItem('smartLockPushData'))) {
      return true;
    } else {
      return false;
    }
  }

  async onNativeSettingsChange(user) {
    await this.registerPush(true);
    this.needUpdateUser && await this.updateUserDevice(user);
  }

  isWebPermissionsModalRequired(isUserDataValid) {
    if (!isUserDataValid || JSON.parse(localStorage.getItem('smartLockWebPushDenied'))) { return false; }

    return !this.deviceData('deviceToken');
  }
}
