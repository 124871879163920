import { Injectable } from '@angular/core';

import { ChartDataSets, ChartOptions } from 'chart.js';
import * as Chart from 'chart.js';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  bookedRooms;
  totalRooms;
  availableRooms;

  roomsActivityLegend = [] as any;

  public doughnutChartType: Chart.ChartType = 'doughnut';
  public doughnutChartData: ChartDataSets[] = [];
  public doughnutChartLegend = false;
  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  private greyColor = 'rgba(229,229,229, 1)';
  private greenColor = 'rgba(92, 117, 84, 1)';
  private blackColor = 'rgba(40, 40, 40, 1)';


  constructor() { }

  createActivityRoomsData() {
    this.bookedRooms = this.totalRooms - this.availableRooms;

    this.roomsActivityLegend = [
      {
        status: 'Booked',
        amount: this.bookedRooms,
        colorCircle: '#282828',
      },
      {
        status: 'Available',
        amount: this.availableRooms,
        colorCircle: '#5C7554',
      },
    ];
  }

  createRoundDonutChart() {
    Chart.defaults.RoundedDoughnut = Chart.helpers.clone(
      Chart.defaults.doughnut
    );
    Chart.controllers.RoundedDoughnut = Chart.controllers.doughnut.extend({
      draw: function (easy) {
        let ctx = this.chart.ctx;
        let easingDecimal = easy || 1;
        let arcs = this.getMeta().data;
        Chart.helpers.each(arcs, (arc, i) => {
          arc.transition(easingDecimal).draw();

          let pArc = arcs[i === 0 ? arcs.length - 1 : i - 1];
          let pColor = pArc._view.backgroundColor;

          let vm = arc._view;
          let radius = (vm.outerRadius + vm.innerRadius) / 2;
          let thickness = (vm.outerRadius - vm.innerRadius) / 2;
          let startAngle = Math.PI - vm.startAngle - Math.PI / 2;
          let angle = Math.PI - vm.endAngle - Math.PI / 2;

          ctx.save();
          ctx.translate(vm.x, vm.y);

          ctx.fillStyle = i === 0 ? vm.backgroundColor : pColor;
          ctx.beginPath();
          ctx.arc(
            radius * Math.sin(startAngle),
            radius * Math.cos(startAngle),
            thickness,
            0,
            2 * Math.PI
          );
          ctx.fill();

          ctx.beginPath();
          ctx.arc(
            radius * Math.sin(angle),
            radius * Math.cos(angle),
            thickness,
            0,
            2 * Math.PI
          );

          ctx.restore();
        });
      },
    });

    new Chart('donutChart', {
      type: 'RoundedDoughnut',
      data: {
        labels: [
          'Booked',
          'Available'
        ],
        datasets: [
          {
            data: [this.bookedRooms, this.availableRooms],
            backgroundColor: [
              this.blackColor,
              this.greyColor
            ],
            borderWidth: 0,
          },
          {},
          {
            data: [this.availableRooms, this.bookedRooms],
            backgroundColor: [
              this.greenColor,
              this.greyColor
            ],
            borderWidth: 0,
          },
        ],
      },
      options: {
        tooltips: {
          enabled: false,
        },
        cutoutPercentage: 70,
        legend: {
          display: false
        }
      },
    });
  };

}
