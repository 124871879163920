import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, AfterViewInit {

  public daysArrayCanceled;
  public daysArrayRefunded;

  @Input() public lineLegendData;
  @Input() public statisticData: {
    label: string,
    amount: number,
    colorLine: string
  }[] = [];
  @Input() public lineChartData: ChartDataSets[] = [];
  @Input() public set setBookingsByMonth(bookingsByMonth: []) {
    if (bookingsByMonth) {
      this.bookingsByMonth = bookingsByMonth.map((numberOfBookings: number, index: number) => {
        return {
          numberOfBookings,
          month: this.lineChartLabelsMonths[index]
        }
      })
    }
  };

  @Input() public set setCanceledBookingsByDay(canceledBookingsByDay: { [key: string]: number }) {
    if (canceledBookingsByDay) {
      this.daysArrayCanceled = this.getCanceledBookingsByYearAndMonth(canceledBookingsByDay);
    }
  };

  @Input() public set setRefundedBookingsByDay(refundedBookingsByDay: { [key: string]: number }) {
    if (refundedBookingsByDay) {
      this.daysArrayRefunded = this.getCanceledBookingsByYearAndMonth(refundedBookingsByDay);
    }
  };

  @Input() public canceledBookingsByMonth: [];
  @Input() public canceledBookingsByDay: [];
  @Input() public refundedBookingsByMonth: []

  @ViewChild('canvasLine') canvasLine: ElementRef;
  @ViewChild('tooltip') tooltip: ElementRef;
  @ViewChildren(BaseChartDirective) baseCharts: QueryList<BaseChartDirective>;

  public lineChartLabelsMonths = this.modalService.isMobileView
    ? Array.from({ length: 12 }, (_, i) => i + 1)
    : [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ] as any;
  public bookingsByMonth: { numberOfBookings: number, month: Label }[];
  public lineChartOptions: ChartOptions = {};
  public lineChartColors: Color[] = [];
  public lineChartLegend = false;
  public lineChartType: Chart.ChartType = 'line';
  public tooltipMonth: string;
  public tooltipValue: number;
  public tooltipYear: string = new Date().getFullYear().toString();

  private opacity = 0.3;
  private opacityRefund = 0.8;
  private opacity1 = 0;
  isRefundSelected = false;
  isRefundHovered = false;

  constructor(private modalService: ModalService) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.createLineChart();
    this.createLegend();
    this.createLinearGradient();
  }

  private createLegend() {
    const lineChart = this.baseCharts.find(
      (chart) => chart.chartType === 'line'
    ).chart;
    this.lineLegendData = lineChart.generateLegend();
  }

  createLineChart() {
    this.lineChartOptions = {
      legend: {
        labels: {
          fontFamily: 'OpenSans-Regular',
          fontSize: 14,
          fontColor: '#787586'
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawTicks: false,
            },
            ticks: {
              autoSkip: true,
              padding: 10,
              maxRotation: 0,
              minRotation: 0
            }
          },
        ],
        yAxes: [
          {
            position: 'right',
            ticks: {
              display: true,
              padding: 20,
              precision: 0
            },
            gridLines: {
              display: true,
              drawTicks: false,
              drawBorder: false,
            },
          },
        ],
      },
      tooltips: {
        enabled: false,
        intersect: true,
        mode: 'single',
        position: 'nearest',
        custom: (tooltip) => {
          if (this.modalService.isMobileView) { this.tooltip.nativeElement.style.opacity = '0'; tooltip.opacity = 0; return; }

          if (tooltip.opacity === 0) {
            let tooltipEl = this.tooltip.nativeElement;
            tooltipEl.style.opacity = '0';
            return tooltipEl;
          }

          if (tooltip?.dataPoints?.length) {
            this.tooltipMonth = tooltip?.dataPoints[0]?.label;
            this.tooltipValue = +tooltip?.dataPoints[0]?.value;
            let tooltipEl = this.tooltip.nativeElement;
            let tooltipOffsetX = -93;
            let tooltipOffsetY = 90;
            if (this.isRefundSelected) {
              if (tooltip.dataPoints[0].datasetIndex === 0) {
                this.isRefundHovered = true;
                this.styleTooltip(tooltipEl, tooltip, tooltipOffsetX, tooltipOffsetY);
              }
            } else {
              if (tooltip.dataPoints[0].datasetIndex === 1) {
                this.isRefundHovered = false;
                this.styleTooltip(tooltipEl, tooltip, tooltipOffsetX, tooltipOffsetY);
              }
            }
          }
        },
      },
    };
  }

  styleTooltip(tooltipEl, tooltip, tooltipOffsetX, tooltipOffsetY) {
    tooltipEl.style.display = 'flex';
    tooltipEl.style.transition = 'opacity .4s ease-out';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = tooltip.caretX + tooltipOffsetX + 'px';
    tooltipEl.style.top = tooltip.caretY - tooltipOffsetY + 'px';
  }

  createLinearGradient(isRefundSelected = false) {
    const gradientRefunded = this.canvasLine.nativeElement
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 400);
    if (isRefundSelected) {
      gradientRefunded.addColorStop(this.modalService.isMobileView ? 0.1 : 0.4, 'rgba(120, 117, 134,' + this.opacityRefund + ')')
      gradientRefunded.addColorStop(1, 'rgba(255, 255, 255,' + this.opacity1 + ')');
    }

    const gradientCanceled = this.canvasLine.nativeElement
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 400);
    if (!isRefundSelected) {
      gradientCanceled.addColorStop(0.1, 'rgba(82, 162, 67,' + this.opacity + ')');
      gradientCanceled.addColorStop(1, 'rgba(255, 255, 255,' + this.opacity1 + ')');
    }

    this.lineChartColors = [
      {
        backgroundColor: gradientRefunded,
        borderColor: 'rgba(120, 117, 134, 1)',
        borderWidth: 2
      },
      {
        backgroundColor: gradientCanceled,
        borderColor: 'rgba(92, 117, 84, 1)',
        borderWidth: 2
      },
    ];
  }

  onChangeFilter(value) {
    if (value.detail.value === 'month') {

      this.lineChartData = [
        {
          data: this.daysArrayRefunded,
          label: 'Refunded booking',
          pointHoverBackgroundColor: this.modalService.isMobileView ? 'transparent' : 'rgba(120, 117, 134, 1)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointHoverRadius: 6,
        },
        {
          data: this.daysArrayCanceled,
          label: 'Canceled booking',
          pointHoverBackgroundColor: this.modalService.isMobileView ? 'transparent' : 'rgba(92, 117, 84, 1)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointHoverRadius: 6,
        }
      ];

      this.lineChartLabelsMonths = this.daysArrayCanceled.map((_, index) => {
        return this.modalService.isMobileView ? index + 1 : `${index + 1} ${new Date().toLocaleString('en-Us', { month: 'short' })}`
      });

      this.statisticData = [
        {
          label: 'Refunded booking',
          amount: this.daysArrayRefunded.reduce((acc, cur) => acc + cur, 0),
          colorLine: '#787568'
        },
        {
          label: 'Canceled booking',
          amount: this.daysArrayCanceled.reduce((acc, cur) => acc + cur, 0),
          colorLine: '#5C7554'
        }
      ];
    } else if (value.detail.value == 'year') {

      this.lineChartData = [
        {
          data: this.refundedBookingsByMonth,
          label: 'Refunded booking',
          pointHoverBackgroundColor: this.modalService.isMobileView ? 'transparent' : 'rgba(120, 117, 134, 1)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointHoverRadius: 6,
        },
        {
          data: this.canceledBookingsByMonth,
          label: 'Canceled booking',
          pointHoverBackgroundColor: this.modalService.isMobileView ? 'transparent' : 'rgba(92, 117, 84, 1)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointHoverRadius: 6,
        }
      ];

      this.lineChartLabelsMonths = this.modalService.isMobileView
        ? Array.from({ length: 12 }, (_, i) => i + 1)
        : [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];

      this.statisticData = [
        {
          label: 'Refunded booking',
          amount: this.refundedBookingsByMonth.reduce((acc, cur) => acc + cur, 0),
          colorLine: '#787568'
        },
        {
          label: 'Canceled booking',
          amount: this.canceledBookingsByMonth.reduce((acc, cur) => acc + cur, 0),
          colorLine: '#5C7554'
        }
      ];
    }
  }

  getCanceledBookingsByYearAndMonth(canceledBookingsByDay: { [key: string]: number }): number[] {
    const month = new Date().getMonth() + 1;
    const currentMonth = month < 10 ? '0' + month : month.toString();
    const currentYear = new Date().getFullYear();
    const amountDaysByCurrentMonth = new Date(currentYear, month, 0).getDate();

    const filteredCanceledBookingsByYear = this.filterByYear(canceledBookingsByDay, currentYear)
    const filteredCanceledBookingsByMonth = this.filterByMonth(filteredCanceledBookingsByYear, currentMonth);

    const daysArray = [];
    for (let index = 0; index < amountDaysByCurrentMonth; index++) {
      const localIndex = index + 1;
      const day = localIndex < 10 ? '0' + localIndex : localIndex;
      const key = `${currentYear}-${currentMonth}-${day}`;
      daysArray[index] = filteredCanceledBookingsByMonth[key] ? filteredCanceledBookingsByMonth[key] : 0;
    }

    return daysArray;
  }

  filterByYear(canceledBookingsByDay: { [key: string]: number }, year: number): { [key: string]: number } {
    return Object.entries(canceledBookingsByDay)
      .filter(el => el[0].includes(year.toString()))
      .reduce((accum, [k, v]) => {
        accum[k] = v;
        return accum;
      }, {});
  }

  filterByMonth(canceledBookingsByDay: { [key: string]: number }, month: string): { [key: string]: number } {
    return Object.entries(canceledBookingsByDay)
      .filter(el => el[0].includes('-' + month + '-'))
      .reduce((accum, [k, v]) => {
        accum[k] = v;
        return accum;
      }, {});
  }

  setActiveGraph(data) {
    if (data.label.includes('Refunded')) {
      this.isRefundSelected = true;
      this.createLineChart();
      this.createLinearGradient(true);
    } else {
      this.isRefundSelected = false;
      this.createLineChart();
      this.createLinearGradient();
    }
  }
}
