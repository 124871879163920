import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

import { StepSelectionService } from '../../../services/step-selection/step-selection.service';
import { RoomCreationService } from '../../../services/room-creation/room-creation.service';

@Component({
  selector: 'app-add-room-description',
  templateUrl: './add-room-description.component.html',
  styleUrls: ['./add-room-description.component.scss'],
})
export class AddRoomDescriptionComponent implements OnInit {

  roomForm: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    price: new UntypedFormControl('')
  });

  counterTitleValue = 50;
  counterDescriptionValue = 500;

  constructor(
    public stepSelectionService: StepSelectionService,
    public roomCreationService: RoomCreationService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.roomForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required])],
      description: '',
      price: ['', Validators.compose([Validators.required])]
    }, { updateOn: 'change' });
  }

  async createRoom(roomData) {
    this.roomCreationService.roomData.name = roomData.title;
    this.roomCreationService.roomData.description = roomData.description;
    this.roomCreationService.roomData.price = roomData.price * 100;
    await this.roomCreationService.createRoom();
  }

  checkLength(event, maxLength) {
    if (maxLength === 50) {
      this.counterTitleValue = maxLength - event.target.value.length;
    } else {
      this.counterDescriptionValue = maxLength - event.target.value.length;
    }
  }

}
