import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-delete-credit-card',
  templateUrl: './delete-credit-card.component.html',
  styleUrls: ['./delete-credit-card.component.scss'],
})
export class DeleteCreditCardComponent implements OnInit {

  constructor(
    public modalService: ModalService,
    private navController: NavController
  ) { }

  ngOnInit() { }

  deleteCard() {
    this.modalService.dismiss();
  }

  cancel() {
    this.modalService.dismiss();
    import('../edit-credit-card/edit-credit-card.component').then(m =>
      this.modalService.presentModal(m.EditCreditCardComponent, 'auto-height-notifications')
    );
  }

}
