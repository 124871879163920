import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AdminGuard } from './guards/admin/admin.guard';
import { AuthGuard } from './guards/auth/auth.guard';
import { DirectAccessGuard } from './guards/direct-access/direct-access.guard';
import { NgModule } from '@angular/core';
import { ResetPasswordGuard } from './guards/reset-password/reset-password.guard';
import { RefundGuard } from './guards/refund/refund.guard';
import { BookingService } from 'src/app/services/booking/booking.service';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/search',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule)
  },
  {
    path: 'room-details/:id',
    loadChildren: () => import('./pages/room-details/room-details.module').then(m => m.RoomDetailsPageModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./pages/booking/booking.module').then(m => m.BookingPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'check-email',
    loadChildren: () => import('./pages/check-email/check-email.module').then(m => m.CheckEmailPageModule),
    canActivate: [ResetPasswordGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canActivate: [ResetPasswordGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment/payment.module').then(m => m.PaymentPageModule),
    canActivate: [AuthGuard, DirectAccessGuard]
  },
  {
    path: 'group-details/:id',
    loadChildren: () => import('./pages/group-details/group-details.module').then(m => m.GroupDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'booking-details',
    loadChildren: () => import('./pages/booking-details/booking-details.module').then(m => m.BookingDetailsPageModule),
    canActivate: [DirectAccessGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'issue',
    loadChildren: () => import('./pages/issue-current-room/issue-current-room.module').then(m => m.IssueCurrentRoomPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'client-reservations-refund',
    loadChildren: () => import('./pages/client-reservations-refund/client-reservations-refund.module').then(m => m.ClientReservationsRefundPageModule),
    canActivate: [AuthGuard, RefundGuard]
  },
  {
    path: 'frequently-asked-questions',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-credit-card',
    loadChildren: () => import('./pages/add-credit-card/add-credit-card.module').then(m => m.AddCreditCardPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsPageModule)
  },
  {
    path: '500',
    loadChildren: () => import('./pages/server-err/server-err.module').then( m => m.ServerErrPageModule)
  },
  {
    path: '404',
    loadChildren: () => import('./pages/not-found/not-found.module').then( m => m.NotFoundPageModule)
  },
  {
    path: '**',
    redirectTo: '/404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
