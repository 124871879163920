import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../components.module';

import { GroupNameAddingModalComponent } from '../group/modals/group-name-adding-modal/group-name-adding-modal.component';
import { GroupNotificationModalComponent } from './modals/group-notification-modal/group-notification-modal.component';
import { GroupMembersAddingModalComponent } from './modals/group-members-adding-modal/group-members-adding-modal.component';
import { GroupCreatedModalComponent } from './modals/group-created-modal/group-created-modal.component';
import { DeleteGroupModalComponent } from './modals/delete-group-modal/delete-group-modal.component';
import { DeleteMemberModalComponent } from './modals/delete-member-modal/delete-member-modal.component';
import { OngoingGroupReservationErrModalComponent } from './modals/ongoing-group-reservation-err-modal/ongoing-group-reservation-err-modal.component';

import { MemberListComponent } from './components/member-list/member-list.component';
import { GroupNameInputComponent } from './components/group-name-input/group-name-input.component';

@NgModule({
  declarations: [
    GroupNotificationModalComponent,
    GroupNameAddingModalComponent,
    GroupMembersAddingModalComponent,
    GroupCreatedModalComponent,
    MemberListComponent,
    GroupNameInputComponent,
    DeleteGroupModalComponent,
    DeleteMemberModalComponent,
    OngoingGroupReservationErrModalComponent
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    GroupNotificationModalComponent,
    GroupNameAddingModalComponent,
    GroupMembersAddingModalComponent,
    GroupCreatedModalComponent,
    MemberListComponent,
    GroupNameInputComponent,
    DeleteGroupModalComponent,
    DeleteMemberModalComponent,
    OngoingGroupReservationErrModalComponent
  ]
})
export class GroupModule { }
