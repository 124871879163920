import { Component, OnInit } from '@angular/core';

import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-download-from-store-popup',
  templateUrl: './download-from-store-popup.component.html',
  styleUrls: ['./download-from-store-popup.component.scss'],
})
export class DownloadFromStorePopupComponent implements OnInit {

  logoSrc = '/assets/icon/ora-logo-icon.svg';

  constructor(
    public modalService: ModalService,
  ) { }

  ngOnInit() { }

  close() {
    localStorage.setItem('smartLocksDownloadFromStoreWasShowed', JSON.stringify(true));
    this.modalService.dismiss();
  }

}
