import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { SignInWithApple, SignInWithAppleResponse, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { environment } from 'src/environments/environment.prod';
import { ModalController, NavController } from '@ionic/angular';
import firebase from 'firebase/app';
import 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { JwtHelperService } from '@auth0/angular-jwt';

import { RequestService } from '../request/request.service';
import { LoaderService } from '../loader/loader.service';
import { ToastService } from '../../services/toast/toast.service';
import { PushNotificationService } from '../push-notification/push-notification.service';
import { EventsService } from 'src/app/services/events/events.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  options: SignInWithAppleOptions = {
    clientId: 'com.smartofficelogin.ora',
    redirectURI: environment.redirectURI,
    scopes: 'email name',
    state: 'initial'
  };

  user = JSON.parse(localStorage.getItem('smartLockUser')) || null;
  userEmail;

  helper = new JwtHelperService();
  isDismissRequired = false;
  isAuthBeforePayment = false;

  constructor(
    private requestService: RequestService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private pushNotificationService: PushNotificationService,
    private modalController: ModalController,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    private events: EventsService,
    private navCtrl: NavController
  ) {
    GoogleAuth.initialize();
  }

  async login(userData, modalService, roomsService) {
    this.pushNotificationService.platform === 'ios' && await this.pushNotificationService.checkPushRegisteringOnAuth(userData);

    await this.loaderService.presentLoader();
    try {
      const resp = await this.requestService.postRequest('auth/signin', userData).toPromise();
      this.saveUser(resp);

      this.loaderService.dismissLoader();
      await modalService.dismiss();
      await roomsService.getAllRooms();
      await this.checkCurrentWebPushPermissions(modalService);
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  async signup(userData, modalService, roomsService) {
    this.pushNotificationService.platform === 'ios' && await this.pushNotificationService.checkPushRegisteringOnAuth(userData);
    await this.loaderService.presentLoader();
    try {
      const resp = await this.requestService.postRequest('auth/signup', userData).toPromise();
      this.saveUser(resp);
      await this.loaderService.dismissLoader();
      await modalService.dismiss();
      await roomsService.getAllRooms();
      await this.checkCurrentWebPushPermissions(modalService);
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  async googleAuth(modalService) {
    const googleUser = await GoogleAuth.signIn();
    this.pushNotificationService.platform === 'ios' && await this.pushNotificationService.checkPushRegisteringOnAuth(googleUser);
    await this.postGoogleToken(googleUser, modalService);
  }

  async postGoogleToken(googleUser, modalService) {
    const googleUserData = {
      token: googleUser.authentication.accessToken,
      deviceToken: this.pushNotificationService.deviceData('deviceToken'),
      platform: this.pushNotificationService.deviceData('platform')
    }
    await this.loaderService.presentLoader();
    try {
      const resp = await this.requestService.postRequest('auth/google', googleUserData).toPromise();
      this.saveUser(resp);
      this.pushNotificationService.platform === 'web' && await this.checkCurrentWebPushPermissions(modalService);
    } catch (e) {
      console.log(e);
    } finally {
      this.loaderService.dismissLoader();
    }
  }

  async checkCurrentWebPushPermissions(modalService) {
    if (this.pushNotificationService.isWebPermissionsModalRequired(this.isUserDataValid())) {
      modalService.dismiss();
      await modalService.openWebPushPermissionsModal();
    } else {
      this.pushNotificationService.platform === 'web' && (this.isDismissRequired = true);
    }
  }

  async appleAuth(modalService) {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    Capacitor.getPlatform() === 'web' ? await this.signInWithAppleWeb(provider, modalService) : await this.signInWithAppleNative(provider);
  }

  async signInWithAppleWeb(provider, modalService) {
    const credential = await this.auth.signInWithPopup(provider);
    const credProfile = credential.additionalUserInfo.profile as any;
    localStorage.setItem('smartLockAppleAuthCode', JSON.stringify({ authorizationCode: credProfile.sub, platform: 'web' }));
    await this.postAppleToken(credential, true, modalService);
  }

  async signInWithAppleNative(provider) {
    const appleUser = await SignInWithApple.authorize(this.options);
    localStorage.setItem('smartLockAppleAuthCode', JSON.stringify({ authorizationCode: appleUser.response.authorizationCode, platform: 'ios' }));
    const credential = provider.credential({
      idToken: appleUser.response.identityToken
    });
    const userCredential = await this.auth.signInWithCredential(credential);
    await this.pushNotificationService.checkPushRegisteringOnAuth(appleUser);
    await this.postAppleToken(appleUser);
  }

  async postAppleToken(response, isWeb = false, modalService?) {
    const appleUser = {
      identityToken: isWeb ? response.credential.idToken : response.response.identityToken,
      givenName: !isWeb ? response.response.givenName : (response.user.displayName && response.user.displayName.split(' ')[0]),
      familyName: !isWeb ? response.response.familyName : (response.user.displayName && response.user.displayName.split(' ')[1]),
      platform: this.pushNotificationService.deviceData('platform'),
      deviceToken: this.pushNotificationService.deviceData('deviceToken')
    }

    try {
      await this.loaderService.presentLoader();
      const resp = await this.requestService.postRequest('auth/apple', appleUser).toPromise();
      this.loaderService.dismissLoader();
      this.saveUser(resp);
      modalService && await this.checkCurrentWebPushPermissions(modalService);
    } catch (err) {
      console.log(err);
      this.toastService.presentToast(err.message, 'error');
      this.loaderService.dismissLoader();
    }
  }

  async getUser() {
    if (!this.user) { return; }

    await this.loaderService.presentLoader();
    try {
      const actualUser = await this.requestService.getAuthRequest(`users/${this.user.id}`).toPromise();
      Object.keys(actualUser).forEach(key => {
        this.user[key] = actualUser[key];
      });
      localStorage.setItem('smartLockUser', JSON.stringify(this.user));
      this.parseJWT(this.user.accessToken);
    } catch (e) {
      console.log(e);
      this.loaderService.dismissLoader();
    } finally {
      this.loaderService.dismissLoader();
    }
  }

  saveUser(resp) {
    const userInfo: any = resp.user;
    if (userInfo) {
      this.user = userInfo;
      this.user.accessToken = resp.accessToken;
      this.pushNotificationService.saveNotification(resp);
      localStorage.setItem('smartLockUser', JSON.stringify(userInfo));
      this.parseJWT(this.user.accessToken);
    }
  }

  async updateUser(userData, fromModal = false, isUpdateSms = false) {
    await this.loaderService.presentLoader();
    if (!isUpdateSms) { this.setUserPhone(userData); }

    try {
      const response = await this.requestService.patchAuthRequest(`users/${this.user.id}`, userData).toPromise();
      this.saveUserLocally(response);
      this.loaderService.dismissLoader();
      fromModal && this.modalController.dismiss();
    } catch (err) {
      this.toastService.presentToast(err.message, 'error');
      this.loaderService.dismissLoader();
    }
  }

  setUserPhone(userData) {
    this.user.state === 'ukr'
      ? !userData.phone.includes('+38') && (userData.phone = `+38${userData.phone}`)
      : !userData.phone.includes('+1') && (userData.phone = `+1${userData.phone}`);
  }

  saveUserLocally(userInfo) {
    const user = JSON.parse(localStorage.getItem('smartLockUser'));
    Object.keys(userInfo).forEach(key => {
      user[key] = userInfo[key];
    });
    this.user = user;
    localStorage.setItem('smartLockUser', JSON.stringify(this.user));
  }

  parseJWT(accessToken) {
    const decodedToken = this.helper.decodeToken(accessToken);
    this.requestService.isAdmin = decodedToken.type === 'ADMIN';
  }

  async isJWTExpired(accessToken) {
    if (!accessToken || !this.helper.isTokenExpired(accessToken)) { return; }
    await this.logOut(true);
  }

  async logOut(isTokenExpired = false, fromDeleteAcc = false) {
    if (!isTokenExpired) {
      !fromDeleteAcc && await this.pushNotificationService.deleteUserDevice();
      this.pushNotificationService.platform === 'ios' && await this.pushNotificationService.removePushListeners();
    }
    this.user = null;
    this.requestService.isAdmin = false;
    localStorage.removeItem('smartLockBooking');
    localStorage.removeItem('smartLockUser');
    localStorage.removeItem('smartLockAppleAuthCode');
    localStorage.removeItem('deviceUnlockModalWasShowed');
    if (this.pushNotificationService.platform === 'web') {
      localStorage.removeItem('smartLockPushData');
      this.pushNotificationService.notification = null;
      this.pushNotificationService.deviceToken = null;
    }
  }

  isUserDataValid() {
    return this.user.phoneVerified && this.user.firstName && this.user.lastName;
  }

  islogOutRequired() {
    if (!this.user) { return; }
    if (!this.user.buildingAdmins || !this.user.ownerGroupIds) { this.logOut(); }
  }

  async deleteAccount(modalService) {
    const authCode = JSON.parse(localStorage.getItem('smartLockAppleAuthCode'));
    await this.loaderService.presentLoader();

    const body = authCode ? {
      authorizationCode: authCode.authorizationCode,
      platform: authCode.platform
    } : {};

    try {
      const response = await this.requestService.deleteAuthRequest(`users/${this.user.id}`, body).toPromise();
      if (response) {
        this.clearDataOnDelete(modalService);
        await this.logOut(false, true);
        localStorage.removeItem('smartLockWebPushDenied');
        this.navCtrl.navigateRoot('search');
        const currentFbUser = this.auth.currentUser;
        authCode && (currentFbUser && (await currentFbUser).delete());
      }
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      
      if (!err) { return; }
      (err.message && err.message.includes('ongoing')) && import('../../components/refund-before-delete-acc-alert/refund-before-delete-acc-alert.component').then(m => {
        modalService.presentModal(m.RefundBeforeDeleteAccAlertComponent, 'auto-height', { isBookingInProcess: true });
      });
    }
  }

  isAccountWillBeDeleted() {
    if (!this.user) { return false; }
    if (!this.user.needDelete) { return false; }

    this.toastService.presentToast(`Your profile and account details will be deleted on ${this.user.willDeletedAt} at 00:00. You cannot make any future reservations.`, 'error');
    return this.user.needDelete;
  }

  clearDataOnDelete(modalService) {
    this.loaderService.dismissLoader();
    modalService.dismiss();
    modalService.unlockBeenShowed = false;
    this.events.bookingDetectionInterval$ && this.events.bookingDetectionInterval$.unsubscribe();
  }
}
