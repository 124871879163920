import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';
import { EventsService } from '../../services/events/events.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    public toastController: ToastController,
    private events: EventsService
  ) { }

  async presentToast(message: string, status?: string, duration = 5000) {
    const isBookingDetailsPromoError = message && message.includes('promo code after signing');

    const toast = await this.toastController.create({
      message: message ? message : 'An error has occurred!',
      duration: duration,
      position: 'top',
      cssClass: isBookingDetailsPromoError && 'promo-toast',
      buttons: [
        {
          side: 'start',
          icon: status === 'error' ? '/assets/icon/unsaccessful-toast-icon.svg' : '/assets/icon/saccessful-toast-icon.svg',
        },
        {
          side: 'end',
          icon: isBookingDetailsPromoError && '/assets/icon/back_button_white.svg',
          handler: () => { this.events.onToastClick(); }
        }
      ]
    });

    isBookingDetailsPromoError ? this.styleToastOnPromocodesError(toast) : this.styleToastOnPromocodesError(toast, false);

    toast.onDidDismiss().then(() => {
      // console.log('Dismissed toast');
    });

    toast.present();
  }

  async dismissToast() {
    await this.toastController.dismiss();
  }

  styleToastOnPromocodesError(toast, isIconRequired = true) {
    const shadow = toast.shadowRoot;
    const childNodes = Array.from(shadow.childNodes)
    const buttonNode = childNodes[childNodes.length - 1] as any;

    const toastText = buttonNode.querySelector('.toast-content');
    const buttons = buttonNode.getElementsByClassName('toast-button');

    if (isIconRequired) {
      toastText.style.paddingRight = 0;
      buttons[1].setAttribute('style', 'font-size: 12px !important; transform: rotate(180deg); cursor: pointer !important;')
    } else {
      buttons[1].style.display = 'none';
    }
  }
}
