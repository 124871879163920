import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  day;
  monthName;
  currentHours;
  fullDate;
  bookedHours = [];
  bookedHoursMulti = [];
  datesRange = [];
  datePipe = new DatePipe('en-US');

  timeZoneList = [
    { name: 'HKST', timeZoneNum: -10 },
    { name: 'AKST', timeZoneNum: -9 },
    { name: 'PST', timeZoneNum: -8 },
    { name: 'MST', timeZoneNum: -7 },
    { name: 'CST', timeZoneNum: -6 },
    { name: 'EST', timeZoneNum: -5 },
    { name: 'AST', timeZoneNum: -4 },
    { name: 'CET', timeZoneNum: 1 },
    { name: 'EET', timeZoneNum: 2 }
  ];

  constructor() { }

  setTimeZoneName(timeZoneNum) {
    const timeZoneItem = this.timeZoneList.find(zone => zone.timeZoneNum === timeZoneNum);
    return timeZoneItem ? timeZoneItem.name : timeZoneNum;
  }

  formatDateForView(dateStart, dateEnd, fullMonth = false): string {
    const correctedEndDate = this.correctMidnightTime(dateEnd);
    const month = fullMonth ? 'MMMM' : 'MMM';
    const isMonthEqual = this.datePipe.transform(dateStart, `MMM`, 'UTC') === this.datePipe.transform(correctedEndDate, `MMM`, 'UTC');
    const isDaysEqual = this.datePipe.transform(dateStart, `d`, 'UTC') === this.datePipe.transform(correctedEndDate, `d`, 'UTC');
    const isYearEqual = this.datePipe.transform(dateStart, `YYYY`, 'UTC') === this.datePipe.transform(correctedEndDate, `YYYY`, 'UTC');
    if (!isYearEqual) {
      return `${this.datePipe.transform(dateStart, `${month} d, YYYY`, 'UTC')} - ${this.datePipe.transform(correctedEndDate, `${month} d, YYYY`, 'UTC')}`;
    } else if (!isMonthEqual) {
      return `${this.datePipe.transform(dateStart, `${month} d`, 'UTC')} - ${this.datePipe.transform(correctedEndDate, `${month} d, YYYY`, 'UTC')}`;
    } else if (!isDaysEqual) {
      return `${this.datePipe.transform(dateStart, `${month} d`, 'UTC')} - ${this.datePipe.transform(correctedEndDate, 'd, YYYY', 'UTC')}`;
    } else {
      return `${this.datePipe.transform(dateStart, `${month} d, YYYY`, 'UTC')}`;
    }
  }

  formatTimeForView(dateStart, dateEnd): string {
    return `${this.datePipe.transform(dateStart, 'h a')} - ${this.datePipe.transform(dateEnd, 'h a')}`;
  }

  correctMidnightTime(date, isISOStringRequired = true) {
    const dateArr = date.split('T');
    if (dateArr[1] === '00:00:00' || dateArr[1].includes('23:59:59')) {
      const result = new Date(dateArr[0]);
      if (!dateArr[1].includes('23:59:59')) {
        result.setDate(result.getDate() - 1);
      }
      if (isISOStringRequired) { return result.toISOString(); }
      return this.datePipe.transform(result, `MMMM d, YYYY`, 'UTC');
    } else {
      return date;
    }
  }

  convertToEndOfDat(endDay) {
    let date = new Date(endDay);
    date.setDate(date.getDate() + 1)

    let convertedDate = new Date(date.getFullYear()
      , date.getMonth()
      , date.getDate());

    convertedDate.setUTCHours(23, 59, 59);
    return convertedDate.toISOString();
  }

  removeTimeZone(dateString) {
    return dateString.slice(0, -5);
  }

  formatDate(date, time) {
    const hours = this.formatIn24ForServer(time);
    let isoDate = date.toISOString();
    const lastFourItems = isoDate.substr(isoDate.length - 4);
    (hours === '00') && (date = new Date(new Date(date).setDate(new Date(date).getDate() + 1)));
    let dayNumber = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let monthNumber = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const convertedDate = `${date.getFullYear()}-${monthNumber}-${dayNumber}`;
    return `${convertedDate}T${hours}:00:00.${lastFourItems}`;
  }

  formatIn24(time12) {
    let [hours, modifier] = time12.split(' ');
    hours = parseInt(hours, 10);
    (hours === 12 && modifier === 'AM') && (hours = 24);
    (hours === 12 && modifier === 'PM') && (hours = 0);
    modifier === 'PM' && (hours += 12);
    return hours;
  }

  formatIn24ForServer(time12) {
    let [hours, modifier] = time12.split(' ');
    hours = parseInt(hours, 10);
    hours === 12 && (hours = 0);
    hours = modifier === 'PM' ? hours + 12 : (hours < 10 ? `0${hours}` : hours);
    return hours;
  }

  formatTime12Hours(time) {
    return time.toLocaleString('en-US', { hour: 'numeric', hour12: true, timeZone: 'UTC' });
  }

  addBookedHoursIn24Format(isoDateStart, isoDateEnd) {
    const dateStart = new Date(this.removeTimeZone(isoDateStart));
    const dateEnd = new Date(this.removeTimeZone(isoDateEnd));

    this.isMultiBooking(dateStart, dateEnd, isoDateEnd)
      ? this.setBookedHours(this.bookedHoursMulti, dateStart, dateEnd)
      : this.setBookedHours(this.bookedHours, dateStart, dateEnd, isoDateStart, isoDateEnd);

  }

  setBookedHours(dataToUpdate, dateStart, dateEnd, isoDateStart = null, isoDateEnd = null) {
    dataToUpdate.push({
      hours: { from: dateStart.getHours(), to: dateEnd.getHours() === 0 ? 24 : dateEnd.getHours() },
      dateStart: this.transformFullDate(dateStart),
      dateEnd: this.transformFullDate(dateEnd),
      selectedTimeCategories: this.timePickerCategory(isoDateStart, isoDateEnd)
    });
  }

  timePickerCategory(dateStart, dateEnd) {
    return `${this.formatTime12Hours(new Date(dateStart))} – ${this.formatTime12Hours(new Date(dateEnd))}`;
  }

  isMultiBooking(dateStart, dateEnd, isoDateEnd) {
    const dateStartStr = this.transformFullDate(dateStart);
    const dateEndStr = this.transformFullDate(dateEnd);

    return dateStartStr !== dateEndStr && dateStartStr !== this.transformFullDate(new Date(this.correctMidnightTime(this.removeTimeZone(isoDateEnd))))
  }

  getDatesRange(startDate, endDate, isFromReservations = false, includingLastDate = true) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    while (currentDate <= endDate) {
      dates.push({ date: !isFromReservations ? this.transformFullDate(currentDate) : currentDate });
      currentDate = addDays.call(currentDate, 1);
    }
    return includingLastDate ? dates : dates.slice(0, -1);
  }

  transformFullDate(date) {
    return `${new Date(date).getDate()}/${new Date(date).getMonth()}/${new Date(date).getFullYear()}`;
  }

  convertDateForConstructor(date) {
    const dateArr = date.split('/').reverse().map(str => parseInt(str));
    return new Date(dateArr[0], dateArr[1], dateArr[2]);
  }

  formattedPromocodeDate(date: string): string {
    const formattedDate = this.correctMidnightTime(date);
    const month = new Date(formattedDate).toLocaleDateString('en-US', { month: 'short' });
    return `${month} ${new Date(formattedDate).getDate()}, ${new Date(formattedDate).getFullYear()}`;
  }

  dateForStatsView(date) {
    let selectedMonth = new Date(date).getMonth() + 1 as any;
    let selectedDate = new Date(date).getDate() as any;

    selectedMonth = selectedMonth < 10 ? `0${selectedMonth}` : selectedMonth;
    selectedDate = selectedDate < 10 ? `0${selectedDate}` : selectedDate;

    return `${selectedMonth} / ${selectedDate} / ${new Date(date).getFullYear()}`;
  }

  formatInitialDateFullCalendar() {
    const firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

    let selectedMonthStart = new Date(firstDay).getMonth() + 1 as any;
    let selectedMonthEnd = new Date(lastDay).getMonth() + 1 as any;

    let selectedDateStart = new Date(firstDay).getDate() as any;
    let selectedDateEnd = new Date(lastDay).getDate() + 1 as any;

    return {
      start: new Date(`${firstDay.getFullYear()}-${this.checkSelectedMonthDate(selectedMonthStart)}-${this.checkSelectedMonthDate(selectedDateStart)}`).toISOString(),
      end:  new Date(`${lastDay.getFullYear()}-${this.checkSelectedMonthDate(selectedMonthEnd)}-${this.checkSelectedMonthDate(selectedDateEnd)}`.replace(/-/g, "/")).toISOString()
    };
  }

  checkSelectedMonthDate(selectedItem) {
    return selectedItem < 10 ? `0${selectedItem}` : selectedItem;
  }
}
