import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  @Input() modal;
  @Input() showBtnText;
  @Input() parentHeader;
  @Input() blackBtn;
  @Input() noLeftRightPadding;

  constructor(
    public modalController: ModalController,
    public navController: NavController
  ) { }

  ngOnInit() { }

  setCorrectClass() {
    if (this.blackBtn) {
      return 'black_btn';
    } else if (!this.modal && !this.showBtnText) {
      return 'no_padding';
    } else if (!this.showBtnText) {
      return 'no_padding';
    } else if (!this.modal) {
      return this.noLeftRightPadding ? 'no_left_right_padding' : 'no_border_radius';
    } else if (this.parentHeader) {
      return 'no_padding';
    }
  }

}
