import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { NavParams } from '@ionic/angular';
import { ModalService } from 'src/app/services/modal/modal.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { EventsService } from 'src/app/services/events/events.service';
import { BuildingService } from '../../services/building/building.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-add-building-managers-mobile',
  templateUrl: './add-building-managers-mobile.component.html',
  styleUrls: ['./add-building-managers-mobile.component.scss'],
})
export class AddBuildingManagersMobileComponent implements OnInit {

  @ViewChild('searchInput') searchInput;
  managersToShow;
  managersToDelete = [];
  building = this.params.get('options').building;
  isReturnModalDetails = this.params.get('options').isReturnModalDetails;

  constructor(
    private requestService: RequestService,
    private params: NavParams,
    private toastService: ToastService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private events: EventsService,
    public buildingService: BuildingService
  ) { }

  ngOnInit() {
    this.managersToShow = this.building.buildingAdmins;
  }

  back() {
    this.modalService.dismiss();
    this.createPreviousBuildingAdmins();
    this.buildingService.clearManagersState();

    if (this.isReturnModalDetails) {
      import('../room-building-details-admin/room-building-details-admin.component').then(m =>
        this.modalService.presentModal(m.RoomBuildingDetailsAdminComponent, 'auto-height', {
          building: this.building, isRoomDetails: false, buidingPaginationExists: true
        })
      );
    } else {
      import('../building-admins-modal/building-admins-modal.component').then(m =>
        this.modalService.presentModal(m.BuildingAdminsModalComponent, 'auto-height', { building: this.building, buidingPaginationExists: true })
      );
    }
  }

  createPreviousBuildingAdmins() {
    this.managersToDelete.length && (this.building.buildingAdmins = this.building.buildingAdmins.concat(this.managersToDelete));
    this.building.buildingAdmins = this.building.buildingAdmins.filter((admin, index, self) => {
      return index === self.findIndex((t) => {
        return admin.user && t.user && (admin.user.id === t.user.id);
      });
    });
  }

  deleteManagerLocally(manager, managersToShow) {
    this.building.buildingAdmins.some(admin => admin.user && admin.userId === manager.userId) && this.managersToDelete.push(manager);

    if (this.buildingService.managersToAdd) {
      this.removeUsersOnDelete(manager, managersToShow);
      this.removeUsersOnDelete(manager, this.buildingService.managersToAdd);
    } else {
      this.managersToShow = managersToShow.filter(admin => admin.userId !== manager.userId);
    }
    this.clearInputValueCloseDropdown();
  }

  removeUsersOnDelete(managerToRemove, managers) {
    const index = managers.indexOf(managerToRemove);
    index > -1 && managers.splice(index, 1);
  }

  clearInputValueCloseDropdown() {
    this.searchInput.value = '';
    this.buildingService.showManagersList = false;
  }

  addManagerLocally(manager, managersToShow) {
    managersToShow.push(manager);
    this.buildingService.managersToAdd = managersToShow.filter((manager) => !manager.user);
    this.searchInput.setFocus();
    this.clearInputValueCloseDropdown();
  }

  async onSubmit() {
    const buildingAdminRequests = this.buildingAdminRequests();
    await this.loaderService.presentLoader();

    try {
      if (!buildingAdminRequests) { return; }
      const response = await forkJoin(buildingAdminRequests).toPromise();
      this.loaderService.dismissLoader();
      this.modalService.dismiss();
      this.events.onCloseModal(false);
      this.buildingService.clearManagersState();
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  buildingAdminRequests() {
    const bodyToAdd = this.buildingService.managersToAdd && this.buildingService.managersToAdd.map(manager => {
      return { userId: manager.id, buildingId: this.building.id }
    });

    const bodyToDelete = this.managersToDelete && this.managersToDelete.map(manager => {
      return { userId: manager.user.id, buildingId: this.building.id }
    });

    if (bodyToAdd && bodyToDelete) {
      return [
        this.requestService.postAuthRequest('building-admins', bodyToAdd),
        this.requestService.deleteAuthRequest('building-admins', bodyToDelete)
      ];
    } else if (bodyToAdd) {
      return [this.requestService.postAuthRequest('building-admins', bodyToAdd)];
    } else if (bodyToDelete) {
      return [this.requestService.deleteAuthRequest('building-admins', bodyToDelete)];
    } else {
      return null;
    }
  }


}
