import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ModalController, GestureController, NavParams, NavController } from '@ionic/angular';

import { BookingService } from '../../services/booking/booking.service';
import { DateService } from 'src/app/services/date/date.service';
import { LockService } from 'src/app/services/lock/lock.service';

@Component({
  selector: 'app-unlock-office-modal',
  templateUrl: './unlock-office-modal.component.html',
  styleUrls: ['./unlock-office-modal.component.scss'],
})
export class UnlockOfficeModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('btnWrapper') btnWrapper: ElementRef;
  @ViewChild('btnBackground') btnBackground: ElementRef;
  @ViewChild('gestureWrapp') gestureWrapp: ElementRef;

  public bookingDate: string;
  public bookingTime: string;
  booking;
  isUnlocked;
  fromUnlockPush = false;
  showRoomsList = false;
  isMultipleBookings = false;
  chosenRoom;
  options;

  constructor(
    public bookingService: BookingService,
    public modalController: ModalController,
    private navParams: NavParams,
    private dateService: DateService,
    public lockService: LockService,
    private gestureCtrl: GestureController,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.options = this.navParams.get('options');
    if (!this.options.bookings) { return; }
    this.checkForMoreThatOneBookingSameTime(this.options.bookings, this.options.fromUnlockPush);

    this.setBookingDatesInHeader();
  }

  checkForMoreThatOneBookingSameTime(bookings, fromUnlockPush) {
    if (bookings.length === 1) {
      this.booking = bookings[0];
      this.chosenRoom = bookings[0].room;

      this.isUnlocked = this.chosenRoom.lockStatus === 'unlocked';
      this.fromUnlockPush = fromUnlockPush;
    } else {
      this.booking = bookings[0];
      this.fromUnlockPush = fromUnlockPush;
      this.isMultipleBookings = true;
    }
  }

  ngAfterViewInit() {
    if (this.booking.isExpired) { return; }
    this.isUnlocked && setTimeout(() => { this.updateGestureOnOpen(); }, 100);
    this.createSwipeGesture();
  }

  createSwipeGesture() {
    const swipeGesture = this.gestureCtrl.create({
      el: this.btnWrapper.nativeElement,
      threshold: 0,
      direction: 'x',
      gestureName: 'swipe-delete',
      onMove: this.onGestureMove.bind(this),
      onEnd: this.onGestureEnd.bind(this),
    }, true);

    swipeGesture.enable(true);
  }

  setBookingDatesInHeader() {
    const startDate = this.fromUnlockPush ? this.dateService.removeTimeZone(this.booking.bookingStart) : this.booking.bookingStart;
    const endDate = this.fromUnlockPush ? this.dateService.removeTimeZone(this.booking.bookingEnd) : this.booking.bookingEnd;
    this.bookingDate = this.dateService.formatDateForView(startDate, endDate);
    this.bookingTime = this.dateService.formatTimeForView(startDate, endDate);
  }

  onGestureMove(ev) {
    const currentX = ev.deltaX;
    if (currentX >= this.gestureWrapp.nativeElement.offsetWidth - 68 || currentX < 0) { return; }

    this.btnWrapper.nativeElement.style.transform = `translateX(${currentX}px)`;
    this.btnWrapper.nativeElement.classList.add('on-move');
    this.btnBackground.nativeElement.style.width = `${currentX + 56}px`;
  }

  async onGestureEnd(ev) {
    if (ev.deltaX >= this.gestureWrapp.nativeElement.offsetWidth / 2) {
      this.btnWrapper.nativeElement.style.transform = `translateX(${this.gestureWrapp.nativeElement.offsetWidth - 68}px)`;
      this.btnWrapper.nativeElement.classList.remove('on-move');
      this.resetBtnBackgroundElement();

      await this.lockService.unlockRoom(this.chosenRoom);
      this.isUnlocked = this.chosenRoom.lockStatus === 'unlocked';
      this.isUnlocked ? this.onLockOpen() : this.resetLockBtnToStartingPosition(true);
    } else {
      this.resetLockBtnToStartingPosition();
      this.resetBtnBackgroundElement();
    }
  }

  onLockOpen(unlockedTime = 30000) {
    this.btnWrapper.nativeElement.classList.add('on-open');
    setTimeout(() => { this.resetLockBtnToStartingPosition(); }, unlockedTime);
  }

  resetLockBtnToStartingPosition(isError = false) {
    this.isUnlocked = false;

    if (isError) {
      this.btnWrapper.nativeElement.classList.add('on-error');
      setTimeout(() => { this.addStylesOnBtnReset(); }, 500);
    } else {
      this.addStylesOnBtnReset();
    }
  }

  addStylesOnBtnReset() {
    this.btnWrapper.nativeElement.style.transform = `translateX(0px)`;
    this.btnWrapper.nativeElement.classList.remove('on-move', 'on-open', 'on-error');
  }

  resetBtnBackgroundElement() {
    this.btnBackground.nativeElement.style.width = 0;
    this.btnBackground.nativeElement.style.left = 0;
  }

  close() {
    this.modalController.dismiss();
  }

  updateGestureOnOpen() {
    const offsetWidth = this.gestureWrapp.nativeElement.offsetWidth;
    this.btnWrapper.nativeElement.style.transform = `translateX(${offsetWidth - 68}px)`;
    this.onLockOpen(this.chosenRoom.unlockedTime * 1000);
  }

  toggleIcon() {
    this.showRoomsList = !this.showRoomsList;
  }

  getChosenRoom(room) {
    this.chosenRoom = room;
    this.isUnlocked = this.chosenRoom.lockStatus === 'unlocked';
    this.isUnlocked ? this.updateGestureOnOpen() : this.resetLockBtnToStartingPosition();
    this.showRoomsList = !this.showRoomsList;
  }

  openRoomsPage() {
    this.modalController.dismiss();
    this.navCtrl.navigateForward('rooms');
  }

  ngOnDestroy() {
    this.isUnlocked = false;
    this.fromUnlockPush = false;
  }

}
