import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import { AuthService } from '../app/services/auth/auth.service';
import { ModalService } from '../app/services/modal/modal.service';
import { PushNotificationService } from 'src/app/services/push-notification/push-notification.service';
import { EventsService } from './services/events/events.service';
import { BookingService } from '../app/services/booking/booking.service';
import { UpdateService } from '../app/services/update/update.service';

import { Platform } from '@ionic/angular';
import { RateModalComponent } from './components/rate-modal/rate-modal.component';
import { ExtendBookingComponent } from './components/extend-booking/extend-booking.component';
import { UnlockOfficeModalComponent } from './components/unlock-office-modal/unlock-office-modal.component';
import { timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private router: Router,
    private zone: NgZone,
    private authService: AuthService,
    private modalService: ModalService,
    public platform: Platform,
    public pushNotificationService: PushNotificationService,
    private events: EventsService,
    private bookingService: BookingService,
    private updateService: UpdateService
  ) {
    this.platform.ready().then(() => this.presentDowloadFromAppStorePopup());
    this.clearBookingDuePreviosLogic();
    this.updateService.checkForUpdate();
    this.checkAuthTokenExpiration();
    this.authService.islogOutRequired();
    this.initializeApp();

    if (this.pushNotificationService.isPushExists() && this.authService.user) {
      this.pushNotificationService.handleWebPush();
      this.pushNotificationService.addPushListeners();
    }

    this.events.roomIdSubject$.subscribe((data) => {
      this.modalService.isUnlockModalOpenedFromPush = true;

      for (const key of Object.keys(this.bookingService.bookingDataExtend)) {
        this.bookingService.bookingDataExtend[key] = data[key];
      }

      this.presentModalAfterPush(data.modalToShow, data.roomId, data.bookingId, data.bookingStart, data.bookingEnd, data.bookingsToUnlockModal);
    });
    if (!this.authService.user || JSON.parse(localStorage.getItem('deviceUnlockModalWasShowed'))) return;
    timer(2500).subscribe(async () => await this.bookingDetection())
  }

  async bookingDetection() {
    if (this.modalService.isUnlockModalOpenedFromPush) { return; }

    await this.detectCurrentBooking();
    await this.nextBookingWithinInterval();
  }

  async nextBookingWithinInterval() {
    const isBookingStarted = await this.bookingService.checkBookingsInterval();
    isBookingStarted && await this.detectCurrentBooking();
  }

  async detectCurrentBooking() {
    !this.modalService.unlockBeenShowed && (this.bookingService.processedBookings = await this.bookingService.getBookings());
    if (this.bookingService.processedBookings?.length) {
      this.modalService.presentUnlockOnBookingProcess(this.bookingService.processedBookings);
      Capacitor.getPlatform() === 'ios' && localStorage.setItem('deviceUnlockModalWasShowed', JSON.stringify(true));
    }
  }

  async initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const url = event.url.split('https://booking.byoraprivateoffices.com/').pop();
        url && this.checkUserAuth(url);
      });
    });

    App.addListener('appStateChange', async (state: AppState) => {
      if (!this.authService.user || this.pushNotificationService.platform === 'web') { return; }

      if (state.isActive) {
        if (this.pushNotificationService.isPushExists()) {
          this.pushNotificationService.addPushListeners();
          return;
        } else {
          await this.pushNotificationService.onNativeSettingsChange(this.authService.user);
          this.events.pushEnabledSubject$.next(this.pushNotificationService.isPushEnabled);
        }
      } else {
        this.pushNotificationService.removePushListeners();
      }
    });

    this.modalService.openRequiredDataModal();
  }

  checkUserAuth(url) {
    if (url === '/rooms' && !this.authService.user) {
      import('./components/login/login.component').then(m =>
        this.modalService.presentModal(m.LoginComponent, 'fixed-height', 'rooms')
      );
    } else {
      this.router.navigateByUrl(url);
    }
  }

  presentModalAfterPush(modalToShow, roomId, bookingId, bookingStart, bookingEnd, bookingsToUnlockModal) {
    if (!modalToShow) { return; }
    
    if (modalToShow === 'rate') {
      setTimeout(() => {
        this.modalService.presentModal(RateModalComponent, 'auto-height notifications', { roomId, bookingId });
      }, 2000);
    } else if (modalToShow === 'extend') {
      setTimeout(() => {
        this.modalService.presentModal(ExtendBookingComponent, 'auto-height notifications', { roomId, bookingStart, bookingEnd });
      }, 2000);
    } else if (modalToShow === 'unlock') {
      setTimeout(() => {
        this.modalService.presentModal(UnlockOfficeModalComponent, 'auto-height', { bookings: bookingsToUnlockModal, fromUnlockPush: true });
      }, 1000);
    }
  }

  async checkAuthTokenExpiration() {
    if (!this.authService.user) { return; }
    await this.authService.isJWTExpired(this.authService.user.accessToken);
  }

  clearBookingDuePreviosLogic() {
    JSON.parse(localStorage.getItem('smartLockBooking')) && localStorage.removeItem('smartLockBooking');
  }

  presentDowloadFromAppStorePopup() {
    !JSON.parse(localStorage.getItem('smartLocksDownloadFromStoreWasShowed')) && this.modalService.presentDowloadFromAppStorePopup();
  }
}
