import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavParams } from '@ionic/angular';
import { forkJoin } from 'rxjs';

import { ModalService } from 'src/app/services/modal/modal.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { DateService } from 'src/app/services/date/date.service';
import { GoogleMapsService } from '../../services/google-maps/google-maps.service';
import { EventsService } from '../../../services/events/events.service';
import { BuildingService } from '../../services/building/building.service';
import { PaginationService } from 'src/app/services/pagination/pagination.service';

@Component({
  selector: 'app-edit-building-modal',
  templateUrl: './edit-building-modal.component.html',
  styleUrls: ['./edit-building-modal.component.scss'],
})
export class EditBuildingModalComponent implements OnInit {

  @Input() building;
  @Output() close = new EventEmitter();
  @ViewChild('searchInput') searchInput;
  @Output() openDelete = new EventEmitter();

  showTimeZonesList = false;
  chosenTimeZone;
  chosenTimeZoneNum;

  managersToShow;
  managersToDelete = [];

  addressResponse;
  errorText;

  editBuildingForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    address: new UntypedFormControl(''),
    zipcode: new UntypedFormControl(''),
    published: new UntypedFormControl(''),
  });

  constructor(
    public modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private requestService: RequestService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    public dateService: DateService,
    public maps: GoogleMapsService,
    private params: NavParams,
    private events: EventsService,
    public buildingService: BuildingService,
    private pagination: PaginationService
  ) { }

  ngOnInit() {
    if (this.modalService.isMobileView) {
      const options = this.params.get('options');
      this.building = options.building;
    }
    this.managersToShow = JSON.parse(JSON.stringify(this.building.buildingAdmins));
    this.chosenTimeZone = this.dateService.setTimeZoneName(this.building.timeZone);
    this.initForm();
    this.setInputValues();

    this.pagination.buildingsPaginationState = {
      currentPage: this.pagination.currentPagination.currentPage,
      totalPages: this.pagination.currentPagination.totalPages
    };
  }

  initForm() {
    this.editBuildingForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      address: ['', Validators.compose([Validators.required])],
      zipcode: ['', Validators.compose([Validators.required])],
      published: [this.building.published]
    }, { updateOn: 'change' });
  }

  setInputValues() {
    Object.keys(this.editBuildingForm.controls).forEach(key => {
      for (let propt in this.building) {
        (key === propt) && this.editBuildingForm.controls[key].setValue(this.building[propt]);
        (key === 'address') && this.editBuildingForm.controls[key].setValue(
          `${this.building.streetNumber}, ${this.building.streetName}, ${this.building.city.name}`
        );
      }
    });
  }

  async onSubmit(data) {
    this.maps.buildingData.name = data.name;
    this.maps.buildingData.timeZone = this.chosenTimeZoneNum;
    this.maps.buildingData.published = data.published;

    const buildingUpdateRequest = this.requestService.patchAuthRequest(`buildings/${this.building.id}`, this.maps.buildingData);
    const buildingAdminRequests = this.buildingAdminRequests();

    await this.loaderService.presentLoader();

    try {
      if (buildingAdminRequests) {
        buildingAdminRequests.push(buildingUpdateRequest);
        const response = await forkJoin(buildingAdminRequests).toPromise();
        this.closeModalsDismissLoader(true, false, true);
      } else {
        const response = await buildingUpdateRequest.toPromise();
        this.closeModalsDismissLoader(true, false, true);
      }
    } catch (err) {
      this.closeModalsDismissLoader(true, false, true);
      this.checkDeactivationError(err);
    }
  }

  checkDeactivationError(err) {
    if (err.message && err.message.includes('must cancel all reservations')) {
      if (this.modalService.isMobileView) {
        this.modalService.dismiss();
        import('../../components/alert-modal/alert-modal.component').then(m =>
          this.modalService.presentModal(m.AlertModalComponent, 'auto-height', { fromOffices: false })
        );
      } else {
        this.errorText = err.message;
        this.modalService.openDesktopModal('AlertModal');
      }
    } else {
      this.toastService.presentToast(err.message, 'error');
    }
  }

  closeModalsDismissLoader(isLoaderDismissRequired = false, backToDetails = false, closeEmmiterRequired = false) {
    isLoaderDismissRequired && this.loaderService.dismissLoader();
    this.buildingService.clearManagersState();
    this.pagination.currentPagination.currentPage = this.pagination.buildingsPaginationState.currentPage;
    this.pagination.currentPagination.totalPages = this.pagination.buildingsPaginationState.totalPages;
    this.pagination.currentPagination.isBuildingsSelected = true;

    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      if (backToDetails) { this.openDetails(this.building, false); }
      isLoaderDismissRequired && this.events.onCloseModal(false);
    } else {
      this.modalService.closeDesktopModal('EditBuildingModal');
      this.modalService.closeDesktopModal('AddBuildingManagersModal');
      this.modalService.closeDesktopModal('AddBuildingPhotoModal');
      closeEmmiterRequired && this.close.emit(true);
    }
  }

  buildingAdminRequests() {
    const bodyToAdd = this.buildingService.managersToAdd && this.buildingService.managersToAdd.map(manager => {
      return { userId: manager.id, buildingId: this.building.id }
    });

    const bodyToDelete = this.managersToDelete && this.managersToDelete.map(manager => {
      return { userId: manager.user.id, buildingId: this.building.id }
    });

    if (bodyToAdd && bodyToDelete) {
      return [
        this.requestService.postAuthRequest('building-admins', bodyToAdd),
        this.requestService.deleteAuthRequest('building-admins', bodyToDelete)
      ];
    } else if (bodyToAdd) {
      return [this.requestService.postAuthRequest('building-admins', bodyToAdd)];
    } else if (bodyToDelete) {
      return [this.requestService.deleteAuthRequest('building-admins', bodyToDelete)];
    } else {
      return null;
    }
  }

  addManagerLocally(manager) {
    this.managersToShow.push(manager);
    this.buildingService.managersToAdd = this.managersToShow.filter((manager) => !manager.user);
    this.searchInput.setFocus();
    this.clearInputValueCloseDropdown();
  }

  deleteManagerLocally(manager, managersToShow) {
    if (this.buildingService.managersToAdd) {
      this.removeUsersOnDelete(manager, managersToShow);
      this.removeUsersOnDelete(manager, this.buildingService.managersToAdd);
    } else {
      this.managersToShow = managersToShow.filter(admin => admin.userId !== manager.userId);
    }
    this.building.buildingAdmins.some(admin => admin.user && admin.userId === manager.userId) && this.managersToDelete.push(manager);
    this.clearInputValueCloseDropdown();
  }

  removeUsersOnDelete(managerToRemove, managers) {
    const index = managers.indexOf(managerToRemove);
    index > -1 && managers.splice(index, 1);
  }

  clearInputValueCloseDropdown() {
    this.searchInput.value = '';
    this.buildingService.showManagersList = false;
  }

  openPhotoModal() {
    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      import('../../components/add-building-photo-modal/add-building-photo-modal.component').then(m =>
        this.modalService.presentModal(m.AddBuildingPhotoModalComponent, 'auto-height', { building: this.building })
      );
    } else {
      this.modalService.openDesktopModal('AddBuildingPhotoModal');
    }
  }

  toggleIcon() {
    this.showTimeZonesList = !this.showTimeZonesList;
  }

  getChosenZone(event, timeZoneNum) {
    this.chosenTimeZone = event.target.innerText;
    this.chosenTimeZoneNum = timeZoneNum;
    this.showTimeZonesList = !this.showTimeZonesList;
  }

  openDeleteModal() {
    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      import('../../components/delete-modal/delete-modal.component').then(m =>
        this.modalService.presentModal(m.DeleteModalComponent, 'auto-height', { building: this.building, fromOffices: false })
      );
      this.events.onOpenDelete(false);
    } else {
      this.modalService.closeDesktopModal('EditBuildingModal');
      this.modalService.openDesktopModal('DeleteModal');
      this.openDelete.emit(false);
    }
  }

  createPreviousBuildingAdmins() {
    this.managersToDelete.length && (this.building.buildingAdmins = this.building.buildingAdmins.concat(this.managersToDelete));
    this.building.buildingAdmins = this.building.buildingAdmins.filter((admin, index, self) => {
      return index === self.findIndex((t) => {
        return admin.user && t.user && (admin.user.id === t.user.id);
      });
    });
  }

  openDetails(building, isRoomDetails) {
    this.createPreviousBuildingAdmins();
    import('../../components/room-building-details-admin/room-building-details-admin.component').then(m =>
      this.modalService.presentModal(
        m.RoomBuildingDetailsAdminComponent,
        'auto-height',
        { building, isRoomDetails }
      )
    );
  }

}
