import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { ModalService } from '../../../services/modal/modal.service';
import { EventsService } from '../../../services/events/events.service';

@Component({
  selector: 'app-user-details-modal',
  templateUrl: './user-details-modal.component.html',
  styleUrls: ['./user-details-modal.component.scss'],
})
export class UserDetailsModalComponent implements OnInit {

  userAvatar = '/assets/icon/empty-avatar.svg';
  user;
  labels = [] as any[];

  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    private datePipe: DatePipe,
    private modalService: ModalService,
    private events: EventsService
  ) { }

  ngOnInit() {
    const options = this.navParams.get('options');
    this.user = options.user;
    this.user && this.createLabels();
  }

  createLabels() {
    this.labels = [
      { name: 'Created:', value: this.datePipe.transform(this.user.createdAt, 'MMMM d, YYYY'), enabled: true },
      { name: 'Email:', value: this.user.email, enabled: true },
      { name: 'Phone:', value: this.user.phone ? this.user.phone : '-', enabled: true },
      { name: 'Last active:', value: this.user.lastActive ? this.datePipe.transform(this.user.lastActive, 'MMMM d, YYYY') : '–', enabled: this.user.isClientsSelected },
      { name: 'Revenue:', value: `$${this.user.revenue / 100}`, enabled: this.user.isClientsSelected }
    ];
  }

  filterEnabledLabels() {
    return this.labels.filter(label => label.enabled);
  }

  setAdminBuildings() {
    return this.user.buildings.slice(0, 3);
  }

  openBuildingsModal() {
    this.modalController.dismiss();
    import('../../components/edit-building-manager/edit-building-manager.component').then(m =>
      this.modalService.presentModal(m.EditBuildingManagerComponent, 'auto-height', { user: this.user })
    );
  }

  setTextDueBuildings(user) {
    if (user.buildings.constructor === Object || (user.buildings.length && user.buildings.length <= 3)) {
      return 'View details';
    } else if (!user.buildings.length) {
      return 'No buildings';
    } else if (user.buildings.length > 3) {
      return 'View all buildings';
    }
  }

  setStyle(user) {
    if (user.buildings.constructor === Object) { return; }
    return !user.buildings.length && 'none';
  }

  close() {
    if (this.user.isAdminsSelected) { this.events.onCloseModal(true); }
    this.modalController.dismiss();
  }

}
