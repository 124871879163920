import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { Geolocation } from '@capacitor/geolocation';

import { RequestService } from '../request/request.service';
import { AuthService } from '../auth/auth.service';
import { BookingService } from '../booking/booking.service';
import { DateService } from '../date/date.service';
import { LoaderService } from '../loader/loader.service';
import { ToastService } from '../toast/toast.service';

import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { take, switchMap, catchError, tap } from 'rxjs/operators';
import { forkJoin, from } from 'rxjs';
import { PaginationService } from '../pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  rooms = [] as any;
  likedRooms = [] as any;
  likedBuildings = [] as any;
  address: string;
  roomsByBuilding = [] as any;
  historyRooms = [] as any;
  geolocation = null;

  constructor(
    public requestService: RequestService,
    public authService: AuthService,
    public bookingService: BookingService,
    private dateService: DateService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private location: Location,
    private router: Router,
    private paginationService: PaginationService
  ) { }

  async getLikedRooms() {
    await this.loaderService.presentLoader();
    this.requestService.getAuthRequest('user-like').subscribe(
      (resp: any[]) => {
        this.likedRooms = resp;
        this.likedRooms.forEach(room => room.userLike = true);
        this.loaderService.dismissLoader();
      },
      err => {
        this.loaderService.dismissLoader();
        console.log(err)
      }
    );
  }

  async getLikedBuildings() {
    await this.loaderService.presentLoader();
    this.requestService.getAuthRequest('buildings/likes').subscribe(
      (resp: any[]) => {
        this.likedBuildings = resp;
        this.address = `St ${resp[0]?.streetNumber} ${resp[0]?.city.name}`;
        this.loaderService.dismissLoader();
      },
      err => {
        this.loaderService.dismissLoader();
        console.log(err)
      }
    );
  }

  getGeolocation() {
    return from(Geolocation.getCurrentPosition()).pipe(
      take(1),
      switchMap((locationData) => { return this.nearestRoomsRequest(locationData) }),
      catchError((error) => { return this.nearestRoomsRequest(null) })
    );
  }

  nearestRoomsRequest(geolocation) {
    this.geolocation = geolocation;
    let request;

    if (this.geolocation && this.geolocation.coords) {
      const geolocationQuery = '?lat=' + this.geolocation.coords.latitude + '&lon=' + this.geolocation.coords.longitude;

      request = this.authService.user ?
        this.requestService.getAuthRequest('rooms' + geolocationQuery) :
        this.requestService.getRequest('rooms' + geolocationQuery);
    } else {
      request = this.authService.user ? this.requestService.getAuthRequest('rooms') : this.requestService.getRequest('rooms');
    }
    return request;
  }

  getNearestRooms() {
    this.nearestRoomsRequest(this.geolocation).subscribe(
      (resp: any[]) => {
        this.rooms = resp;
        this.loaderService.dismissLoader();
      },
      err => {
        this.loaderService.dismissLoader();
        err && this.toastService.presentToast(err.message, 'error');
        console.log(err);
      }
    );
  }

  async getAllRooms(isLoaderRequired = true) {
    const requestForLikedRooms = this.authService.user && this.requestService.getAuthRequest('user-like');
    isLoaderRequired && await this.loaderService.presentLoader();

    if (requestForLikedRooms) {
      forkJoin([this.getGeolocation(), requestForLikedRooms])
        .subscribe(
          response => {
            this.setRoomsData(response[0], response[1]);
            isLoaderRequired && this.loaderService.dismissLoader();
          },
          err => {
            this.loaderService.dismissLoader();
            err && this.toastService.presentToast(err.message, 'error');
          });
    } else {
      this.getGeolocation().subscribe(() => {
        this.getNearestRooms();
      });
    }
  }

  setRoomsData(nearestRooms, likedRooms) {
    this.rooms = nearestRooms;
    this.likedRooms = likedRooms;
    this.likedRooms.forEach(room => room.userLike = true);
  }

  async getAvailableRooms() {
    const body = {
      startDate: this.bookingService.multiDatesArr.length
        ? this.dateService.formatDate(new Date(this.bookingService.multiDatesArr[0].time), '6 AM')
        : this.dateService.formatDate(this.bookingService.date, this.bookingService.timeStart),
      endDate: this.bookingService.multiDatesArr.length
        ? this.dateService.formatDate(new Date(this.bookingService.multiDatesArr[1].time), '12 AM')
        : this.dateService.formatDate(this.bookingService.date, this.bookingService.timeEnd),
      chair: this.bookingService.persons
    };
    await this.loaderService.presentLoader();
    const request = this.authService.user
      ? this.requestService.postAuthRequest('bookings/' + this.bookingService.building.id + '/rooms-available', body)
      : this.requestService.postRequest('bookings/' + this.bookingService.building.id + '/rooms-available', body);
    try {
      this.roomsByBuilding = await request.toPromise();
      this.loaderService.dismissLoader();
      this.location.replaceState('search/results');
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      this.location.replaceState('search/results');
      console.log(err);
    };
  }

  async getlikedAvailableRooms() {
    const body = {
      startDate: this.bookingService.multiDatesArr.length
        ? this.dateService.formatDate(new Date(this.bookingService.multiDatesArr[0].time), '6 AM')
        : this.dateService.formatDate(this.bookingService.date, this.bookingService.timeStart),
      endDate: this.bookingService.multiDatesArr.length
        ? this.dateService.formatDate(new Date(this.bookingService.multiDatesArr[1].time), '12 AM')
        : this.dateService.formatDate(this.bookingService.date, this.bookingService.timeEnd),
      chair: this.bookingService.persons
    };
    await this.loaderService.presentLoader();
    try {
      this.roomsByBuilding = await this.requestService.postAuthRequest('bookings/rooms-liked-available', body).toPromise();
      this.loaderService.dismissLoader();
      this.location.replaceState('liked/results');
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      this.location.replaceState('liked/results');
      console.log(err);
    };
  }

  async getHistoryRooms(event?, loaderRequired = false) {
    loaderRequired && await this.loaderService.presentLoader();

    try {
      await this.requestService.getAuthRequest('booked-rooms-history', this.paginationService.currentPagination.currentPage).pipe(
        tap((resp: { currentPage: number, totalCount: number, totalPages: number, rooms: [] }) => {
          this.historyRooms = this.paginationService.currentPagination.currentPage === 1 ? resp.rooms : [...this.historyRooms, ...resp.rooms];
          this.paginationService.currentPagination.currentPage = resp.currentPage;
          this.paginationService.currentPagination.totalPages = resp.totalPages;
          this.paginationService.currentPagination.currentPage++;
          event && event.target.complete();

          this.loaderService.dismissLoader();
        })
      ).toPromise();
    } catch (err) {
      this.loaderService.dismissLoader();
      console.log(err)
    }
  }

  async getRooms(filter = null, buildingIds = null, event?, isLoaderRequired = false) {
    if (isLoaderRequired) { await this.loaderService.presentLoader(); };

    let buildingIdsQuery = buildingIds && buildingIds.map(buildingId => `buildingId[]=${buildingId}&`).join('');
    let query;

    if (buildingIds && filter) {
      query = `?filter=${filter}&${buildingIdsQuery}`;
    } else if (filter) {
      query = '?filter=' + filter;
    } else if (buildingIds) {
      query = `?filter=all&${buildingIdsQuery}`;
    } else {
      query = '';
    }

    try {
      await this.requestService.getAuthRequest('admin/rooms' + query, this.paginationService.currentPagination.currentPage).pipe(
        tap((resp: { currentPage: number, totalCount: number, totalPages: number, rooms: [] }) => {
          this.rooms = this.paginationService.currentPagination.currentPage === 1 ? resp.rooms : [...this.rooms, ...resp.rooms];
          this.paginationService.currentPagination.currentPage = resp.currentPage;
          this.paginationService.currentPagination.totalPages = resp.totalPages;
          this.paginationService.currentPagination.currentPage++;
          event && event.target.complete();

          this.loaderService.dismissLoader();
        })
      ).toPromise();
      return this.rooms;
    } catch (err) {
      this.loaderService.dismissLoader();
      console.log(err)
    }
  }
}
