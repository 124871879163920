/* eslint-disable @typescript-eslint/quotes */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  awsBaseUrl: 'https://smart-office-production.s3.us-west-1.amazonaws.com/',
  baseUrl: 'https://conference-room-rental.herokuapp.com/',
  redirectURI: 'https://smart-office-ora.firebaseapp.com/__/auth/handler',
  firebase: {
    apiKey: "AIzaSyDxs1tScSXxHoaGNhSN08crcDXlJN8KIaI",
    authDomain: "smart-office-ora.firebaseapp.com",
    projectId: "smart-office-ora",
    storageBucket: "smart-office-ora.appspot.com",
    messagingSenderId: "403479322235",
    appId: "1:403479322235:web:8f2a0f9a72d85d86800cde",
    measurementId: "G-RWFNSCQ4B0",
    vapidKey: "BLqilg0p3Q7pAmIwe5jR7c7L-4HMnh8G8RZ1qsuq0NNoAiHByqJQzQqT9T486M_j2zz_ngrDI0ttJJmtGsiAPiw"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
