import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ModalService } from '../../../services/modal/modal.service';

@Component({
  selector: 'app-reservation-list-modal',
  templateUrl: './reservation-list-modal.component.html',
  styleUrls: ['./reservation-list-modal.component.scss'],
})
export class ReservationListModalComponent implements OnInit {

  @Input() bookingsData;
  @Input() selectedDate;
  @Output() selectedBooking = new EventEmitter();
  options = this.params.get('options');

  constructor(
    public modalService: ModalService,
    private params: NavParams
  ) { }

  ngOnInit() {
    if (this.modalService.isMobileView) {
      this.bookingsData = this.options.bookingsData;
      this.selectedDate = this.options.selectedDate;
    }
  }

  openDetailsModal(booking) {
    if (this.modalService.isMobileView) {
      this.modalService.openReservationModal(booking, false, false, this.options.calendarOptions, this.bookingsData, this.selectedDate);
    } else {
      this.selectedBooking.emit(booking);
      this.modalService.closeDesktopModal('ReservationListModal');
      this.modalService.openDesktopModal('ReservationModal');
    }
  }

  back() {
    this.modalService.dismiss();
  }

}
