import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { GroupService } from '../../../../services/group/group.service';

@Component({
  selector: 'app-group-name-input',
  templateUrl: './group-name-input.component.html',
  styleUrls: ['./group-name-input.component.scss'],
})
export class GroupNameInputComponent implements OnInit, OnChanges {

  @ViewChild('groupNameInput') groupNameInput;
  @Input() groupName;
  @Input() clearRequired;
  @Input() isGroupAdmin;
  @Output() inputChange = new EventEmitter();
  counterVal = 30;

  constructor(
    private groupService: GroupService
  ) { }

  ngOnInit() { }

  ngOnChanges() {
    this.groupName && (this.counterVal = 30 - this.groupName.length);
    if (this.clearRequired) { this.groupNameInput.value = ''; this.counterVal = 30; }
  }

  calcSymbols(event, maxLength) {
    this.groupService.name = event.target.value;
    this.counterVal = maxLength - this.groupService.name.length;
    this.inputChange.emit(this.groupService.name);
  }

}
