import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ModalService } from '../../../services/modal/modal.service';
import { RoomCreationService } from '../../services/room-creation/room-creation.service';
import { BuildingService } from '../../services/building/building.service';
import { FacilityService } from '../../services/facility/facility.service';
import { RoomUpdatingService } from '../../services/room-updating/room-updating.service';
import { RequestService } from 'src/app/services/request/request.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { EventsService } from 'src/app/services/events/events.service';
import { ImageConversionService } from 'src/app/services/image-conversion/image-conversion.service';


import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-room-modal',
  templateUrl: './room-modal.component.html',
  styleUrls: ['./room-modal.component.scss'],
})
export class RoomModalComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Output() openDelete = new EventEmitter();

  @ViewChild('content') content: ElementRef;
  @ViewChild('coverPicturesInput') coverPicturesInput: ElementRef;
  @Input('room') room;
  @Input() isRoomCreation;

  status;
  servicePic;

  facilities = [];
  buildingsToShow;
  isInputChange = false;
  chairsAmount = 1;

  editRoomForm: UntypedFormGroup = new UntypedFormGroup({
    floor: new UntypedFormControl(''),
    number: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    description: new UntypedFormControl(''),
    price: new UntypedFormControl(''),
    lockId: new UntypedFormControl(''),
    isServiceFeeEnabled: new UntypedFormControl(''),
    feeAmount: new UntypedFormControl(''),
    feeType: new UntypedFormControl(''),
  });

  counterTitleValue = 50;
  counterDescriptionValue = 500;

  galleryImages = [];
  planImages = [];
  coverImage;

  constructor(
    public modalService: ModalService,
    public roomCreationService: RoomCreationService,
    public buildingService: BuildingService,
    private formBuilder: UntypedFormBuilder,
    public facilityService: FacilityService,
    public roomUpdatingService: RoomUpdatingService,
    private requestService: RequestService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private params: NavParams,
    private events: EventsService,
    private imgConversion: ImageConversionService
  ) { }

  async ngOnInit() {
    if (this.modalService.isMobileView) {
      const options = this.params.get('options');
      this.isRoomCreation = options.isRoomCreation;
      this.room = options.room;
    }

    await this.getFacilitiesAndBuildings();
    this.initForm();
    this.content.nativeElement.scrollTop = 0;
    if (this.isRoomCreation) { return; }

    this.filterImagesByType();
    this.setInputValues();
    this.facilityService.room = this.room;
    await this.buildingService.getBuildingNamesList();
  }

  initForm() {
    this.editRoomForm = this.formBuilder.group({
      floor: ['', Validators.compose([Validators.required])],
      number: ['', Validators.compose([Validators.required])],
      name: ['', Validators.compose([Validators.required])],
      description: '',
      lockId: ['', Validators.compose([Validators.required])],
      price: ['', Validators.compose([Validators.required])],
      isServiceFeeEnabled: [this.room?.serviceFee?.isEnabled],
      feeAmount: [!this.isRoomCreation ? (this.room?.serviceFee?.type === 'percent' ? this.room?.serviceFee?.amount : this.room?.serviceFee?.amount / 100) : 0],
      feeType: [this.room?.serviceFee?.type]
    }, { updateOn: 'change' });
  }

  async getFacilitiesAndBuildings() {
    await this.loaderService.presentLoader();

    const currentRoomRequest = !this.isRoomCreation && this.requestService.getAuthRequest(`rooms/${this.room.id}`);
    try {
      const response: any = currentRoomRequest
        ? await forkJoin([this.facilityService.facilitiesRequest, this.requestService.getAuthRequest('admin/buildings'), currentRoomRequest]).toPromise()
        : await forkJoin([this.facilityService.facilitiesRequest, this.requestService.getAuthRequest('admin/buildings')]).toPromise();

      this.facilityService.facilities = response[0];
      this.buildingService.buildings = response[1].buildings;
      this.loaderService.dismissLoader();

      if (this.isRoomCreation) { return; }
      this.room = response[2];
      this.facilityService.pushFacilityToRoomData(this.room);
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
    }
  }

  async convertPicturesFromInput(event, type) {
    const files = event.target.files;
    if (type === 'gallery' && this.checkMaxImagesLength(files)) {
      this.toastService.presentToast('You can’t upload more than 4 images.', 'error');
      this.clearFileInputValue(event);
      return;
    }

    await this.loaderService.presentLoader();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = file.type;
      if (file.size > 10000000) { this.toastService.presentToast('Maximum upload file size 10 MB', 'error'); this.clearFileInputValue(event); return; }

      const reader = new FileReader();

      reader.onload = async (ev) => {
        if (this.imgConversion.isHeicImgType(fileType)) {
          let conversionResponse = await this.imgConversion.convertHeic2Jpeg(reader, file.name, true);
          this.setCreationImages(type, conversionResponse[0], conversionResponse[1]);
          this.setWrapperStatus(false);
        } else {
          this.setCreationImages(type, file, reader.result);
          this.setWrapperStatus(false);
          this.loaderService.dismissLoader();
        }
      }

      reader.readAsDataURL(file);
    }
    this.clearFileInputValue(event);
  }

  clearFileInputValue(event) {
    event.target.value = '';
  }

  setCreationImages(type, file, result) {
    if (this.isRoomCreation) {
      const picturesToCreateLocally = type === 'gallery'
        ? this.roomCreationService.localGalleryPictures
        : this.roomCreationService.localPlanPictures;

      this.roomCreationService.putPicturesLocally(picturesToCreateLocally, result, file.name);
      this.roomCreationService.putFiles(this.roomCreationService.imagesToUpload, { file, type });
    } else {
      this.updateLocalPictures(this.roomUpdatingService.localRoomPictures, `${type}Images`, result, file, type);
    }
  }

  checkUploadButton() {
    return !this.isRoomCreation ? this.galleryImages.length < 4 : this.roomCreationService.localGalleryPictures.length < 4;
  }

  setWrapperStatus(status) {
    this.status = status;
  }

  checkCounterLength(event, maxLength, isFromServer) {
    if (isFromServer) {
      maxLength === 50
        ? this.counterTitleValue = maxLength - event.length
        : this.counterDescriptionValue = maxLength - event.length;
    } else {
      maxLength === 50
        ? this.counterTitleValue = maxLength - event.target.value.length
        : this.counterDescriptionValue = maxLength - event.target.value.length;
    }
  }

  changeAmount(type) {
    if (type === 'add') {
      this.chairsAmount++;
    } else {
      this.chairsAmount > 1 && this.chairsAmount--;
    }
  }

  checkDisabled() {
    if (this.isRoomCreation) {
      return !this.chairsAmount
        || !this.buildingService.chosenBuilding
        || !this.roomUpdatingService.roomData?.facilities.length
        || !this.roomCreationService.localGalleryPictures.length
        || !this.roomCreationService.localPlanPictures.length
    } else {
      return !this.chairsAmount
        || !this.room.building.name
        || !this.galleryImages.length
        || !this.planImages.length
        || !this.roomUpdatingService.roomData?.facilities.length;
    }
  }

  checkMaxImagesLength(files) {
    return files.length > 4 || files.length + this.roomCreationService.localGalleryPictures.length > 4 || this.galleryImages.length + files.length > 4;
  }

  openDeleteModal(room) {
    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      import('../../components/delete-modal/delete-modal.component').then(m =>
        this.modalService.presentModal(m.DeleteModalComponent, 'auto-height', { room, fromOffices: true })
      );
      this.events.onOpenDelete(true);
    } else {
      this.modalService.closeDesktopModal('RoomModal');
      this.modalService.openDesktopModal('DeleteModal');
      this.openDelete.emit(true);
    }
  }

  getCoverImage(picture) {
    if (!picture) { return; }
    return picture.pictures ? 'url(' + picture.pictures.medium + ')' : 'url(' + picture.storage + ')';
  }

  async onSubmit(data, buildingId, chairsAmount) {
    data.buildingId = buildingId;
    data.chair = chairsAmount;
    data.facilities = this.roomUpdatingService.roomData.facilities;
    data.price = data.price * 100;
    data = this.createServiceFeeObj(data);
    this.roomCreationService.roomData = data;

    if (!data.serviceFee.amount && this.editRoomForm.controls['isServiceFeeEnabled'].value) {
      this.toastService.presentToast('Surcharge amount can`t be empty', 'error');
      return;
    }

    !this.isRoomCreation && (this.roomUpdatingService.close = this.close);
    this.isRoomCreation ? await this.roomCreationService.createRoom() : await this.roomUpdatingService.updateRoomData(data, this.room);
  }

  createServiceFeeObj(data) {
    data.serviceFee = {
      type: data.feeType === '%' ? 'percent' : 'amount',
      amount: data.feeAmount && (data.feeType === '%' ? data.feeAmount : data.feeAmount * 100),
      isEnabled: data.isServiceFeeEnabled
    };

    const { isServiceFeeEnabled, feeAmount, feeType, ...cleared } = data;

    if (!cleared.serviceFee.amount) { cleared.serviceFee.isEnabled = false; }
    return cleared;
  }

  filterImagesByType() {
    this.coverImage = this.room.images.find(image => image.type === 'cover');
    this.galleryImages = this.room.images.filter(image => image.type === 'gallery');
    this.coverImage && this.galleryImages.unshift(this.coverImage);

    this.planImages = this.room.images.filter(image => image.type === 'plan');
  }

  deletePics(i, currentImages, isDeletePlan = false, currPicture) {
    this.roomUpdatingService.imagesToDelete.push(...currentImages.splice(i, 1));
    (!i && !isDeletePlan) && (this.roomUpdatingService.imageToUpdate = currentImages[0]);
    this.roomUpdatingService.imagesToUpload = this.filterImagesToUpload(currPicture);
  }

  filterImagesToUpload(currPicture) {
    return this.roomUpdatingService.imagesToUpload.filter(img => img.name !== currPicture.name);
  }

  updateLocalPictures(servicePics, componentPics, readerResult, file, type) {
    this.roomUpdatingService.putPicturesLocally(servicePics, readerResult, file.name, type);
    servicePics.forEach(picture => this.servicePic = picture);
    this[componentPics].push(this.servicePic);
    this.roomUpdatingService.putFiles(this.roomUpdatingService.imagesToUpload, { file, type });
    if (type !== 'plan' && !this.galleryImages.some(pic => pic.id)) {
      this.roomUpdatingService.imagesToUpload[0].photoFile.type = 'cover';
    }
  }

  setInputValues() {
    this.chairsAmount = this.room.chair;
    Object.keys(this.editRoomForm.controls).forEach(key => {
      for (let propt in this.room) {
        (key === propt) && this.editRoomForm.controls[key].setValue(this.room[propt]);
        (key === propt && key === 'price') && this.editRoomForm.controls[key].setValue(this.room[propt] / 100);
        (propt === 'name') && this.checkCounterLength(this.room[propt], 50, true);
        (propt === 'description') && this.checkCounterLength(this.room[propt], 500, true);
      }
    });
  }

  checkFacility(id) {
    return this.room.facilities.some(facility => facility.id === id);
  }

  setPricePlaceHolder() {
    return this.isRoomCreation ? 'Enter price here' : `${this.room.price / 100}$ / hour`;
  }

  back(backToOffices = false) {
    this.modalService.isMobileView
      ? (this.isRoomCreation ? this.modalService.dismiss() : (backToOffices ? this.modalService.dismiss() : this.openRoomDetails(this.room)))
      : this.modalService.closeDesktopModal('RoomModal');

    this.buildingService.clearState();
    this.facilityService.clearState();
    this.roomCreationService.localGalleryPictures = [];
    this.roomCreationService.localPlanPictures = []
    this.roomCreationService.imagesToUpload = [];
    this.roomUpdatingService.imageToUpdate = null;
    this.galleryImages = [];
    this.planImages = [];
  }

  openRoomDetails(room) {
    room.coverUrl = this.coverImage.pictures;
    this.modalService.dismiss();
    import('../room-building-details-admin/room-building-details-admin.component').then(m =>
      this.modalService.presentModal(m.RoomBuildingDetailsAdminComponent, 'auto-height', { room, isRoomDetails: true })
    )
  }

  onChangeFeeType(event) {
    const currentControlVal = this.editRoomForm.controls['feeType'].value === 'percent' ? '%' : '$';

    if (event.target.value !== currentControlVal) {
      this.editRoomForm.controls['feeType'].setValue(event.target.value);
    }
  }
}
