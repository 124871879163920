import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ModalService } from '../../../services/modal/modal.service';

@Component({
  selector: 'app-map-usa',
  templateUrl: './map-usa.component.html',
  styleUrls: ['./map-usa.component.scss'],
})
export class MapUsaComponent implements OnInit {

  @Input() public coordinates: any[];
  @Input() fillStateColor: string;
  @Input() strokeColor: string;
  @Input() fillColor: string;
  @Input() set setPopularArea(popularArea: { state: string, rooms: number }[]) {
    this.popularArea = popularArea;
    if (this.popularArea?.length) {
      this.getPositionBubbleOnMap();
      this.resizeBubbleRadius();
    }
  };

  @ViewChildren('element') element: QueryList<ElementRef>;

  public stateData: {
    name: string,
    x: number,
    y: number,
    rooms?: number,
    radius?: number
  }[] = [];

  private popularArea: { state: string, rooms: number }[] = [];

  constructor(public modalService: ModalService) { }

  ngOnInit() { }

  getPositionBubbleOnMap() {
    this.stateData = this.element.map((element: ElementRef) => {
      return {
        name: element.nativeElement.textContent,
        x: element.nativeElement.getAttribute('x'),
        y: element.nativeElement.getAttribute('y'),
        rooms: this.popularArea.find((area) => area.state === element.nativeElement.textContent)?.rooms
      }
    });
  }

  resizeBubbleRadius() {
    const roomsDataList = this.popularArea.map(room => room.rooms);

    if (roomsDataList?.length) {
      const maxRoom = Math.max.apply(null, roomsDataList);
      const minRoom = Math.min.apply(null, roomsDataList);
      const range = maxRoom - minRoom;
      const maxRadius = 40;
      const minRadius = 20;

      this.stateData = this.stateData.map(data => {
        if (!data.rooms) {
          return data
        }
        const relSize = ((data.rooms - minRoom) / range);
        let radius = Math.floor(minRadius + relSize * (maxRadius - minRadius));
        radius = radius ? radius : minRadius;

        return { ...data, radius };
      });
    }
  };
}
