import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NavController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-refund-before-delete-acc-alert',
  templateUrl: './refund-before-delete-acc-alert.component.html',
  styleUrls: ['./refund-before-delete-acc-alert.component.scss'],
})
export class RefundBeforeDeleteAccAlertComponent implements OnInit {

  options = this.params.get('options');

  textValues = [
    {
      bookingInProcessTitle: 'You have an ongoing reservation.',
      isRefundedTxt: 'We`re sad to see you go 😔'
    },
    {
      bookingInProcessDescription: 'You can delete your account once the reservation is over.',
      isRefundedTxt: 'Please note that once you delete your account, any reservations canceled 24 h or less before the session will not be refunded.'
    }
  ];

  constructor(
    public modalService: ModalService,
    private navCtrl: NavController,
    private params: NavParams
  ) { }

  ngOnInit() { }

  openRefundPage() {
    this.modalService.dismiss();
    this.navCtrl.navigateForward('client-reservations-refund');
  }

  setTextDueOptionsParams(isTitle = false) {
    if (this.options.isBookingInProcess) {
      return isTitle ? this.textValues[0].bookingInProcessTitle : this.textValues[1].bookingInProcessDescription
    }

    return isTitle ? this.textValues[0].isRefundedTxt : this.textValues[1].isRefundedTxt;
  }

}
