import { Injectable } from '@angular/core';
import heic2any from 'heic2any';

import { LoaderService } from '../../services/loader/loader.service';
import { ToastService } from '../../services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ImageConversionService {

  constructor(
    private loaderService: LoaderService,
    private toastService: ToastService
  ) { }

  async convertHeic2Jpeg(reader, fileName, isAdminPanel = false) {
    !isAdminPanel && await this.loaderService.presentLoader();
    try {
      const blob = await (await fetch(reader.result as string)).blob();
      const conversionResult = await heic2any({ blob, toType: 'image/jpeg', quality: 0.5 }) as any;
      conversionResult.name = fileName;

      const base64 = await this.blobToBase64(conversionResult)
      this.loaderService.dismissLoader();
      return [conversionResult, base64];
    } catch (err) {
      console.log(err);
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  isHeicImgType(type) {
    return type === 'image/heic' || type === 'image/heif';
  }

  blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };
}
