import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ModalService } from 'src/app/services/modal/modal.service';
import { RequestService } from '../../services/request/request.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss'],
})
export class LikeComponent implements OnInit {

  @Input() room;
  @Output() likeAdded = new EventEmitter();
  @Output() likeDeleted = new EventEmitter();

  disabled = false;

  constructor(
    private modalService: ModalService,
    private requestService: RequestService,
    private toastService: ToastService
  ) { }

  ngOnInit() { }

  like(event) {
    if (this.disabled) { return; }
    if (localStorage.getItem('smartLockUser')) {
      if (!this.room) { return; }
      this.room?.userLike ? this.deleteLike() : this.postLike();
    } else {
      this.modalService.modalOptions = null;
      import('../../components/login/login.component').then(m => this.modalService.presentModal(m.LoginComponent, 'fixed-height', 'login'));
    }
  }

  postLike() {
    this.disabled = true;
    this.requestService.postAuthRequest('user-like', { roomId: this.room.id }).subscribe(
      (resp) => {
        this.room.userLike = true;
        this.likeAdded.emit(this.room);
        this.disabled = false;
      },
      err => {
        console.log(err);
        this.toastService.presentToast(err.message, 'error');
        this.disabled = false;
      }
    );
  }

  deleteLike() {
    this.disabled = true;
    this.requestService.deleteAuthRequest(`user-like/${this.room.id}`).subscribe(
      (resp) => {
        this.room.userLike = false;
        this.likeDeleted.emit(this.room);
        this.disabled = false;
      },
      err => {
        console.log(err);
        this.toastService.presentToast(err.message, 'error');
        this.disabled = false;
      }
    );
  }

}
