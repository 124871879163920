import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavParams } from '@ionic/angular';

import { GroupService } from 'src/app/services/group/group.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { RequestService } from 'src/app/services/request/request.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ValidatorsService } from 'src/app/services/validators/validators.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-group-members-adding-modal',
  templateUrl: './group-members-adding-modal.component.html',
  styleUrls: ['./group-members-adding-modal.component.scss'],
})
export class GroupMembersAddingModalComponent implements OnInit {

  memberForm: FormGroup = new FormGroup({
    email: new FormControl(''),
  });

  isFocused = false;
  isGroupWasCreated = false;
  isFromGroupDetails = false;

  constructor(
    private formBuilder: FormBuilder,
    private validatorsService: ValidatorsService,
    public groupService: GroupService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private requestService: RequestService,
    private toastService: ToastService,
    private params: NavParams,
    private auth: AuthService,
    private events: EventsService
  ) { }

  ngOnInit() {
    const options = this.params.get('options');
    this.isGroupWasCreated = options && options.isGroupWasCreated;
    this.isFromGroupDetails = options && options.isFromGroupDetails;
    this.initForm();
  }

  back() {
    this.groupService.clearState();
    this.modalService.dismiss();
    if (this.isFromGroupDetails) { return; }
    import('../group-name-adding-modal/group-name-adding-modal.component').then(m =>
      this.modalService.presentModal(m.GroupNameAddingModalComponent, 'auto-height notifications', { isResizeRequired: true })
    );
  }

  initForm() {
    this.memberForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, this.validatorsService.validateEmail()])],
    }, { updateOn: 'change' });
  }

  async addMember(formData, emailInput) {
    emailInput.value = '';
    await this.loaderService.presentLoader();

    try {
      const response = await this.requestService.postAuthRequest('users/find-by', { email: formData.email }).toPromise();
      this.loaderService.dismissLoader();
      response ? this.checkUserFullName(response) : this.checkUserFullName(formData);
    } catch (err) {
      console.log(err);
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  checkUserFullName(user) {
    if (user.firstName && user.lastName) {
      this.groupService.membersToShow.push({ userCredential: `${user.firstName} ${user.lastName} (${user.email})`, email: user.email });
    } else {
      this.groupService.membersToShow.push({ userCredential: user.email, email: user.email });
    }
  }

  deleteMemberFromList(index) {
    this.groupService.membersToShow = this.groupService.membersToShow.filter(member => {
      return member !== this.groupService.membersToShow[index];
    });
  }

  async createGroup() {
    if (this.isGroupWasCreated) {
      const emailsToAdd = this.groupService.membersToShow.map(member => member.email);

      await this.loaderService.presentLoader();
      try {
        const response = await this.groupService.usersToGroupRequest(this.groupService.createdGroup, emailsToAdd).toPromise();
        this.toastService.presentToast('User(s) successfully invited');
        this.loaderService.dismissLoader();
        if (!response) { return; }
        if (this.isFromGroupDetails) {
          this.clearState();
          this.modalService.dismiss();
          this.events.onCloseGroupModal();
          return;
        }
        this.openNextModal();
      } catch (err) {
        this.loaderService.dismissLoader();
        this.toastService.presentToast(err.message, 'error');
      }
      return;
    }

    const response = await this.groupService.createGroup();
    response && this.openNextModal();
  }

  openNextModal() {
    this.modalService.dismiss();
    import('../group-created-modal/group-created-modal.component').then(m =>
      this.modalService.presentModal(m.GroupCreatedModalComponent, 'auto-height notifications')
    );
  }

  isMemberAlreadyInList(emailInput) {
    return this.groupService.membersToShow.some(member => member.email === emailInput.value) || this.auth.user.email === emailInput.value;
  }

  clearState(emailInput?) {
    emailInput &&( emailInput.value = '');
    this.groupService.membersToShow = [];
  }

}
