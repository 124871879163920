import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { RequestService } from 'src/app/services/request/request.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-rate-modal',
  templateUrl: './rate-modal.component.html',
  styleUrls: ['./rate-modal.component.scss'],
})
export class RateModalComponent implements OnInit {

  @Input() options: {
    roomId: number,
    bookingId: number
  };

  condition = 0;
  isonsHref = ['/assets/icon/star.svg', '/assets/icon/star_filled.svg'];
  list = ['Terrible', 'Bad', 'Okay', 'Good', 'Great'];
  starStatus;

  constructor(
    public modalController: ModalController,
    private requestService: RequestService,
    public bookingService: BookingService,
    private toastService: ToastService
  ) { }

  ngOnInit() { }

  review(i) {
    this.condition = i + 1;
  }

  async rateRoom() {
    this.modalController.dismiss();
    const data = {
      roomId: +this.options?.roomId,
      bookingId: +this.options?.bookingId,
      rating: this.condition
    };

    try {
      await this.requestService.postAuthRequest('ratings', data).toPromise();
      this.toastService.presentToast('Rating submitted');
    } catch (err) {
      this.toastService.presentToast(err.message, 'error');
    }
  }
}
