import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss'],
})
export class MemberListComponent implements OnInit {

  @Input() groupData;
  @Input() isGroupAdmin;

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit() {}

  setFullName(member) {
    return member.firstName && member.lastName ? `${member.firstName} ${member.lastName}` : 'Unknown Name';
  }

  openDeleteMemberModal(userId: number) {
    import('../../modals/delete-member-modal/delete-member-modal.component').then(m =>
      this.modalService.presentModal(m.DeleteMemberModalComponent, 'auto-height notifications', 
        { groupId: this.groupData.groupId, userId, isReservationOngoing: false }
      )
    );
  }

  filterMembersToShow(members, fromGroupDetails) {
    return !fromGroupDetails ? members.slice(1) : members;
  }
}
