import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ModalService } from '../../../services/modal/modal.service';
import { BuildingService } from '../../services/building/building.service';
import { RequestService } from '../../../services/request/request.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { ToastService } from '../../../services/toast/toast.service';
import { EventsService } from '../../../services/events/events.service';

import { forkJoin } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-building-admins-modal',
  templateUrl: './building-admins-modal.component.html',
  styleUrls: ['./building-admins-modal.component.scss'],
})
export class BuildingAdminsModalComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Input() buildingAdmins;
  searchBuildingForm: FormGroup;
  emptyAvatar = '/assets/icon/empty-avatar.svg';

  listener;
  checkBoxes;
  options;

  customAlertOptions: any = {
    cssClass: 'filter-alert',
    cancelText: 'CANCEL',
    okText: 'APPLY',
  };

  managersToDelete = [];
  managersToAdd = [];

  constructor(
    public modalService: ModalService,
    public buildingService: BuildingService,
    private requestService: RequestService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private params: NavParams,
    private events: EventsService,
    private fb: FormBuilder
  ) { }

  async ngOnInit() {
    this.searchBuildingForm = this.fb.group({
      buildingIds: new FormControl('')
    });

    if (this.modalService.isMobileView) {
      this.options = this.params.get('options');
      this.buildingAdmins = this.options.building.buildingAdmins;
    }
    await this.buildingService.getBuildingNamesList();
  }

  selectBuilding(buildingIds, userId) {
    const selectedBuildingsArr = buildingIds;
    const currentManager = this.buildingAdmins.find(admin => admin.userId === userId);

    this.checkPreviousUsersToDeleteForValues(selectedBuildingsArr, userId);

    if (!selectedBuildingsArr.length) {
      this.createManagersToDelete(currentManager);
    } else {
      this.checkDefaultBuildingIdChanged(selectedBuildingsArr, currentManager);
      if (selectedBuildingsArr.length < this.managersToAdd.length) {
        this.managersToAdd = this.managersToAdd.filter((manager, index) => {
          return manager.userId !== userId && manager.buildingId !== selectedBuildingsArr[index]
        });
      }
      this.createManagersToAdd(selectedBuildingsArr, userId, currentManager);
      this.removeManagersDuplicates();
    }
  }

  removeManagersDuplicates() {
    this.managersToAdd = this.managersToAdd.reduce((managers, prev) => {
      if (!managers.some(manager => manager.buildingId === prev.buildingId && manager.userId === prev.userId)) {
        managers.push(prev);
      }
      return managers;
    }, []);
  }

  createManagersToDelete(currentManager) {
    this.managersToDelete.push({ userId: currentManager.userId, buildingId: currentManager.buildingId });
  }

  createManagersToAdd(selectedBuildingsArr, userId, currentManager) {

    selectedBuildingsArr.forEach(buildingId => {
      this.managersToAdd.push({ userId, buildingId });
    });

    this.managersToAdd = this.managersToAdd.filter(manager => manager.buildingId !== currentManager.buildingId);
  }

  checkDefaultBuildingIdChanged(selectedBuildingsArr, currentManager) {
    if (!selectedBuildingsArr.includes(currentManager.buildingId)) {
      this.managersToDelete.push({ userId: currentManager.userId, buildingId: currentManager.buildingId });
    }
  }

  checkPreviousUsersToDeleteForValues(selectedBuildingsArr, userId) {
    const isValueOfDeletedUserChanged = this.managersToDelete.length &&
      this.managersToDelete.some(manager => manager.userId === userId) &&
      selectedBuildingsArr.length;

    isValueOfDeletedUserChanged && (this.managersToDelete = this.managersToDelete.filter(manager => manager.userId !== userId));
  }

  async onSubmit() {
    if (!this.managersToDelete.length && !this.managersToAdd.length) {
      this.modalService.isMobileView
        ? this.modalService.dismiss()
        : this.modalService.closeDesktopModal('BuildingAdminsModal');
      return;
    }

    await this.loaderService.presentLoader();

    try {
      if (this.buildingAdminRequests()) {
        const response = await forkJoin(this.buildingAdminRequests()).toPromise();
        this.loaderService.dismissLoader();
        this.closeModal();
      }
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      this.modalService.closeDesktopModal('BuildingAdminsModal');
      console.log(err);
    }
  }

  closeModal() {
    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      this.events.onCloseModal(false);
    } else {
      this.modalService.closeDesktopModal('BuildingAdminsModal');
      this.close.emit(true);
    }
  }

  buildingAdminRequests() {
    if (this.managersToAdd && this.managersToDelete) {
      return [
        this.requestService.postAuthRequest('building-admins', this.managersToAdd),
        this.requestService.deleteAuthRequest('building-admins', this.managersToDelete)
      ];
    } else if (this.managersToAdd) {
      return [this.requestService.postAuthRequest('building-admins', this.managersToAdd)];
    } else if (this.managersToDelete) {
      return [this.requestService.deleteAuthRequest('building-admins', this.managersToDelete)];
    } else {
      return null;
    }
  }

  openModal() {
    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      import('../../components/add-building-managers-mobile/add-building-managers-mobile.component')
        .then(m => this.modalService.presentModal(m.AddBuildingManagersMobileComponent, 'auto-height', { building: this.options.building, isReturnModalDetails: false }));
    } else {
      this.modalService.closeDesktopModal('BuildingAdminsModal');
      this.modalService.showAddBuildingManagersModal = true;
      this.modalService.openDesktopModal('EditBuildingModal');
    }
  }

  back() {
    this.modalService.dismiss();

    import('../../components/room-building-details-admin/room-building-details-admin.component').then(m =>
      this.modalService.presentModal(
        m.RoomBuildingDetailsAdminComponent,
        'auto-height',
        { building: this.options.building, isRoomDetails: false, isFromEditBuilding: true, buidingPaginationExists: this.options.buidingPaginationExists }
      )
    );
  }

}
