import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { BookingService } from '../../services/booking/booking.service';
import { ModalService } from '../../services/modal/modal.service';
import { GroupService } from 'src/app/services/group/group.service';

import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-booking-confirmed',
  templateUrl: './booking-confirmed.component.html',
  styleUrls: ['./booking-confirmed.component.scss'],
})
export class BookingConfirmedComponent implements OnInit {

  booking = JSON.parse(localStorage.getItem('smartLockBooking'));
  selectedGroupsNamesStr;

  constructor(
    public modalController: ModalController,
    public navController: NavController,
    public bookingService: BookingService,
    private modalService: ModalService,
    public groupService: GroupService,
    private params: NavParams
  ) { }

  ngOnInit() {
    this.bookingService.date = this.booking.date;
    const options = this.params.get('options');
    this.selectedGroupsNamesStr = options && options.selectedGroupsNamesStr;
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  goToRooms() {
    this.modalController.dismiss();
    this.bookingService.multiDatesArr = [];
    this.groupService.clearState();
    this.groupService.clearBoolState();
    this.bookingService.fromPayment = true;
    this.modalService.isConfirmedModalOpened = false;
    this.selectedGroupsNamesStr = '';
    this.groupService.groupMembersNum = null;
    this.navController.navigateForward('rooms');
  }

  openGroupNotificationModal() {
    this.modalService.dismiss();
    if (this.selectedGroupsNamesStr) {
      import('../group/modals/group-name-adding-modal/group-name-adding-modal.component').then(m =>
        this.modalService.presentModal(m.GroupNameAddingModalComponent, 'auto-height notifications', 
          { isGroupExists: true, fromBookingConfirmed: true, selectedGroupsNamesStr: this.selectedGroupsNamesStr }
        )
      );
      return; 
    }

    this.groupService.bookingId = this.booking.id;
    this.groupService.fromBookingFlow = true;
    import('../group/modals/group-notification-modal/group-notification-modal.component').then(m =>
      this.modalService.presentModal(m.GroupNotificationModalComponent, 'auto-height notifications')
    );
  }

  openRoomsTabAfterAddingGroup() {
    this.groupService.clearState();
    this.groupService.clearBoolState();
    this.navController.navigateForward(['rooms'], { queryParams: { isUserReservations: false } });
  }

}
