import { Component, Input, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ModalService } from '../../../services/modal/modal.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RoomsService } from 'src/app/services/rooms/rooms.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { BuildingService } from '../../services/building/building.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements OnInit {

  @Input() fromOffices;
  @Input() roomId;
  @Input() building;

  options = this.params.get('options');

  constructor(
    public modalService: ModalService,
    private loaderService: LoaderService,
    private roomsService: RoomsService,
    private requestService: RequestService,
    private toastService: ToastService,
    private buildingService: BuildingService,
    private params: NavParams
  ) { }

  ngOnInit() {
    if (this.modalService.isMobileView) {
      this.fromOffices = this.options.fromOffices;
      this.roomId = this.options.room?.id;
      this.building = this.options.building;
    }
  }

  async deleteRequest() {
    await this.loaderService.presentLoader();
    try {
      if (this.fromOffices) {
        const response = await this.requestService.deleteAuthRequest(`rooms/${this.roomId}`).toPromise();
        this.roomsService.rooms = this.roomsService.rooms.filter(room => room.id !== this.roomId);
      } else {
        const response = await this.requestService.deleteAuthRequest(`buildings/${this.building.id}`).toPromise();
        this.buildingService.buildings = this.buildingService.buildings.filter(building => building.id !== this.building.id);
      }
      this.closeModal(true);
      this.loaderService.dismissLoader();
    } catch (err) {
      this.loaderService.dismissLoader();
      this.openAlertModal(err);
    }
  }

  openAlertModal(err) {
    if (err.message && err.message.includes('removing the room')) {
      if (this.modalService.isMobileView) {
        this.modalService.dismiss();
        import('../../components/alert-modal/alert-modal.component').then(m =>
          this.modalService.presentModal(m.AlertModalComponent, 'auto-height', { fromOffices: this.fromOffices })
        );
      } else {
        this.modalService.closeDesktopModal('DeleteModal');
        this.modalService.openDesktopModal('AlertModal');
      }
    } else {
      this.toastService.presentToast(err.message, 'error');
    }
  }

  closeModal(isToastRequired = false) {
    this.roomId = null;
    this.building = null;

    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
    } else {
      this.modalService.closeDesktopModal('DeleteModal');
    }

    isToastRequired && this.toastService.presentToast(`${this.fromOffices ? 'Office' : 'Building'} successfully deleted.`);
  }

  back() {
    this.modalService.dismiss();

    if (this.options.room) {
      import('../../components/room-modal/room-modal.component').then(m =>
        this.modalService.presentModal(m.RoomModalComponent, 'auto-height', { room: this.options.room })
      )
    } else {
      import('../edit-building-modal/edit-building-modal.component').then(m =>
        this.modalService.presentModal(m.EditBuildingModalComponent, 'auto-height', { building: this.options.building })
      );
    }
  }

  setMargin() {
    if (this.modalService.isMobileView) {
      return this.fromOffices ? '0' : '26px';
    } else {
      return;
    }
  }

}
