import { Component, OnInit } from '@angular/core';

import { NavController } from '@ionic/angular';
import { BuildingService } from '../../services/building/building.service';

import { RoomCreationService } from '../../services/room-creation/room-creation.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  showDropdown = false;
  showNav = false;

  items = [
    { name: 'Notifications', src: '/assets/icon/bell-icon.svg' },
    { name: 'Profile', src: '/assets/icon/profile-icon.svg' },
    { name: 'Switch to Client View', src: '/assets/icon/switch-icon.svg' },
    { name: 'Log out', src: '/assets/icon/log-out-icon.svg' }
  ];

  constructor(
    public roomCreationService: RoomCreationService,
    public navController: NavController,
    public buildingService: BuildingService,
    public auth: AuthService
  ) { }

  ngOnInit() { }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  toogleNavbar() {
    this.showNav = !this.showNav;
  }

  redirectToPage(href) {
    this.navController.navigateForward(href);
    this.showDropdown = false;
    this.showNav = false;
  }

  switchToClientView() {
    this.navController.navigateRoot('search');
    this.showNav = false;
  }

  backToEditProfile() {
    this.navController.navigateForward('edit-profile', { queryParams: { fromAdminPanel: 'true' } });
  }

  backToNotifications() {
    this.navController.navigateForward('notifications', { queryParams: { fromAdminPanel: 'true' } });
  }

  setCorrectMethod(item) {
    switch (item.name) {
      case 'Notifications':
        this.backToNotifications();
        break;
      case 'Profile':
        this.backToEditProfile();
        break;
      case 'Switch to Client View':
        this.switchToClientView();
        break;
      case 'Log out':
        this.logOut();
        break;
    }
  }

  logOut() {
    this.auth.logOut();
    this.navController.navigateRoot('search');
  }

  ngOnDestroy() {
    this.showNav = false;
  }
}