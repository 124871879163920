import { Component, OnInit } from '@angular/core';

import { RequestService } from 'src/app/services/request/request.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ModalService } from 'src/app/services/modal/modal.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Capacitor } from '@capacitor/core';

import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-phone-confirmation-modal',
  templateUrl: './phone-confirmation-modal.component.html',
  styleUrls: ['./phone-confirmation-modal.component.scss'],
})
export class PhoneConfirmationModalComponent implements OnInit {

  public codeForm: UntypedFormGroup;
  public firstNumberControl: UntypedFormControl;
  public secondNumberControl: UntypedFormControl;
  public numberControl: UntypedFormControl;
  public inputs = [];
  autoFilled = false;
  inputElements = [];
  platform = Capacitor.getPlatform();

  constructor(
    private requestService: RequestService,
    public modalService: ModalService,
    public auth: AuthService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private navController: NavController
  ) { }

  ngOnInit() {
    this.inputs.length = 6;

    setTimeout(() => {
      this.inputElements = Array.from(document.querySelectorAll('ion-input input'));
      this.inputElements[0].focus();
    }, 800);
  }

  back() {
    this.modalService.dismiss();

    import('../required-data-modal/required-data-modal.component').then(m => {
      this.modalService.presentModal(m.RequiredDataModalComponent, 'fixed-height');
    });
  }

  onChange(event) {
    if (!event.detail.value) { this.focusPrevElementsOnDelete(event.target); return; }

    if (event.detail.value.length > 6 || event.detail.value.length < 6 && event.detail.value.length !== 1) {
      event.target.value = '';
      this.toastService.presentToast('Please enter valid SMS code.', 'error');
      return;
    }

    this.onAutoFillIos(event.detail.value, this.inputElements);

    this.inputElements.forEach((input: any) => {
      !this.autoFilled && (input.maxLength = 1);
      if (input.value !== event.detail.value) { return; }

      let index = this.inputElements.indexOf(input);
      index++;
      let nextInput = this.inputElements[index] as HTMLInputElement;
      if (!nextInput) { this.focusLastElement(this.inputElements); return; };
      nextInput.focus();
    });
  }

  focusPrevElementsOnDelete(ionInput) {
    let index = this.inputElements.indexOf(ionInput.firstElementChild);
    index--;
    let prevInput = this.inputElements[index] as HTMLInputElement;
    if (!prevInput) { this.inputElements[0].focus(); return; };
    prevInput.focus();
  }

  focusLastElement(inputs) {
    let lastInput = inputs[inputs.length - 1] as HTMLInputElement;
    lastInput.focus();
  }

  onAutoFillIos(inputData, inputs) {
    if (inputData.length === 6) {

      setTimeout(() => {
        const eventNumbers = inputData.split('');

        inputs.forEach((input: any, index) => {
          input.maxLength = 1;
          input.value = eventNumbers[index];
        });
        this.autoFilled = true;
        this.focusLastElement(inputs);
      }, 100);
    }
  }

  disableVerifyButton() {
    this.inputElements = Array.from(document.querySelectorAll('ion-input input'));
    return this.inputElements.some((input: any) => !input.value.length);
  }

  async verifyPhoneNumber() {
    await this.loaderService.presentLoader();
    const phoneVerificationCode = +this.inputElements.map(input => input.value).join('');

    try {
      const response = await this.requestService.postAuthRequest('users/phone-verification', { phoneVerificationCode }).toPromise() as any;
      this.loaderService.dismissLoader();
      this.modalService.dismiss();
      this.toastService.presentToast(response.message);
      this.auth.saveUserLocally({ phoneVerified: true });
      await this.auth.updateUser({ smsNotification: true }, false, true);
      this.checkUserForGroups();
      this.platform === 'web' && this.modalService.openWebPushPermissionsModal();
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  checkUserForGroups() {
    if (!this.auth.user.userGroups.length) { return; }
    const lastGroupUserWasAdded = this.auth.user.userGroups[this.auth.user.userGroups.length - 1];
    this.navController.navigateForward(`group-details/${lastGroupUserWasAdded.groupId}`);
  }

  async resendSms() {
    await this.loaderService.presentLoader();

    try {
      await this.requestService.postAuthRequest('users/resend-phone-verification', {}).toPromise();
      this.inputElements[0].focus();
      this.loaderService.dismissLoader();
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }
}
