import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComponentsModule } from '../../components/components.module';
import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { NavParams } from '@ionic/angular';

import { AdminHeaderComponent } from '../components/admin-header/admin-header.component';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { BuildingCreationModalComponent } from './building-creation-modal/building-creation-modal.component';
import { AllDoneModalComponent } from './all-done-modal/all-done-modal.component'
import { RoomModalComponent } from './room-modal/room-modal.component';
import { DayReservationsComponent } from './day-reservations/day-reservations.component';
import { PromocodeModalComponent } from './promocode-modal/promocode-modal.component';
import { EditBuildingModalComponent } from './edit-building-modal/edit-building-modal.component';
import { BuildingAdminsModalComponent } from './building-admins-modal/building-admins-modal.component';
import { TopRoomsComponent } from './top-rooms/top-rooms.component';
import { MapUsaComponent } from './map-usa/map-usa.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { ReservationModalComponent } from './reservation-modal/reservation-modal.component';
import { CalendarComponent } from './calendar/calendar.component';
import { BuildingFilterSelectComponent } from './building-filter-select/building-filter-select.component';
import { EditBuildingManagerComponent } from './edit-building-manager/edit-building-manager.component'
import { ReservationListModalComponent } from './reservation-list-modal/reservation-list-modal.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { UserDetailsModalComponent } from './user-details-modal/user-details-modal.component';
import { ManagerBuildingAddingMobileComponent } from './manager-building-adding-mobile/manager-building-adding-mobile.component';
import { RoomBuildingDetailsAdminComponent } from './room-building-details-admin/room-building-details-admin.component';
import { AddBuildingManagersMobileComponent } from './add-building-managers-mobile/add-building-managers-mobile.component';
import { OfficesFiltersMobileModalComponent } from './offices-filters-mobile-modal/offices-filters-mobile-modal.component';

// Previous room creation components start

import { ChooseBuildingComponent } from './previous-room-creation/choose-building/choose-building.component';
import { ChooseFacilitiesComponent } from './previous-room-creation/choose-facilities/choose-facilities.component';
import { AddRoomPhotoComponent } from './previous-room-creation/add-room-photo/add-room-photo.component';
import { AddRoomPlansComponent } from './previous-room-creation/add-room-plans/add-room-plans.component';
import { AddRoomDescriptionComponent } from './previous-room-creation/add-room-description/add-room-description.component';
import { AddLockIdComponent } from './previous-room-creation/add-lock-id/add-lock-id.component';
import { AddBuildingPhotoModalComponent } from './add-building-photo-modal/add-building-photo-modal.component';

// Previous room creation components end

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxGoogleMapsModule } from '@codious/ngx-google-maps';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { ChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'ion2-calendar';

@NgModule({
    declarations: [
        AdminHeaderComponent,
        ProgressBarComponent,
        ChooseBuildingComponent,
        ChooseFacilitiesComponent,
        AddRoomPhotoComponent,
        AddRoomPlansComponent,
        AddRoomDescriptionComponent,
        BuildingCreationModalComponent,
        AllDoneModalComponent,
        RoomModalComponent,
        EditBuildingModalComponent,
        BuildingAdminsModalComponent,
        AddBuildingPhotoModalComponent,
        PromocodeModalComponent,
        AddLockIdComponent,
        DonutChartComponent,
        TopRoomsComponent,
        MapUsaComponent,
        LineChartComponent,
        BarChartComponent,
        DayReservationsComponent,
        ReservationModalComponent,
        CalendarComponent,
        BuildingFilterSelectComponent,
        EditBuildingManagerComponent,
        ReservationListModalComponent,
        AlertModalComponent,
        DeleteModalComponent,
        UserDetailsModalComponent,
        ManagerBuildingAddingMobileComponent,
        RoomBuildingDetailsAdminComponent,
        AddBuildingManagersMobileComponent,
        OfficesFiltersMobileModalComponent
    ],
    imports: [
        ComponentsModule,
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        GooglePlaceModule,
        NgxGoogleMapsModule.forRoot({
            key: 'AIzaSyA5U0E0oB0F0d-AhPqlKfmmcLhrR57ngVU',
            language: 'en',
            libraries: 'geometry',
            loadScript: true,
            options: { panControl: true, panControlOptions: { position: 9 } },
            region: 'US'
        }),
        ChartsModule,
        RouterModule,
        IonicModule.forRoot(),
        CalendarModule
    ],
    providers: [DatePipe, NavParams, DecimalPipe],
    exports: [
        AdminHeaderComponent,
        ProgressBarComponent,
        ChooseBuildingComponent,
        ChooseFacilitiesComponent,
        AddRoomPhotoComponent,
        AddRoomPlansComponent,
        AddRoomDescriptionComponent,
        BuildingCreationModalComponent,
        AllDoneModalComponent,
        RoomModalComponent,
        EditBuildingModalComponent,
        BuildingAdminsModalComponent,
        AddBuildingPhotoModalComponent,
        PromocodeModalComponent,
        AddLockIdComponent,
        EditBuildingManagerComponent,
        DonutChartComponent,
        TopRoomsComponent,
        MapUsaComponent,
        LineChartComponent,
        BarChartComponent,
        DayReservationsComponent,
        ReservationModalComponent,
        CalendarComponent,
        BuildingFilterSelectComponent,
        ReservationListModalComponent,
        AlertModalComponent,
        DeleteModalComponent,
        UserDetailsModalComponent,
        ManagerBuildingAddingMobileComponent,
        RoomBuildingDetailsAdminComponent,
        AddBuildingManagersMobileComponent,
        OfficesFiltersMobileModalComponent
    ]
})
export class ComponentsAdminModule { }
