/* eslint-disable max-len */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from '../../services/modal/modal.service';
import { RoomsService } from 'src/app/services/rooms/rooms.service';
import { Subscription } from 'rxjs';
import { ValidatorsService } from '../../services/validators/validators.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {

  @ViewChild('passwordInput') passwordInput: ElementRef;

  errorText;
  userResp;
  type = 'password';
  googleUserInfo;

  private form$: Subscription;

  isUpdated = {
    email: true,
    password: true,
  };

  savedValues = {
    email: '',
    password: '',
  }

  userForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    password: new UntypedFormControl('')
  });

  constructor(
    public formBuilder: UntypedFormBuilder,
    private validatorsService: ValidatorsService,
    private auth: AuthService,
    public modalService: ModalService,
    private roomsService: RoomsService
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  ionViewDidEnter() {
    this.initAppleButton();
  }

  initAppleButton() {
    window['AppleID'].auth.init({
      clientId: 'com.smartofficelogin.ora',
      scope: 'email name',
      redirectURI: 'https://booking.byoraprivateoffices.com',
      state: 'initial',
      usePopup: false
    })
    document.getElementById('appleid-signin').style.pointerEvents = 'none';
    document.querySelector<HTMLElement>('#appleid-signin div').style.maxWidth = 'initial';
  }


  initForm() {
    this.userForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, this.validatorsService.validateEmail()])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20), this.validatorsService.validatePassword()])]
    }, { updateOn: 'change' });
    this.form$ = this.userForm.valueChanges.subscribe(res => {
      for (const property in this.isUpdated) {
        if (this.isUpdated[property] && this.savedValues[property] !== this.userForm.get(property).value) {
          this.isUpdated[property] = false;
        }
      }
    });
  }

  async onSubmit(userData) {
    for (const property in this.userForm.controls) {
      if (this.userForm.controls[property].errors) {
        this.savedValues[property] = userData[property];
        this.userForm.controls[property].markAsDirty();
        this.isUpdated[property] = true;
      }
    }
    if (!this.userForm.invalid) {
      await this.auth.signup(userData, this.modalService, this.roomsService);
      this.modalService.openRequiredDataModal();
    }

  }

  async googleSignup() {
    await this.auth.googleAuth(this.modalService);
    !this.modalService.isAuthModalBeforePayment && await this.roomsService.getAllRooms();
    (this.auth.isDismissRequired || this.modalService.ios) && this.modalService.dismiss();
    this.modalService.openRequiredDataModal();
  }

  async appleSignup() {
    await this.auth.appleAuth(this.modalService);
    !this.modalService.isAuthModalBeforePayment && await this.roomsService.getAllRooms();
    (this.auth.isDismissRequired || this.modalService.ios) && this.modalService.dismiss();
    this.modalService.openRequiredDataModal();
  }

  togglePassword() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.type = this.passwordInput.nativeElement.getAttribute('type') === 'password' ? 'text' : 'password';
    this.passwordInput.nativeElement.setAttribute('type', this.type);
  }

  openModal(modal) {
    this.modalService.dismiss();
    if (modal === 'terms') {
      import('../terms/terms.component').then(m => this.modalService.presentModal(m.TermsComponent, 'fixed-height', 'terms'));
    } else if (modal === 'policy') {
      import('../terms/terms.component').then(m => this.modalService.presentModal(m.TermsComponent, 'fixed-height', 'policy'));
    } else {
      import('../login/login.component').then(m => this.modalService.presentModal(m.LoginComponent, 'fixed-height', 'login'));
    }

  }

  ngOnDestroy(): void {
    this.form$.unsubscribe();
  }
}
