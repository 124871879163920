import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { Keyboard, KeyboardResize, KeyboardInfo } from '@capacitor/keyboard';

import { AuthService } from '../auth/auth.service';
import { LoaderService } from '../loader/loader.service';
import { UnlockOfficeModalComponent } from 'src/app/components/unlock-office-modal/unlock-office-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modal;
  modalOptions;
  ios = false;
  isModalOpened = false;

  showAlertModal = false;
  showDeleteModal = false;

  showBuildingCreationModal = false;
  showRoomCreationModal = false;

  showRoomModal = false;
  showEditBuildingModal = false;

  showPromocodeModal = false;
  showReservationModal = false;
  showEditBuildingManagerModal = false;
  showBuildingAdminsModal = false;
  showAddBuildingManagersModal = false;
  showAddBuildingPhotoModal = false;
  showReservationListModal = false;
  fromEditUser = false;
  isReservationOpenedFromTable = false;

  showAllDoneModal = false;
  isMobileView = false;
  unlockBeenShowed = false;
  isAuthModalBeforePayment = false;
  isConfirmedModalOpened = false;
  isUnlockModalOpenedFromPush = false;

  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private navController: NavController,
    private platform: Platform,
    private loaderService: LoaderService
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('mobileweb')) { this.isMobileView = true; return; }
      if (this.platform.is('ios')) { this.ios = true; this.isMobileView = true; }
    });
  }

  async presentModal(component, cssClass, options = null) {
    this.modal = await this.modalController.create({
      component,
      cssClass,
      showBackdrop: true,
      backdropDismiss: false,
      componentProps: { options }
    });
    options === 'payment' && (this.isAuthModalBeforePayment = true);
    this.modal.onDidDismiss().then((data) => {
      this.isUnlockModalOpenedFromPush = false;
      this.removeListenersFromKeyboard();
      this.openSelectedPage(options);
      this.compareDataFromDismissing(data, options);
      this.loaderService.dismissLoader();
    });
    return await this.modal.present().then((data) => {
      this.setKeyboardResize(options);
    });
  }

  compareDataFromDismissing(data, options) {
    if (options === null) { return; }
    if (typeof options === 'string') { return; }
  }

  openSelectedPage(options) {
    if (options !== null) { this.modalOptions = options; }
    this.authService.user && this.setSelectedPage(this.modalOptions);
  }

  setSelectedPage(pageName) {
    switch (pageName) {
      case 'settings':
        this.navigate('settings');
        break;
      case 'rooms':
        this.navigate('rooms');
        break;
      case 'liked':
        this.navigate('liked');
        break;
      case 'payment':
        this.navigate('payment');
        break;
      case 'notifications':
        this.navigate('notifications');
        break;
    }
  }

  setKeyboardResize(modalName) {
    if (!this.ios) { return; }
    Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
      if (!modalName) { return; }

      if (modalName.building || modalName.isResizeRequired) {
        Keyboard.setResizeMode({ mode: KeyboardResize.Native });
      } else {
        Keyboard.setResizeMode({ mode: KeyboardResize.None });
      }
    });
  }

  removeListenersFromKeyboard() {
    if (!this.ios) { return; }
    Keyboard.removeAllListeners().then(() => {
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    });
  }

  navigate(pageName) {
    this.navController.navigateForward(pageName);
  }

  dismiss() {
    this.modalController.getTop().then(modal => {
      modal && modal.dismiss();
    });
  }

  openDesktopModal(modalName) {
    this['show' + modalName] = true;
    this.isModalOpened = true;
  }

  closeDesktopModal(modalName) {
    this['show' + modalName] = false;
    this.isModalOpened = false;
  }

  // Modal opening if user do not verify phone, first/last name
  openRequiredDataModal() {
    if (!this.authService.user) { return; }
    if (this.authService.isUserDataValid()) { return; }

    import('../../components/required-data-modal/required-data-modal.component').then(m => {
      this.presentModal(m.RequiredDataModalComponent, 'fixed-height');
    });
  }

  openReservationModal(currentBooking, isRefundModalOpen, isCancelModalOpen, calendarOptions, bookingsData = null, selectedDate = null) {
    this.dismiss();
    import('../../admin/components/reservation-modal/reservation-modal.component').then(m =>
      this.presentModal(m.ReservationModalComponent, 'auto-height',
        { currentBooking, isRefundModalOpen, isCancelModalOpen, calendarOptions, bookingsData, selectedDate }
      )
    );
  }

  openWebPushPermissionsModal() {
    import('../../components/allow-web-notifications-modal/allow-web-notifications-modal.component').then(m => {
      this.presentModal(m.AllowWebNotificationsModalComponent, 'web-push-modal');
    });
  }

  presentUnlockOnBookingProcess(bookings) {
    this.unlockBeenShowed = true;
    setTimeout(() => {
      this.presentModal(UnlockOfficeModalComponent, 'auto-height', { bookings, fromUnlockPush: true });
    }, 1000);
  }

  presentDowloadFromAppStorePopup() {
    if (this.ios) { return; }

    if (this.isMobileView) {
      import('../../components/download-from-store-popup/download-from-store-popup.component').then(m => {
        this.presentModal(m.DownloadFromStorePopupComponent, 'download-from-store');
      })
    }
  }
}
