import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { NavController } from '@ionic/angular';

import { AuthService } from '../services/auth/auth.service';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  authErrors = [
    'Email already exists',
    'Use social login',
    'Incorrect email or password',
    'User does not exist'
  ]

  constructor(
    private auth: AuthService,
    private navCtrl: NavController
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          switch (error && error.status) {
            case 500:
              Capacitor.getPlatform() === 'web' && this.navCtrl.navigateRoot('500');
              break;
            case 401:
              this.auth.logOut(false, true);
              this.navCtrl.navigateRoot('search');
          }
          return error.statusText === 'Unauthorized' && !this.authErrors.some(message => message === error.error.message) ? throwError({}) : throwError(error);
        })
      );
  }
}