/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable arrow-body-style */
import { Component, ElementRef, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { NavController } from '@ionic/angular';

import { RequestService } from '../../services/request/request.service';
import { BookingService } from './../../services/booking/booking.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-search-component',
  templateUrl: './search-component.html',
  styleUrls: ['./search-component.scss'],
})
export class SearchComponent implements OnInit, AfterViewInit {

  @ViewChild('searchInput') searchInput;
  @ViewChildren('buildingName') buildingNames: QueryList<ElementRef>;
  @ViewChildren('buildingAddress') buildingAddresses: QueryList<ElementRef>;

  buildings: any = [];
  searchValue;

  constructor(
    public modalController: ModalController,
    private bookingService: BookingService,
    private navController: NavController,
    private requestService: RequestService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchInput.setFocus();
    }, 1000);
  }

  async onKey(value) {
    this.buildings = await this.searchRequest(value);
    this.buildings && this.highlightResults(value);
  }

  async searchRequest(data) {
    const query = '?search=' + data;
    try {
      return await this.requestService.getRequest('buildings' + query).toPromise();
    } catch (e) {
      console.log(e);
      this.toastService.presentToast(e.message, 'error');
      return [];
    }
  }

  highlightResults(value) {
    setTimeout(() => {
      const nameElems: any = this.buildingNames['_results'];
      const addressElems: any = this.buildingAddresses['_results'];
      this.buildings && this.buildings.forEach((building, i) => {
        building.nameElement = nameElems[i].nativeElement;
        building.addressElement = addressElems[i].nativeElement;
        this.highlightElement(value, building.nameElement);
        this.highlightElement(value, building.addressElement);
      });
    }, 0);
  }

  highlightElement(searchString, element) {
    const newElement = element.innerHTML.replace(new RegExp(searchString, 'gi'), match => `<span style="color: #B4B7B3;">${match}</span>`);
    element.innerHTML = newElement;
  }

  chooseBuilding(building) {
    this.bookingService.building = building;
    this.modalController.dismiss({ modal: 'search', direction: 'forward' });
  }

  clearInputValue() {
    this.searchValue = '';
    this.buildings = [];
  }

  back() {
    this.bookingService.reset();
    this.modalController.dismiss();
    this.navController.navigateBack('/search');
  }

}
