import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-initials',
  templateUrl: './avatar-initials.component.html',
  styleUrls: ['./avatar-initials.component.scss'],
})
export class AvatarInitialsComponent implements OnInit {

  @Input() user;
  @Input() size;
  @Input() fontSize;
  @Input() editProfileSize;

  constructor() { }

  ngOnInit() { }

  setUserInitials() {
    if (!this.user) { return '?'; }
    if (this.user.firstName && this.user.lastName) {
      return `${this.user.firstName.split('')[0].toUpperCase()}${this.user.lastName.split('')[0].toUpperCase()}`
    } else if (this.user.firstName && !this.user.lastName) {
      return `${this.user.firstName.split('')[0].toUpperCase()}`
    } else {
      return '?';
    }
  }

  setWrapperSize() {
    return this.editProfileSize
      ? { 'min-width': '100%', 'height': this.editProfileSize + 'px', 'font-size': this.fontSize + 'px', 'border-radius': '6px', 'margin-bottom': '26px' }
      : { 'min-width': this.size + 'px', 'height': this.size + 'px', 'font-size': this.fontSize + 'px' }
  }
}
