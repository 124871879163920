import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NavParams } from '@ionic/angular';

import { ModalService } from '../../../services/modal/modal.service';
import { RequestService } from 'src/app/services/request/request.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

import { ToastService } from 'src/app/services/toast/toast.service';
import { DateService } from 'src/app/services/date/date.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { RoomsService } from 'src/app/services/rooms/rooms.service';
import { BuildingService } from 'src/app/admin/services/building/building.service';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-reservation-modal',
  templateUrl: './reservation-modal.component.html',
  styleUrls: ['./reservation-modal.component.scss'],
})
export class ReservationModalComponent implements OnInit {
  @Input() isDaySelected;
  @Input() currentBooking;
  @Input() calendarOptions;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() onCancelRefund: EventEmitter<any> = new EventEmitter();

  public dateBooking: string;
  public timeBooking: string;
  public roomLockIdControl: UntypedFormControl;
  public amountControl: UntypedFormControl;
  public isCancelModalOpen = false;
  public isRefundModalOpen = false;
  public isShowTimeBooking = false;
  public dateAndTimeBooking: string;
  public closeModal: boolean;
  bookings;
  options = this.params.get('options');

  constructor(
    public modalService: ModalService,
    private requestService: RequestService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private dateService: DateService,
    public bookingService: BookingService,
    public roomsService: RoomsService,
    public buildingService: BuildingService,
    private params: NavParams,
    private events: EventsService
  ) { }

  ngOnInit() {
    this.collectBookingData();
    this.bookings = this.bookingService.roomsWithBookings.flatMap(item => item.bookings);
  }

  collectBookingData() {
    this.getDataFromNavParams();

    if (this.isDaySelected) {
      this.currentBooking = this.bookingService.reservationBookingData;
    };

    const bookingEndArr = this.currentBooking.bookingEnd.split('.');
    this.dateBooking = this.dateService.formatDateForView(this.currentBooking.bookingStart, bookingEndArr[0]);
    this.timeBooking = this.dateService.formatTimeForView(this.currentBooking.bookingStart.slice(0, -1), this.currentBooking.bookingEnd.slice(0, -1));

    if (!this.dateBooking.includes('-')) {
      this.isShowTimeBooking = true;
      this.dateAndTimeBooking = `${this.dateBooking}, ${this.timeBooking}`;
    };

    this.roomLockIdControl = new UntypedFormControl(this.currentBooking.room?.lockId);
    this.amountControl = new UntypedFormControl(this.currentBooking?.totalPrice / 100);
  }

  getDataFromNavParams() {
    if (!this.modalService.isMobileView) { return; }

    this.currentBooking = this.options.currentBooking;
    this.calendarOptions = this.options.calendarOptions;
    this.isRefundModalOpen = this.options.isRefundModalOpen;
    this.isCancelModalOpen = this.options.isCancelModalOpen;
  }

  openRefundCancelModals(isFefund = false, isCancel = false) {
    if (this.modalService.isMobileView) {
      this.modalService.openReservationModal(this.currentBooking, isFefund, isCancel, this.calendarOptions);
    } else {
      this.isRefundModalOpen = isFefund;
      this.isCancelModalOpen = isCancel;
    }
  }

  async cancelRefundReservation() {
    await this.loaderService.presentLoader();

    const currentRequest = this.isCancelModalOpen
      ? this.requestService.patchAuthRequest(`bookings/${this.currentBooking.id}/cancel`, null)
      : this.requestService.postAuthRequest(`bookings/${this.currentBooking.id}/refund`, null);

    try {
      const resp = await currentRequest.toPromise() as { success: boolean, message: string };
      this.loaderService.dismissLoader();
      this.toastService.presentToast(resp.message);
      this.isCancelModalOpen ? this.updateLocalBookingsAfterCancel() : this.updateBookingsAfterRefund();
      this.modalService.isMobileView ? this.modalService.dismiss() : this.modalService.closeDesktopModal('ReservationModal');
    } catch (err) {
      console.log(err);
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  updateLocalBookingsAfterCancel() {

    this.bookings = this.bookings.filter(booking => {
      return booking.extendedProps.bookingToShow
        ? !this.isBookingEqualsCurrent(booking.extendedProps.booking.id)
        : !this.isBookingEqualsCurrent(booking.extendedProps.booking.id)
    });

    this.bookingService.roomsWithBookings = this.removeCanceledBookings(this.bookingService.roomsWithBookings, this.bookings);
    this.calendarOptions.events = this.bookings;
    this.modalService.isMobileView ? this.events.onCloseCancelRefundAdminModal() : this.onCancelRefund.emit();
  }

  isBookingEqualsCurrent(bookingId) {
    return bookingId === this.currentBooking.id;
  }

  removeCanceledBookings(roomsWithBookings, bookings) {
    return roomsWithBookings.map(item => {
      return {
        room: item.room,
        bookings: bookings.filter(booking => booking.extendedProps.room.id === item.room.id)
      }
    });
  }

  updateBookingsAfterRefund() {
    const bookingToRefund = this.bookings.find(booking => booking.extendedProps.bookingToShow
      ? this.isBookingEqualsCurrent(booking.extendedProps.booking.id)
      : this.isBookingEqualsCurrent(booking.extendedProps.booking.id)
    );

    if (!bookingToRefund) { return; }

    bookingToRefund.extendedProps.booking.refunded = true;

    if (bookingToRefund.extendedProps.bookingToShow) {
      const bookingToUpdate = JSON.parse(bookingToRefund.extendedProps.bookingToShow.booking);
      bookingToUpdate.refunded = true;
      bookingToRefund.extendedProps.bookingToShow.booking = JSON.stringify(bookingToUpdate);
    }

    this.bookingService.roomsWithBookings = this.setRefundedBookings(this.bookingService.roomsWithBookings, this.bookings);
    this.calendarOptions.events = this.bookings;
    this.modalService.isMobileView ? this.events.onCloseCancelRefundAdminModal() : this.onCancelRefund.emit();
  }

  setRefundedBookings(roomsWithBookings, bookings) {
    return roomsWithBookings.map(item => {
      return {
        room: item.room,
        bookings: bookings.filter(booking => booking.extendedProps.room.id === item.room.id)
      }
    });
  }

  backModal() {
    this.isCancelModalOpen = false;
    this.isRefundModalOpen = false;
    this.modalService.closeDesktopModal('ReservationModal');
    this.modalService.openDesktopModal('ReservationModal');
  }

  backDetailsList() {
    this.modalService.closeDesktopModal('ReservationModal');
    if (this.modalService.isReservationOpenedFromTable) { this.modalService.isReservationOpenedFromTable = false; return; }
    this.modalService.openDesktopModal('ReservationListModal');
  }

  getCoverImage(bookingData, type: string) {
    if (type === 'cover') {
      return 'url(' + bookingData.room?.coverUrl.medium + ')';
    } else {
      return 'url(' + bookingData.user?.pictures.small + ')';
    };
  }

  back(isBackFromFooter = false) {
    if (isBackFromFooter) { this.modalService.dismiss(); return; }

    if (this.options.selectedDate) {
      this.modalService.dismiss();
      import('../../components/reservation-list-modal/reservation-list-modal.component').then(m =>
        this.modalService.presentModal(m.ReservationListModalComponent, 'auto-height',
          { bookingsData: this.options.bookingsData, selectedDate: this.options.selectedDate, calendarOptions: this.calendarOptions }
        )
      );
    } else {
      this.modalService.dismiss();
    }
  }

  backFromCancelRefund() {
    this.modalService.openReservationModal(this.currentBooking, false, false, this.calendarOptions);
  }
}


