import { Component, OnInit } from '@angular/core';

// import { StepSelectionService } from '../../../services/step-selection/step-selection.service';
// import { RoomCreationService } from '../../../services/room-creation/room-creation.service';

@Component({
  selector: 'app-add-room-plans',
  templateUrl: './add-room-plans.component.html',
  styleUrls: ['./add-room-plans.component.scss'],
})
export class AddRoomPlansComponent implements OnInit {

  // status;
  // planPictures = [];
  // file;

  constructor(
    // public stepSelectionService: StepSelectionService,
    // public roomCreationService: RoomCreationService
  ) { }

  ngOnInit() { }

  // convertPictureFromInput(event) {
  //   if (this.roomCreationService.localPlanPictures.length) { return; }

  //   this.file = event.target.files[0];
  //   if (!this.file.type.match(/image/)) { return; }
  //   const reader = new FileReader();
  //   reader.onload = (ev) => {
  //     this.roomCreationService.putPicturesLocally(this.roomCreationService.localPlanPictures, reader.result, this.file.name);
  //     this.roomCreationService.putFiles(this.roomCreationService.images, { file: this.file, type: 'plan' });
  //     this.setWrapperStatus(false);
  //   }
  //   reader.readAsDataURL(this.file);
  // }

  // setWrapperStatus(status) {
  //   this.status = status;
  // }
}
