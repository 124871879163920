import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ModalService } from '../../../services/modal/modal.service';
import { LockService } from '../../../services/lock/lock.service';
import { DateService } from '../../../services/date/date.service';
import { BuildingService } from '../../services/building/building.service';
import { RequestService } from 'src/app/services/request/request.service';
import { PaginationService } from '../../../services/pagination/pagination.service';

@Component({
  selector: 'app-room-building-details-admin',
  templateUrl: './room-building-details-admin.component.html',
  styleUrls: ['./room-building-details-admin.component.scss'],
})
export class RoomBuildingDetailsAdminComponent implements OnInit {

  room;
  building;
  promocode;
  isRoomDetails;
  emptyAvatar = '/assets/icon/empty-avatar.svg';

  labels = [];

  constructor(
    private params: NavParams,
    private _decimalPipe: DecimalPipe,
    public modalService: ModalService,
    public lockService: LockService,
    private dateService: DateService,
    public buildingService: BuildingService,
    private requestService: RequestService,
    private pagination: PaginationService
  ) { }

  async ngOnInit() {
    const options = this.params.get('options');
    this.room = options.room;
    this.isRoomDetails = options.isRoomDetails;
    this.building = options.building;
    this.promocode = options.promocode;
    this.createLabels();

    if (options.buidingPaginationExists) { return; }
    this.pagination.buildingsPaginationState = {
      currentPage: this.pagination.currentPagination.currentPage,
      totalPages: this.pagination.currentPagination.totalPages
    };
  }

  createLabels() {
    this.labels = [
      { name: 'Address:', value: this.setAdress(), enabled: true },
      { name: 'Zip code:', value: this.building?.zipcode, enabled: !this.isRoomDetails && !this.promocode },
      { name: 'Time zone:', value: this.dateService.setTimeZoneName(this.building?.timeZone), enabled: !this.isRoomDetails && !this.promocode },
      { name: 'Building:', value: this.room?.building.name, enabled: this.isRoomDetails },
      { name: 'Rating:', value: this.room?.rating ? this.room.rating : '-', enabled: this.isRoomDetails },
      { name: 'Price:', value: `$${this._decimalPipe.transform(this.room?.price / 100, '1.2-2')}`, enabled: this.isRoomDetails },
      { name: '# of people:', value: this.room?.chair, enabled: this.isRoomDetails },
      { name: 'Window:', value: this.room?.facilities?.filter(facility => facility.description === 'Windows').length ? 'Yes' : 'No', enabled: this.isRoomDetails },
      { name: 'Discount:', value: this.setDiscountText(), enabled: this.promocode },
      { name: 'Valid from:', value: this.promocode?.formattedValidFrom, enabled: this.promocode },
      { name: 'Valid to:', value: this.promocode?.formattedValidTo, enabled: this.promocode },
      { name: 'Times used:', value: this.promocode?.timesUsed, enabled: this.promocode },
      { name: 'Promo code:', value: this.promocode?.coupon, enabled: this.promocode }
    ].filter(label => label.enabled);
  }

  openEdit() {
    this.modalService.dismiss();

    if (this.promocode) {
      import('../../components/promocode-modal/promocode-modal.component').then(m =>
        this.modalService.presentModal(m.PromocodeModalComponent, 'auto-height', { promocode: this.promocode, isOpenEditPromocodeModal: true, isResizeRequired: true })
      );
      return;
    }

    if (this.isRoomDetails) {
      import('../room-modal/room-modal.component').then(m =>
        this.modalService.presentModal(m.RoomModalComponent, 'auto-height', { isRoomCreation: false, room: this.room })
      );
    } else {
      import('../edit-building-modal/edit-building-modal.component').then(m =>
        this.modalService.presentModal(m.EditBuildingModalComponent, 'auto-height', { building: this.building })
      );
    }
  }

  setDiscountText() {
    return this.promocode?.amountOff
      ? `${this.promocode?.amountOff / 100}$`
      : (this.promocode?.percentOff ? `${this.promocode?.percentOff}%` : (this.promocode?.hoursOff && `${this.promocode?.hoursOff}h`))
  }

  setAdress() {
    return this.isRoomDetails
      ? `${this.room.building.streetNumber} ${this.room.building.streetName}, ${this.room.building.city.name}`
      : `${this.building.streetNumber} ${this.building.streetName}, ${this.building.city.name}`
  }

  setTextDueAdmins(buildingAdmins) {
    if (buildingAdmins.length > 3) {
      return `${buildingAdmins.map(admin => `${admin?.user?.firstName} ${admin?.user?.lastName}, `).slice(0, 3).join('')}and ${buildingAdmins.length - 3} more.`;
    } else if (buildingAdmins.length === 1) {
      return buildingAdmins[0]?.user?.firstName + ' ' + buildingAdmins[0]?.user?.lastName;
    } else if (buildingAdmins.length <= 3) {
      return buildingAdmins.map(admin => `${admin?.user?.firstName} ${admin?.user?.lastName}, `).join('').replace(/(\s*,?\s*)*$/, '');
    } else {
      return '';
    }
  }

  openBuildingAdminsModal(building) {
    this.modalService.dismiss();
    import('../building-admins-modal/building-admins-modal.component').then(m =>
      this.modalService.presentModal(m.BuildingAdminsModalComponent, 'auto-height', { building })
    );
  }

  openAddManager(building) {
    this.modalService.dismiss();
    import('../add-building-managers-mobile/add-building-managers-mobile.component').then(m =>
      this.modalService.presentModal(m.AddBuildingManagersMobileComponent, 'auto-height', { building, isReturnModalDetails: true })
    );
  }

  async onBuildingStatusChange(value: boolean, currentBuilding) {
    try {
      const resp = await this.requestService.patchAuthRequest(`buildings/${currentBuilding.id}`, { published: value }).toPromise();
      resp && this.buildingService.setElemToStartOrEndOfList(value, currentBuilding);
    } catch (err) {
      this.buildingService.buildings.find(building => building.id === currentBuilding.id).published = true;
      this.openAlert();
    }
  }

  openAlert() {
    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      import('../../components/alert-modal/alert-modal.component').then(m =>
        this.modalService.presentModal(m.AlertModalComponent, 'auto-height', { fromOffices: false })
      );
    } else {
      this.modalService.openDesktopModal('AlertModal');
    }
  }

  close() {
    this.modalService.dismiss();
    if (this.isRoomDetails) { return; }
    this.pagination.currentPagination.currentPage = this.pagination.buildingsPaginationState.currentPage;
    this.pagination.currentPagination.totalPages = this.pagination.buildingsPaginationState.totalPages;
    this.pagination.currentPagination.isBuildingsSelected = true;
  }

}
