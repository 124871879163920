import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  pushEnabledSubject$ = new Subject<boolean>();
  roomIdSubject$ = new Subject<any>();
  closeChange$ = new Subject<any>();
  closeGroupChange$ = new Subject<any>();
  openDeleteSubject$ = new Subject<any>();
  closePromoToast$ = new Subject<any>();
  filterChange$ = new Subject<any>();
  cancelRefundChange$ = new Subject<any>();
  bookingDetectionInterval$: BehaviorSubject<number>;

  constructor() { }

  publishRoomId(data) {
    this.roomIdSubject$.next(data);
  }

  getRoomIdSubject(): Subject<number> {
    return this.roomIdSubject$;
  }

  onCloseModal(data) {
    this.closeChange$.next(data);
  }

  onCloseGroupModal() {
    this.closeGroupChange$.next();
  }

  onCloseCancelRefundAdminModal() {
    this.cancelRefundChange$.next();
  }

  onFilterChange(data) {
    this.filterChange$.next(data);
  }

  filterOb$() {
    return this.filterChange$.asObservable();
  }

  closeOb$() {
    return this.closeChange$.asObservable();
  }

  closeGroupOb$() {
    return this.closeGroupChange$.asObservable();
  }

  closeCancelRefundAdminOb$() {
    return this.cancelRefundChange$.asObservable();
  }

  onOpenDelete(data) {
    this.openDeleteSubject$.next(data);
  }

  deleteObs$() {
    return this.openDeleteSubject$.asObservable();
  }

  promoToastObs$() {
    return this.closePromoToast$.asObservable();
  }

  onToastClick() {
    this.closePromoToast$.next();
  }

}
