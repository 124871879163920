import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { PushNotificationService } from '../push-notification/push-notification.service';
import { AuthService } from '../auth/auth.service';

import { Plugins } from '@capacitor/core';
const { NativeMarket } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController,
    private push: PushNotificationService,
    private auth: AuthService
  ) { }

  async openAlertMessageIOS() {
    if (this.push.platform === 'web') {
      await this.push.onNativeSettingsChange(this.auth.user);
      return;
    }

    const alert = await this.alertCtrl.create({
      cssClass: '',
      header: '"Smart office" Would Like to Send You Notifications',
      message: 'Notifications may include alerts, sounds and icon badges. These can be configured in Settings ',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.push.isPushEnabled = false;
          }
        }, {
          text: 'Go to Settings',
          handler: async () => {
            await NativeSettings.openIOS({
              option: IOSSettings.App,
            });
            this.push.isPushEnabled = false;
          }
        }
      ]
    });

    await alert.present();
  }

  async presentVersionAlert(header, message, buttonText = '', allowClose = false) {
    const buttons = [];

    buttons.push({ text: buttonText && buttonText, handler: () => { this.openAppstore() } });

    allowClose && buttons.push({ text: 'Close', role: 'cancel' });

    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons,
      backdropDismiss: allowClose
    });

    await alert.present();
  }

  async openAppstore() {
    await NativeMarket.openStoreListing({ appId: '1575772390' });
  }
}
