import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { BuildingService } from '../../services/building/building.service';
import { ModalService } from '../../../services/modal/modal.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { ToastService } from '../../../services/toast/toast.service';
import { RequestService } from 'src/app/services/request/request.service';
import { EventsService } from 'src/app/services/events/events.service';

import { ModalController } from '@ionic/angular';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-manager-building-adding-mobile',
  templateUrl: './manager-building-adding-mobile.component.html',
  styleUrls: ['./manager-building-adding-mobile.component.scss'],
})
export class ManagerBuildingAddingMobileComponent implements OnInit {

  userAvatar = '/assets/icon/empty-avatar.svg';
  user = this.navParams.get('options').user;

  constructor(
    private navParams: NavParams,
    public buildingService: BuildingService,
    private modalService: ModalService,
    private modalController: ModalController,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private requestService: RequestService,
    private events: EventsService
  ) { }

  async ngOnInit() {
    await this.buildingService.getBuildingNamesList();
    this.filterListDueAdminBuildings();
  }

  filterListDueAdminBuildings() {
    this.user.buildings.forEach(b => {
      this.buildingService.buildingListNames = this.buildingService.buildingListNames.filter(building => building.id !== b.buildingId);
    });
  }

  back() {
    this.modalController.dismiss();
    this.buildingService.showBuildingList = false;
    import('../../components/edit-building-manager/edit-building-manager.component').then(m =>
      this.modalService.presentModal(m.EditBuildingManagerComponent, 'auto-height', { user: this.user })
    );
  }

  async saveChanges() {
    if (this.buildingService.chosenBuilding && this.buildingService.buildingListNames.length) {
      const buildingToAdd = this.buildingService.buildingListNames.find(building => {
        return building.name.trim() === this.buildingService.chosenBuilding;
      });
      buildingToAdd && await this.updateAdmins(buildingToAdd.id);
    } else {
      return;
    }
  }

  async updateAdmins(buildingId) {
    await this.loaderService.presentLoader();
    const body = [{ userId: this.user.id, buildingId }];

    if (this.user.adminsToDelete.length) {
      try {
        await forkJoin([this.deleteAdminsRequest(), this.addAdminsRequest(body)]).toPromise();
        this.toastService.presentToast('Building updated successfully.');
        this.loaderService.dismissLoader();
        this.closeModalClearState();
      } catch (err) {
        this.loaderService.dismissLoader();
        this.toastService.presentToast(err.message, 'error');
      }
    } else {
      await this.addBuildingAdmins(body);
    }
  }

  async addBuildingAdmins(body) {

    try {
      await this.addAdminsRequest(body).toPromise();
      this.toastService.presentToast('Building added successfully.');
      this.loaderService.dismissLoader();
      this.closeModalClearState();
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  closeModalClearState() {
    this.buildingService.chosenBuilding = null;
    this.user.adminsToDelete = [];
    this.buildingService.buildingListNames = [];
    this.events.onCloseModal(true);
    this.modalController.dismiss();
  }

  deleteAdminsRequest() {
    return this.requestService.deleteAuthRequest('building-admins', this.user.adminsToDelete);
  }

  addAdminsRequest(body) {
    return this.requestService.postAuthRequest('building-admins', body);
  }

}
