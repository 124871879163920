import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-top-rooms',
  templateUrl: './top-rooms.component.html',
  styleUrls: ['./top-rooms.component.scss'],
})
export class TopRoomsComponent implements OnInit {

  @ViewChildren('linkElem') linkElemlist;

  @Input() public topRoomsList: {
    imageUrl: string;
    name: string,
    address: string,
    amount: number,
    hours: number,
    roomId: number
  }[] = [];

  constructor(
    private navCtrl: NavController,
    public modalService: ModalService
  ) { }

  ngOnInit() { }

  openRoom(roomId) {
    if (!this.modalService.ios) {
      const link = this.linkElemlist._results.find(elem => +elem.nativeElement.id === roomId).nativeElement;
      link.href = `/room-details/${link.id}`;
      link.target = '_blank';
    } else {
      this.navCtrl.navigateForward([`/room-details/${roomId}`], { queryParams: { fromStatsPage: true } });
    }
  }

}
