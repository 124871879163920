import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NavController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {

  @Input() errorText;
  @Input() fromOffices;
  options = this.params.get('options');

  constructor(
    public modalService: ModalService,
    private navCtrl: NavController,
    private params: NavParams
  ) { }

  ngOnInit() {
    if (this.modalService.isMobileView) {
      this.fromOffices = this.options.fromOffices;
    }
  }

  closeModal() {
    this.modalService.isMobileView ? this.modalService.dismiss() : this.modalService.closeDesktopModal('AlertModal');
  }

  openReservationsPage() {
    this.modalService.isMobileView ? this.modalService.dismiss() : this.modalService.closeDesktopModal('AlertModal');
    this.navCtrl.navigateForward('admin/reservations');
  }

}
