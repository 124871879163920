import { Injectable } from '@angular/core';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {

  addressResponse;

  options = {
    types: ['address'],
    componentRestrictions: { country: ['us'] }
  } as unknown as Options;

  buildingData = {
    streetName: '',
    streetNumber: 0,
    zipcode: 0,
    cityName: '',
    stateAbbreviation: '',
    stateName: '',
    name: '',
    latitude: '',
    longitude: '',
    countryId: 1,
    timeZone: '',
    published: true
  };

  constructor() { }

  handleAddressChange(event) {
    this.addressResponse = event;
    this.buildingData.latitude = this.addressResponse.geometry.location.lat().toString();
    this.buildingData.longitude = this.addressResponse.geometry.location.lng().toString();
    this.getDataFromAddressComponents();
  }

  getDataFromAddressComponents() {
    for (let component of this.addressResponse.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          this.buildingData.streetNumber = parseInt(component.long_name, 10);
          break;
        }
        case 'route': {
          this.buildingData.streetName = component.long_name;
          break;
        }
        case 'locality': {
          this.buildingData.cityName = component.long_name;
          break;
        }
        case 'postal_code': {
          this.buildingData.zipcode = parseInt(component.long_name, 10);
          break;
        }
        case 'administrative_area_level_1': {
          this.buildingData.stateName = component.long_name;
          this.buildingData.stateAbbreviation = component.short_name;
          break;
        }
      }
    }
  }
}
