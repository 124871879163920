import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ora-header',
  templateUrl: './ora-header.component.html',
  styleUrls: ['./ora-header.component.scss'],
})
export class OraHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
