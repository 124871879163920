import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectService {

  constructor() { }

  togglePerOne(allSelected, isControlsEqualsServiceItems) {
    if (allSelected.selected) { allSelected.deselect(); return false; }
    isControlsEqualsServiceItems && allSelected.select();
  }

  toggleAllSelection(allSelected, controls, dataToPatch, isGroups = false, groupsToBooking?, searchInput?, isFromBookingConfirmed = false) {
    if (allSelected.selected) {
      searchInput && (searchInput.value = '');
      dataToPatch = isGroups ? dataToPatch.filter(val => !val.disabled) : dataToPatch;
      controls.patchValue([...dataToPatch.map(item => item.id), 0]);
    } else {
      
      if (isFromBookingConfirmed) {
        controls.patchValue([...dataToPatch.filter(val => val.disabled).map(item => item.id), 0]);
        allSelected.deselect();
        return;
      }

      controls.patchValue([]);
      isGroups && (groupsToBooking = controls.value);
    }
  }
}
