import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../services/modal/modal.service';
import { EventsService } from '../../../services/events/events.service';

@Component({
  selector: 'app-all-done-modal',
  templateUrl: './all-done-modal.component.html',
  styleUrls: ['./all-done-modal.component.scss'],
})
export class AllDoneModalComponent implements OnInit {

  @Output() close = new EventEmitter();

  constructor(
    private modalService: ModalService,
    private events: EventsService
  ) { }

  ngOnInit() { }

  closeModal() {
    if (this.modalService.isMobileView) {
      this.events.onCloseModal(true);
      this.modalService.dismiss();
    } else {
      this.close.emit();
      this.modalService.closeDesktopModal('AllDoneModal');
    }
  }

}
