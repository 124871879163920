import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';

import { RequestService } from '../../../services/request/request.service';
import { RoomCreationService } from '../room-creation/room-creation.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from '../../../services/auth/auth.service';
import { PaginationService } from 'src/app/services/pagination/pagination.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  showBuildingList = false;
  buildings = [] as any;
  chosenBuilding;
  buildingId;
  buildingAdminRooms;
  firstBuilding;

  idsToPreSelect;
  filterApplied = false;

  activeRoomsFilterValue;
  showManagersList = false;
  buildingListNames = [] as any;
  users = [];
  admins = [];
  managersToAdd;
  usersToShow = [];

  constructor(
    private requestService: RequestService,
    private roomCreationService: RoomCreationService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private auth: AuthService,
    private clipboard: Clipboard,
    private paginationService: PaginationService
  ) { }

  toggleIcon() {
    this.showBuildingList = !this.showBuildingList;
  }

  async getBuildings(nextPage?: number) {
    try {
      await this.requestService.getAuthRequest('admin/buildings', this.paginationService.currentPagination.currentPage).pipe(
        tap((resp: { currentPage: number, totalCount: number, totalPages: number, buildings: [] }) => {
          this.buildings = nextPage === 1 ? resp.buildings : [...this.buildings, ...resp.buildings];
          this.paginationService.currentPagination.currentPage = resp.currentPage;
          this.paginationService.currentPagination.totalPages = resp.totalPages;
          this.paginationService.currentPagination.currentPage++;

          this.paginationService.currentPagination.event?.complete();
          this.loaderService.dismissLoader();
        })
      ).toPromise();

      return this.buildings;
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      console.log(err);
    }
  }

  isBuildingAdmin() {
    return this.auth.user.buildingAdmins.length && !this.requestService.isAdmin ? true : false;
  }

  async getBuildingAdminData(isFromReservations = false) {
    let buildingRequests = [];
    buildingRequests = this.auth.user.buildingAdmins.map(admin => this.requestService.getAuthRequest(`admin/buildings/${admin.buildingId}`));
    await this.getBuildingsById(buildingRequests, isFromReservations);
  }

  async getBuildingsById(requests, isFromReservations) {
    !isFromReservations && await this.loaderService.presentLoader();

    try {
      this.buildings = this.requestService.isAdmin ? await this.requestService.getAuthRequest('admin/buildings').toPromise() : await forkJoin(requests).toPromise();
      this.firstBuilding = this.buildings[0];
      this.filterBuildingAdminRooms(this.buildings);
      !isFromReservations && this.loaderService.dismissLoader();
    } catch (err) {
      console.log(err);
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  filterBuildingAdminRooms(buildings) {
    this.buildingAdminRooms = buildings.flatMap((data: any) => data.rooms);
    buildings.forEach((building: any) => delete building.rooms);

    this.buildingAdminRooms.forEach(room => room.building = buildings.find((building: any) => room.buildingId === building.id));
  }

  async getBuildingAdmins(nextPage?: number, event?, isLoaderRequired = false) {
    if (isLoaderRequired) { await this.loaderService.presentLoader(); };

    try {
      await this.requestService.getAuthRequest('building-admins', nextPage).pipe(
        tap((resp: { currentPage: number, totalCount: number, totalPages: number, users }) => {
          resp.users.forEach(user => user.buildings = user.buildingAdmins);
          this.admins = nextPage === 1 ? resp.users : [...this.admins, ...resp.users];
          this.paginationService.currentPagination.currentPage = resp.currentPage;
          this.paginationService.currentPagination.totalPages = resp.totalPages;
          this.paginationService.currentPagination.currentPage++;
          event && event.target.complete();
          this.loaderService.dismissLoader();
        })
      ).toPromise();

      return this.admins;
    } catch (err) {
      console.log(err)
      this.loaderService.dismissLoader();
      return null;
    }
  }

  async createBuildingAdmin(userId, buildingId) {
    const body = { userId, buildingId };

    try {
      const response = await this.requestService.postAuthRequest('building-admins', body).toPromise();
    } catch (err) {
      console.log(err);
    }
  }

  async deleteBuildingAdmin(buildingId, userId) {
    try {
      const response = await this.requestService.deleteAuthRequest(`admin/buildings/${buildingId}/building-admins/${userId}`).toPromise();
    } catch (err) {
      console.log(err)
    }
  }

  setElemToStartOrEndOfList(isBuildingActivated, currentBuilding) {
    if (isBuildingActivated) {
      const notPublished = this.buildings.filter(building => !building.published);
      const sortedPublished = this.buildings.filter(building => building.published).sort((a, b) => b.id - a.id);
      this.buildings = sortedPublished.concat(notPublished);
    } else {
      const splicedElement = this.buildings.splice(this.buildings.indexOf(currentBuilding), 1)[0];
      this.buildings.push(splicedElement);
    }
  }

  getChosenBuilding(event, type) {
    this.chosenBuilding = event.target.innerText;
    const buildingId = parseInt(event.target.id, 10);
    this.checkModalType(type, buildingId);
    this.showBuildingList = !this.showBuildingList;
  }

  async onKey(value, building = null) {
    this.showManagersList = true;
    this.users = await this.searchRequest(value, building) as any;
    this.filterListOfUsersAfterSelections();
  }

  filterListOfUsersAfterSelections() {
    if (this.users && this.managersToAdd) {
      this.users = this.users.filter(el => {
        return !this.managersToAdd.find(element => {
          return element.id === el.id;
        });
      });
    } else {
      return;
    }
  }

  async searchRequest(data, building = null, nextPage?: number, event?) {
    if (!data) { this.showManagersList = false; return; }
    if (!nextPage) { this.paginationService.resetPagination(); this.usersToShow = []; }

    const query = building ? '/SEARCH?search=' + data + `&buildingId=${building.id}` : '/SEARCH?search=' + data;
    try {
      await this.requestService.getAuthRequest('admin/users' + query, this.paginationService.currentPagination.currentPage).pipe(
        tap((resp: { currentPage: number, totalCount: number, totalPages: number, users: [] }) => {
          this.usersToShow = nextPage === 1 ? resp.users : [...this.usersToShow, ...resp.users];
          this.paginationService.currentPagination.currentPage = resp.currentPage;
          this.paginationService.currentPagination.totalPages = resp.totalPages;
          this.paginationService.currentPagination.currentPage++;
          event && event.target.complete();
        })
      ).toPromise();
      return this.usersToShow;
    } catch (e) {
      console.log(e);
      this.toastService.presentToast(e.message, 'error');
      return [];
    }
  }

  async loadUsersByBuilding(event, inputValue, building) {
    if (this.paginationService.currentPagination.currentPage <= this.paginationService.currentPagination.totalPages) {
      this.users = await this.searchRequest(inputValue, building, this.paginationService.currentPagination.currentPage, event);
    } else {
      event.target.complete();
    };
  }

  clearManagersState() {
    this.managersToAdd = [];
    this.users = [];
    this.showManagersList = false;
    this.usersToShow = [];
  }

  async filterRequest(query, start = null, end = null, isReservations = false, isOfficesScreen = false, isUsersPage = false) {

    await this.loaderService.presentLoader();

    if (isOfficesScreen) {
      query = this.activeRoomsFilterValue
        ? `admin/rooms?filter=${this.activeRoomsFilterValue}&${query}page=${this.paginationService.currentPagination.currentPage}`
        : `admin/rooms?${query}page=${this.paginationService.currentPagination.currentPage}`
    } else if (isUsersPage) {
      this.paginationService.currentPagination.currentPage = this.paginationService.defaultPagination.currentPage;
      query = `admin/clients?${query}page=${this.paginationService.currentPagination.currentPage}`;
    } else {
      query = !isReservations ? (start && end) ? `stats?${query}start=${start}&end=${end}` : `stats?${query}` : `admin/bookings?${query}`;
    }

    try {
      const response = await this.requestService.getAuthRequest(query).toPromise() as any;
      this.loaderService.dismissLoader();
      return isOfficesScreen ? response.rooms : (isUsersPage ? response.users : response);
    } catch (err) {
      console.log(err)
      this.loaderService.dismissLoader();
      return null;
    }
  }

  checkModalType(type, buildingId) {
    this.buildingId = buildingId;
    if (type === 'create') {
      this.roomCreationService.roomData.buildingId = buildingId;
    }
  }

  clearState() {
    this.chosenBuilding = '';
    this.showBuildingList = false;
  }

  async updateBuilding(buildingId, data) {
    try {
      const response = await this.requestService.patchAuthRequest(`buildings/${buildingId}`, data).toPromise();
      this.loaderService.dismissLoader();
      return response;
    } catch (err) {
      this.loaderService.dismissLoader();
      return null;
    }
  }

  async getBuildingNamesList() {
    try {
      this.buildingListNames = await this.requestService.getAuthRequest('admin/buildings/list').toPromise();
      this.loaderService.dismissLoader();
      return this.buildingListNames;
    } catch (err) {
      this.loaderService.dismissLoader();
      return null;
    }
  }

  copyToClipboard(promocode: string) {
    const isCopied = promocode && this.clipboard.copy(promocode);
    isCopied && this.toastService.presentToast('Copied to clipboard');
  }
}
