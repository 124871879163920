import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BuildingService } from '../../admin/services/building/building.service';
import { InputSearchService } from 'src/app/services/input-search/input-search.service';

@Component({
  selector: 'app-buildings-dropdown',
  templateUrl: './buildings-dropdown.component.html',
  styleUrls: ['./buildings-dropdown.component.scss'],
})
export class BuildingsDropdownComponent implements OnInit, OnDestroy {

  @Input() isCouponModal;
  @Input() isRoomCreation;
  @Input() room;
  @Input() isAbsoluteStyles;
  @Input() isCouponEdition;
  @Input() couponBuilding;

  buildingsToShow;

  constructor(
    public buildingService: BuildingService,
    public inputSearch: InputSearchService
  ) { }

  ngOnInit() { }

  setLocationText() {
    if (this.buildingService.chosenBuilding) { return this.buildingService.chosenBuilding; }

    if (this.isCouponModal) {
      return !this.isCouponEdition ? 'Choose location' : this.couponBuilding;
    } else {
      return this.isRoomCreation ? 'Choose location' : this.room.building.name;
    }
  }

  setClassForChooseLocationText() {
    if (this.buildingService.chosenBuilding) { return 'black-text'; }

    if (this.isCouponModal) {
      return !this.isCouponEdition ? 'gray-text' : 'black-text';
    } else {
      return this.isRoomCreation ? 'gray-text' : 'black-text';
    }
  }

  ngOnDestroy() {
    this.buildingService.showBuildingList = false;
    this.buildingService.chosenBuilding = null;
    this.buildingService.buildingId = null;
  }

}
