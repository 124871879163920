import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal/modal.service';
import { GroupService } from 'src/app/services/group/group.service';

@Component({
  selector: 'app-group-notification-modal',
  templateUrl: './group-notification-modal.component.html',
  styleUrls: ['./group-notification-modal.component.scss'],
})
export class GroupNotificationModalComponent implements OnInit {

  constructor(
    private modalService: ModalService,
    public groupService: GroupService
  ) { }

  async ngOnInit() {
    this.groupService.groups = await this.groupService.getGroups();
  }

  back() {
    this.modalService.dismiss();
    if (this.groupService.fromRoomsPage) { return; }
    import('../../../booking-confirmed/booking-confirmed.component').then(m =>
      this.modalService.presentModal(m.BookingConfirmedComponent, 'auto-height notifications')
    );
  }

  openGroupNameCreationModal(isGroupExists: boolean = false) {
    !isGroupExists && (this.groupService.isAddBookingToGroupCreationFlow = true);
    this.modalService.dismiss();
    import('../group-name-adding-modal/group-name-adding-modal.component').then(m =>
      this.modalService.presentModal(m.GroupNameAddingModalComponent, 'auto-height notifications', { isGroupExists, isResizeRequired: true })
    );
  }

}
