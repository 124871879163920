import { Component, Input, OnInit } from '@angular/core';
import { BookingService } from 'src/app/services/booking/booking.service';
import { ModalService } from 'src/app/services/modal/modal.service';

import { DateService } from '../../../services/date/date.service';

@Component({
  selector: 'app-day-reservations',
  templateUrl: './day-reservations.component.html',
  styleUrls: ['./day-reservations.component.scss'],
})
export class DayReservationsComponent implements OnInit {

  @Input() isDaySelected;
  @Input() hoursRange;
  @Input() calendarOptions;
  isReverseList = false;

  timeValues = [
    '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
    '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'
  ];

  constructor(
    public dateService: DateService,
    public modalService: ModalService,
    public bookingService: BookingService
  ) { }

  ngOnInit() { }

  reverseRooms() {
    this.isReverseList = !this.isReverseList;
    this.bookingService.roomsWithBookings.reverse();
  }

  openReservationModal(element: HTMLElement) {
    if (!element.dataset.booking) { return; };

    const bookingData = JSON.parse(element.dataset.booking);
    this.bookingService.reservationBookingData = bookingData;

    this.modalService.isMobileView
      ? this.modalService.openReservationModal(this.bookingService.reservationBookingData, false, false, this.calendarOptions)
      : this.modalService.openDesktopModal('ReservationModal');
  }

}
