import { Component, OnInit } from '@angular/core';
import { NavParams, NavController } from '@ionic/angular';

import { ModalService } from '../../../../services/modal/modal.service';
import { GroupService } from 'src/app/services/group/group.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-delete-group-modal',
  templateUrl: './delete-group-modal.component.html',
  styleUrls: ['./delete-group-modal.component.scss'],
})
export class DeleteGroupModalComponent implements OnInit {

  options = this.params.get('options');

  constructor(
    private modalService: ModalService,
    private groupService: GroupService,
    private params: NavParams,
    private navCtrl: NavController,
    private auth: AuthService,
    private events: EventsService
  ) { }

  ngOnInit() { }

  back() {
    this.modalService.dismiss();
  }

  async deleteGroup() {
    const response = await this.groupService.deleteGroup(this.options.groupId);
    this.checkCurrentError(false, response);
    this.filterGroupsForCreator();
    this.modalService.dismiss();
    this.navCtrl.navigateForward(['rooms'], { queryParams: { isUserReservations: false } });
  }

  async deleteBookingInGroup(groupId, bookingId) {
    const response = await this.groupService.deleteBookingInGroup(groupId, bookingId) as any;
    this.checkCurrentError(true, response);
    this.modalService.dismiss();
  }

  checkCurrentError(isDeleteBookingInGroup, response) {
    if (response.isError) {
      (response.message && response.message.includes('ongoing')) && this.openErrorModal(isDeleteBookingInGroup); 
      return;
    } else {
      response.message && this.openErrorModal(isDeleteBookingInGroup, false, response.message); 
    }
  }

  openErrorModal(isDeleteBookingInGroup, isDeleteUserInGroup = false, message = '') {
    this.modalService.dismiss();
    import('../ongoing-group-reservation-err-modal/ongoing-group-reservation-err-modal.component').then(m =>
      this.modalService.presentModal(m.OngoingGroupReservationErrModalComponent, 'auto-height notifications', {
        isDeleteBookingInGroup, isDeleteUserInGroup, message
      })
    );
  }

  filterGroupsForCreator() {
    this.auth.user.ownerGroupIds = this.auth.user.ownerGroupIds.filter(groupId => this.options.groupId !== groupId);
  }

}
