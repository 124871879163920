import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  defaultPagination = {
    currentPage: 1,
    totalPages: 1,
    event: null,
    isBuildingsSelected: false
  }

  currentPagination: {
    currentPage: number,
    totalPages: number,
    event?: any,
    isBuildingsSelected?: boolean
  } = {
      currentPage: 1,
      totalPages: 1,
      event: null,
      isBuildingsSelected: false
    }

  buildingsPaginationState = {
    currentPage: 1,
    totalPages: 1
  };

  constructor() { }

  resetPagination() {
    this.currentPagination = { ...this.defaultPagination };
  }
}
