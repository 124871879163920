import { Injectable } from '@angular/core';

import { LoaderService } from 'src/app/services/loader/loader.service';
import { RequestService } from 'src/app/services/request/request.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class LockService {

  constructor(
    private loaderService: LoaderService,
    private requestService: RequestService,
    private toastService: ToastService
  ) { }

  async unlockRoom(room, isAdmin = false) {
    await this.loaderService.presentLoader();

    try {
      const response = isAdmin
        ? await this.requestService.postAuthRequest(`admin/rooms/${room.id}/unlock`, {}).toPromise()
        : await this.requestService.postAuthRequest(`rooms/${room.id}/unlock`, {}).toPromise();

      room.lockStatus = 'unlocked';
      this.setLockStatus(room);
      this.loaderService.dismissLoader();
    } catch (error) {
      this.toastService.presentToast(error.message, 'error');
      room.lockStatus = 'locked';
      this.loaderService.dismissLoader();
    }
  }

  setLockStatus(room) {
    room.unlockedTime = room.lockStatus === 'unlocked' ? 30 : 0;
    if (room.lockStatus === 'unlocked') {
      const interval = setInterval(() => {
        room.unlockedTime -= 1;
        if (room.unlockedTime === 0) {
          room.lockStatus = 'locked';
          clearInterval(interval);
        }
      }, 1000);
    }
  }
}
