import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { StepSelectionService } from '../../../services/step-selection/step-selection.service';
import { ModalService } from '../../../../services/modal/modal.service';
import { BuildingService } from '../../../services/building/building.service';
import { RoomCreationService } from '../../../services/room-creation/room-creation.service';

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-choose-building',
  templateUrl: './choose-building.component.html',
  styleUrls: ['./choose-building.component.scss'],
})
export class ChooseBuildingComponent implements OnInit {

  inputsForm: UntypedFormGroup = new UntypedFormGroup({
    floor: new UntypedFormControl(''),
    roomNumber: new UntypedFormControl('')
  });

  constructor(
    public navController: NavController,
    public stepSelectionService: StepSelectionService,
    public modalService: ModalService,
    public buildingService: BuildingService,
    private formBuilder: UntypedFormBuilder,
    private roomCreationService: RoomCreationService
  ) { }

  async ngOnInit() {
    this.buildingService.clearState();
    await this.buildingService.getBuildings();
    this.initForm();
  }

  initForm() {
    this.inputsForm = this.formBuilder.group({
      floor: ['', Validators.compose([Validators.required])],
      roomNumber: ['', Validators.compose([Validators.required])]
    }, { updateOn: 'change' });
  }

  goToNextStep() {
    this.roomCreationService.roomData.floor = this.inputsForm.controls['floor'].value;
    this.roomCreationService.roomData.number = this.inputsForm.controls['roomNumber'].value;
    this.stepSelectionService.nextStep();
  }

}
