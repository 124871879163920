import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

import { RequestService } from '../../../services/request/request.service';
import { ModalService } from '../../../services/modal/modal.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { forkJoin, from } from 'rxjs';
import { concatMap, delay, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoomCreationService {

  imagesToUpload = [];
  localPlanPictures = [];
  localGalleryPictures = [];
  showFullHeader = false;

  createdRoom;

  roomData = {
    name: '',
    buildingId: 0,
    price: 0,
    chair: 0,
    description: '',
    floor: 0,
    number: 0,
    lockId: 0,
    serviceFee: {},
    facilities: []
  };

  awsBaseUrl = environment.awsBaseUrl;

  constructor(
    private requestService: RequestService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private toastService: ToastService
  ) { }

  async createRoom() {
    await this.loaderService.presentLoader();

    try {
      this.createdRoom = await this.requestService.postAuthRequest('rooms', this.roomData).toPromise();
      await this.uploadImagesToAws(this.createdRoom);
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  imagesKeysRequest(room) {
    return this.requestService.getRequest(`file-uploader?path=rooms/${room.id}`) as any;
  }

  awsUploadRequest(uploadUrl, file) {
    return this.requestService.uploadRequest(uploadUrl, file);
  }

  async uploadImagesToAws(room) {

    try {
      const fileUploaderResponse = await from(this.imagesToUpload.map(() => this.imagesKeysRequest(room))).pipe(
        concatMap(item => item.pipe(delay(100))),
        toArray()
      ).toPromise();

      const dataToUpload = this.createDataToUpload(fileUploaderResponse);
      await forkJoin(dataToUpload.map((img) => this.awsUploadRequest(img.uploadURL, img.file))).toPromise();
      await this.uploadImagesToServer(fileUploaderResponse, room.id);
    } catch (err) {
      console.log(err);
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  async uploadImagesToServer(fileUploaderResponse, roomId) {
    const keys = fileUploaderResponse.map((imgData: any) => imgData.Key.split('/')[2]);

    const images = this.imagesToUpload.map((image, index) => {
      return { type: image.photoFile.type, storage: keys[index] };
    });
    images[0] = { type: 'cover', storage: images[0].storage };

    try {
      await this.requestService.postAuthRequest('images', { roomId, images }).toPromise();
      this.loaderService.dismissLoader();
      this.closeModal();
    } catch (err) {
      console.log(err);
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
    }
  }

  createDataToUpload(fileUploaderResponse) {
    const dataToUpload = this.imagesToUpload.map((img, index) => {
      return { file: img.photoFile.file, uploadURL: fileUploaderResponse[index].uploadURL }
    });

    return dataToUpload;
  }

  closeModal() {
    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      import('../../components/all-done-modal//all-done-modal.component').then(m =>
        this.modalService.presentModal(m.AllDoneModalComponent, 'auto-height')
      )
    } else {
      this.modalService.closeDesktopModal('RoomModal');
      this.modalService.openDesktopModal('AllDoneModal');
    }

    this.clearState();
  }

  putPicturesLocally(pictures, result, name) {
    pictures.push({ data: result, name: name });
  }

  deletePictures(localPics, picsFiles, picture) {
    this[localPics] = this[localPics].filter(item => item !== picture);
    this[picsFiles] = this[picsFiles].filter(item => item.name !== picture.name);
  }

  putFiles(files, file) {
    files.push({ photoFile: file, name: file.file.name });
  }

  clearState() {
    this.roomData.facilities = [];
    this.roomData.chair = 0;
    this.localPlanPictures = [];
    this.localGalleryPictures = [];
    this.imagesToUpload = [];
    this.roomData.buildingId = 0;
  }
}
