import { Injectable } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { StripeService } from 'ngx-stripe';
import { PaymentRequestPaymentMethodEvent, StripeElementsOptions } from '@stripe/stripe-js';
import { Stripe } from '@capacitor-community/stripe';

import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { RequestService } from 'src/app/services/request/request.service';
import { BookingService } from 'src/app/services/booking/booking.service';
import { DateService } from 'src/app/services/date/date.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  clientSecret;
  paymentResult;
  totalPrice: number;

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  paymentData = {
    roomId: '',
    paymentType: 'STRIPE',
    bookingStart: '',
    bookingEnd: '',
    coupon: ''
  };

  // Options for payment request button
  paymentRequestOptions = null;

  constructor(
    private loaderService: LoaderService,
    private requestService: RequestService,
    private toastService: ToastService,
    private bookingService: BookingService,
    private modalCtrl: ModalController,
    private dateService: DateService,
    private stripeService: StripeService,
    private modalService: ModalService
  ) {
    Stripe.initialize({
      publishableKey: 'pk_live_51J5AwWBUUjkn5Dav00AEHyveUqVAkZwq0a8yemVauGP3x8WF1eothe5RHYCEWB67VYO3zlhfajRfGgCphey13HwA00XcVnVeN4',
    });
  }

  async createPaymentIntent() {
    await this.loaderService.presentLoader();
    if (this.isCurrentBookingEqualsPrevious()) { this.loaderService.dismissLoader(); return; }

    try {
      const response = await this.bookingRequest();
      this.elementsOptions.clientSecret = response.clientSecret;
      this.paymentRequestOptions.total.amout = response.totalPrice / 100;
      this.totalPrice = response.totalPrice / 100;
      this.clientSecret = response.clientSecret;
      this.loaderService.dismissLoader();

      const expiringTimeDueProcessing = new Date().getTime() + response.expiringTimeDueProcessing;
      this.saveBooking(response.id, response.bookingStart, response.bookingEnd, response.roomId, expiringTimeDueProcessing);
      return response;
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      return null;
    }
  }

  bookingRequest(): any {
    return this.requestService.postAuthRequest('bookings', this.paymentData).toPromise();
  }

  dismissLoaderAndModal() {
    this.modalCtrl.dismiss();
    this.loaderService.dismissLoader();
  }

  setBookingTime() {
    this.paymentData.roomId = this.bookingService.room.id;
    if (this.bookingService.multiDatesArr.length) {
      this.paymentData.bookingStart = this.dateService.formatDate(new Date(this.bookingService.multiDatesArr[0].time), '6 AM');
      this.paymentData.bookingEnd = this.dateService.formatDate(new Date(this.bookingService.multiDatesArr[1].time), '12 AM');
    } else {
      this.paymentData.bookingStart = this.dateService.formatDate(this.bookingService.date, this.bookingService.timeStart);
      this.paymentData.bookingEnd = this.dateService.formatDate(this.bookingService.date, this.bookingService.timeEnd);
    }
  }

  // if user comes to payments with the same booking data as was before
  isCurrentBookingEqualsPrevious() {
    const bookingData = JSON.parse(localStorage.getItem('smartLockBooking'));
    if (!bookingData) { return; }
    if (this.isTimeOfProcessingPaymentExpired(bookingData.expiringTimeDueProcessing)) { return; }

    const isPropertiesEqual = Object.keys(bookingData).filter(key => bookingData[key] === this.paymentData[key]).length === Object.keys(bookingData).length - 1;
    return isPropertiesEqual;
  }

  isTimeOfProcessingPaymentExpired(expiringTime) {
    const timeNow = new Date().getTime();
    if (!expiringTime) { return false; }
    if (timeNow > expiringTime) { localStorage.removeItem('smartLockBooking'); return true; }
  }

  // Ios platform Apple Pay start of methods
  async applePayFlow() {
    await this.loaderService.presentLoader();
    await this.createApplePay(this.clientSecret);
  }

  async createApplePay(clientSecret) {
    try {
      await Stripe.createApplePay({
        paymentIntentClientSecret: clientSecret,
        paymentSummaryItems: [{ label: 'for booking a room', amount: this.totalPrice }],
        merchantIdentifier: 'merchant.com.smartoffice.ora',
        countryCode: 'US',
        currency: 'USD'
      });
      await this.presentApplePay();
    } catch (error) {
      this.toastService.presentToast(error.message, 'error');
      this.loaderService.dismissLoader();
      console.log(error);
    }
  }

  async presentApplePay() {
    try {
      const response = await Stripe.presentApplePay();

      // Webhook will update booking
      if (response.paymentResult === 'applePayCompleted') {
        this.bookingService.reset();
        this.loaderService.dismissLoader();
        this.openConfirmedModal();
      } else {
        this.toastService.presentToast(response.paymentResult);
        this.loaderService.dismissLoader();
      }
    } catch (err) {
      this.toastService.presentToast(err.message, 'error');
      this.loaderService.dismissLoader();
      console.log(err);
    }
  }
  // Ios platform Apple Pay end of methods


  // Web payment methods start (Apple pay, Google pay)
  async paymentMethodWeb(ev: PaymentRequestPaymentMethodEvent) {
    await this.loaderService.presentLoader();
    await this.confirmCardPayment(this.clientSecret, ev);
  }

  async confirmCardPayment(clientSecret, event) {
    try {
      const confirmationResponse = await this.stripeService.confirmCardPayment(
        clientSecret,
        { payment_method: event.paymentMethod.id },
        { handleActions: false }
      ).toPromise();

      if (confirmationResponse) {
        event.complete('success');
        this.loaderService.dismissLoader();
        this.bookingService.reset();
        this.openConfirmedModal();
      }
      // Webhook will update booking
    } catch (err) {
      event.complete('fail');
      this.toastService.presentToast(err.error, 'error');
      this.loaderService.dismissLoader();
    }
  }
  // Web payment methods end (Apple pay, Google pay)

  openConfirmedModal() {
    this.modalService.isConfirmedModalOpened = true;
    import('../../components/booking-confirmed/booking-confirmed.component').then(m =>
      this.modalService.presentModal(m.BookingConfirmedComponent, 'auto-height notifications')
    );
  }

  // Stripe modal component cc payment method

  async stripeModalPayment(paymentElement) {
    await this.loaderService.presentLoader();

    try {
      this.paymentResult = await this.stripeService.confirmPayment({
        elements: paymentElement.elements,
        confirmParams: { payment_method_data: {} },
        redirect: 'if_required'
      }).toPromise();

      if (this.paymentResult.paymentIntent.status === 'succeeded') {
        this.bookingService.reset();
        this.dismissLoaderAndModal();
        this.openConfirmedModal();
      };
    } catch (err) {
      this.loaderService.dismissLoader();
      console.log(err, this.paymentResult.error)

      if (this.paymentResult.error) {
        this.toastService.presentToast(this.paymentResult.error.message, 'error');
      } else {
        this.toastService.presentToast(err.message, 'error');
      }
    }
  }

  saveBooking(bookingId, bookingStart, bookingEnd, roomId, expiringTimeDueProcessing) {
    const booking = {
      id: bookingId,
      bookingStart, bookingEnd, roomId, expiringTimeDueProcessing,
      room: this.bookingService.room,
      timeStart: this.bookingService.timeStart,
      timeEnd: this.bookingService.timeEnd,
      date: this.bookingService.date,
      members: this.bookingService.persons
    };
    localStorage.setItem('smartLockBooking', JSON.stringify(booking));
  }

  resetPaymentData() {
    this.paymentData = {
      roomId: '',
      paymentType: 'STRIPE',
      bookingStart: '',
      bookingEnd: '',
      coupon: ''
    };
  }

}
