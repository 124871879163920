import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { BookingService } from 'src/app/services/booking/booking.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class RefundGuard implements CanActivate {

  constructor(
    private router: Router,
    private bookingService: BookingService,
    private loaderService: LoaderService
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<any> {
    await this.loaderService.presentLoader();

    try {
      await this.bookingService.getBookings();
      this.loaderService.dismissLoader();
    } catch (err) {
      this.router.parseUrl('/search');
      this.loaderService.dismissLoader();
    }
    return Object.keys(this.bookingService.bookedRoomsResponse).length || Object.values(this.bookingService.bookedGroupsData.bookings).length
      ? true 
      : this.router.parseUrl('/search');
  }

}
