import { Component, OnInit } from '@angular/core';

import { ModalService } from '../../../services/modal/modal.service';
import { RoomsService } from 'src/app/services/rooms/rooms.service';
import { BuildingService } from '../../services/building/building.service';
import { PaginationService } from '../../../services/pagination/pagination.service';
import { EventsService } from '../../../services/events/events.service';

@Component({
  selector: 'app-offices-filters-mobile-modal',
  templateUrl: './offices-filters-mobile-modal.component.html',
  styleUrls: ['./offices-filters-mobile-modal.component.scss'],
})
export class OfficesFiltersMobileModalComponent implements OnInit {

  roomsFilterVal;
  buildingIds;
  checkBoxes;
  selectedText = 'Select buildings';

  constructor(
    private modalService: ModalService,
    public roomsService: RoomsService,
    public buildingService: BuildingService,
    private pagination: PaginationService,
    private events: EventsService
  ) { }

  ngOnInit() {
    this.selectedText = this.setSelectedTextBuildingFilter();
  }

  setSelectedTextBuildingFilter() {
    if (this.buildingService.idsToPreSelect) {
      if (this.buildingService.idsToPreSelect.length === this.buildingService.buildingListNames.length) { return 'All buildings'; }
      return this.buildingService.buildingListNames.filter(building => this.buildingService.idsToPreSelect.find(id => id === building.id)).map(building => building.name).join(', ');
    } else {
      return 'Select buildings';
    }
  }

  close(isBack = false) {
    if (isBack && !this.buildingService.filterApplied) {
      this.buildingIds = null;
      this.buildingService.idsToPreSelect = null;
      this.buildingService.filterApplied = false;
    };
    this.modalService.dismiss();
  }

  setBuildingIds(data) {
    this.buildingIds = data;
    this.buildingService.idsToPreSelect = this.buildingIds;
  }

  onSelectChange(event) {
    this.roomsFilterVal = event.detail.value;
  }

  async applyFilters() {
    if (!this.roomsFilterVal && !this.buildingIds) { this.close(); return; }

    this.buildingService.filterApplied = true;
    this.pagination.resetPagination();

    let query = (this.buildingIds ? this.buildingIds : this.buildingService.idsToPreSelect).map(buildingId => `buildingId[]=${buildingId}&`).join('');
    this.buildingService.activeRoomsFilterValue = this.roomsFilterVal ? this.roomsFilterVal : this.buildingService.activeRoomsFilterValue;

    this.buildingService.buildingAdminRooms = await this.buildingService.filterRequest(query, null, null, false, true);
    this.events.onFilterChange({ buildingIds: this.buildingIds ? this.buildingIds : this.buildingService.idsToPreSelect, filterValue: this.buildingService.activeRoomsFilterValue, isMobile: true });
    this.close();
  }
}
