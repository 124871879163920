/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  // baseUrl = 'http://localhost:3000/';
  baseUrl = environment.baseUrl;
  isAdmin = false;

  authToken = localStorage.getItem('smartLockToken');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    Authorization: `Bearer ${this.authToken}`
  });

  constructor(
    private http: HttpClient
  ) { }

  public getRequest(path) {
    return this.http.get(this.baseUrl + path).pipe(retry(3), catchError(this.handleError));
  }

  public getAuthRequest(path, page?: number) {
    let params = {};

    if (page) { params = { page }; };

    return this.http.get(this.baseUrl + path, { params, headers: this.authHeaders() }).pipe(catchError(this.handleError));
  }

  public postRequest(path, data) {
    return this.http.post(this.baseUrl + path, data).pipe(catchError(this.handleError));
  }

  public postAuthRequest(path, data) {
    return this.http.post(this.baseUrl + path, data, { headers: this.authHeaders() }).pipe(catchError(this.handleError));
  }

  public uploadRequest(path, data) {
    return this.http.put(path, data).pipe(catchError(this.handleError));
  }

  public putAuthRequest(path, data) {
    return this.http.put(this.baseUrl + path, data, { headers: this.authHeaders() }).pipe(catchError(this.handleError));
  }

  public patchRequest(path, data) {
    return this.http.patch(this.baseUrl + path, data).pipe(catchError(this.handleError));
  }

  public patchAuthRequest(path, data) {
    return this.http.patch(this.baseUrl + path, data, { headers: this.authHeaders() }).pipe(catchError(this.handleError));
  }

  public deleteAuthRequest(path, data = {}) {
    const options = {
      headers: this.authHeaders(),
      body: data,
    };
    return this.http.delete(this.baseUrl + path, options).pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = error.error;
    }
    // Handle errors
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

  accessToken() {
    return JSON.parse(localStorage.getItem('smartLockUser'))?.accessToken;
  }

  authHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.accessToken()}`
    });
  }
}
