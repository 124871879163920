/* eslint-disable max-len */
export const environment = {
  production: true,
  awsBaseUrl: 'https://smart-office-production.s3.us-west-1.amazonaws.com/',
  baseUrl: 'https://conference-room-rental.herokuapp.com/',
  redirectURI: 'https://smart-office-ora.firebaseapp.com/__/auth/handler',
  firebase: {
    apiKey: "AIzaSyDxs1tScSXxHoaGNhSN08crcDXlJN8KIaI",
    authDomain: "smart-office-ora.firebaseapp.com",
    projectId: "smart-office-ora",
    storageBucket: "smart-office-ora.appspot.com",
    messagingSenderId: "403479322235",
    appId: "1:403479322235:web:8f2a0f9a72d85d86800cde",
    measurementId: "G-RWFNSCQ4B0",
    vapidKey: "BLqilg0p3Q7pAmIwe5jR7c7L-4HMnh8G8RZ1qsuq0NNoAiHByqJQzQqT9T486M_j2zz_ngrDI0ttJJmtGsiAPiw"
  }
};
