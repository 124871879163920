import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { GroupService } from '../../../../services/group/group.service';
import { ModalService } from '../../../../services/modal/modal.service';
import { SelectService } from 'src/app/services/select/select.service';

import { NavParams } from '@ionic/angular';
import { MatOption } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-group-name-adding-modal',
  templateUrl: './group-name-adding-modal.component.html',
  styleUrls: ['./group-name-adding-modal.component.scss'],
})
export class GroupNameAddingModalComponent implements OnInit {

  @ViewChild('allSelected') allSelected: MatOption;

  groupsForm: FormGroup;
  counterVal = 30;
  clearInputRequired = false;

  options = this.params.get('options');
  isGroupExist;
  selectedGroupsNamesStr = '';
  isAllGroupsWereSelected = false;
  isFromBookingConfirmed

  constructor(
    public groupService: GroupService,
    private modalService: ModalService,
    private params: NavParams,
    private fb: FormBuilder,
    public selectService: SelectService
  ) { }

  async ngOnInit() {
    this.groupsForm = this.fb.group({
      groupIds: new FormControl('')
    });
    
    this.isGroupExist = this.options ? this.options.isGroupExists : false;
    this.isFromBookingConfirmed = this.options ? this.options.fromBookingConfirmed : false;

    if (!this.isGroupExist) { return; }
    await this.checkAlreadySelectedGroups();
  }

  async checkAlreadySelectedGroups() {
    if (this.groupService.fromRoomsPage || this.isFromBookingConfirmed) {
      const currentSelectedGroups = await this.groupService.getGroupsByBooking() as Array<Object>;
      this.isAllGroupsWereSelected = currentSelectedGroups.length === this.groupService.groups.length;
      this.groupService.groups.filter(group => currentSelectedGroups.some((g: any) => g.id === group.id)).forEach(group => group.disabled = true);

      if (!this.isFromBookingConfirmed) { return; } 
      this.selectedGroupsNamesStr = this.options.selectedGroupsNamesStr.includes('Select All') ? 'All groups' : this.options.selectedGroupsNamesStr;
      this.groupsForm.controls.groupIds.patchValue(currentSelectedGroups.map((selectedGroup: any) => selectedGroup.id));
    };
  }

  back() {
    if (this.isFromBookingConfirmed) {
      this.modalService.dismiss();
      import('../../../booking-confirmed/booking-confirmed.component').then(m =>
        this.modalService.presentModal(m.BookingConfirmedComponent, 'auto-height notifications', {
          selectedGroupsNamesStr: this.options.selectedGroupsNamesStr
        })
      );
      return;
    }

    this.groupService.clearState();
    this.modalService.dismiss();
    if (this.groupService.fromRoomsPage && this.groupService.fromRoomsGroupCreation) { return; }
    import('../group-notification-modal/group-notification-modal.component').then(m =>
      this.modalService.presentModal(m.GroupNotificationModalComponent, 'auto-height notifications')
    );
  }

  openAddingMembersModal() {
    this.modalService.dismiss();
    import('../group-members-adding-modal/group-members-adding-modal.component').then(m =>
      this.modalService.presentModal(m.GroupMembersAddingModalComponent, 'auto-height notifications', { isResizeRequired: true })
    );
  }

  selectedValue(event: MatSelectChange) {
    this.selectedGroupsNamesStr = event.source.triggerValue;
  }

  async addGroupToBooking() {
    this.groupService.groupsToBooking = this.groupsForm.controls.groupIds.value.filter(id => id !== 0);
    const response = await this.groupService.addGroupsToBooking() as Array<Object>;
    this.groupService.groupMembersNum = response.reduce((acc, curr: any) => acc + curr.userCount, 0);
    response && this.openNextModal();
  }

  openNextModal() {
    this.modalService.dismiss();
    if (this.groupService.fromRoomsPage) {
      this.groupService.clearState();
      this.groupService.clearBoolState();
      return;
    }
    import('../../../../components/booking-confirmed/booking-confirmed.component').then(m =>
      this.modalService.presentModal(m.BookingConfirmedComponent, 'auto-height notifications',
        { selectedGroupsNamesStr: this.selectedGroupsNamesStr }
      )
    );
  }

  clearSelection() {
    this.isFromBookingConfirmed
      ? this.groupsForm.controls.groupIds.patchValue(this.groupService.groups.filter(group => group.disabled).map(group => group.id)) 
      : this.groupsForm.controls.groupIds.patchValue([]);
  }

  clearState() {
    this.clearSelection();
    this.clearInputRequired = true;
  }
}
