import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { ModalService } from '../../../services/modal/modal.service';
import { RequestService } from '../../../services/request/request.service';
import { BuildingService } from '../../services/building/building.service';
import { GoogleMapsService } from '../../services/google-maps/google-maps.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { DateService } from '../../../services/date/date.service';
import { LoaderService } from '../../../services/loader/loader.service';
import { EventsService } from '../../../services/events/events.service';
import { PaginationService } from '../../../services/pagination/pagination.service';
import { ImageConversionService } from '../../../services/image-conversion/image-conversion.service';

import { environment } from 'src/environments/environment.prod';
import { Validators, UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-building-creation-modal',
  templateUrl: './building-creation-modal.component.html',
  styleUrls: ['./building-creation-modal.component.scss'],
})
export class BuildingCreationModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  @ViewChild('searchInput') searchInput;

  localBuildingPictures = [];
  pictureLink;
  awsBaseUrl = environment.awsBaseUrl;

  status;
  file;
  pictureToAdd;

  showTimeZonesList = false;
  chosenTimeZone;
  chosenTimeZoneNum;


  managersToShow = [];

  building;

  buildingForm: UntypedFormGroup = new UntypedFormGroup({
    fullAddress: new UntypedFormControl(''),
    buildingName: new UntypedFormControl(''),
    timeZone: new UntypedFormControl(''),
  });

  constructor(
    public modalService: ModalService,
    private requestService: RequestService,
    public maps: GoogleMapsService,
    private toastService: ToastService,
    public dateService: DateService,
    private formBuilder: UntypedFormBuilder,
    public buildingService: BuildingService,
    private loaderService: LoaderService,
    private events: EventsService,
    private pagination: PaginationService,
    private imgConversion: ImageConversionService
  ) { }

  ngOnInit() {
    this.pagination.buildingsPaginationState = {
      currentPage: this.pagination.currentPagination.currentPage,
      totalPages: this.pagination.currentPagination.totalPages
    };
  }

  setWrapperStatus(status) {
    this.status = status;
  }

  initForm() {
    this.buildingForm = this.formBuilder.group({
      fullAddress: ['', Validators.compose([Validators.required])],
      buildingName: ['', Validators.compose([Validators.required])],
      timeZone: ['', Validators.compose([Validators.required])]
    }, { updateOn: 'change' });
  }

  convertPictureFromInput(event) {
    this.file = event.target.files[0];
    const type = this.file.type;
    if (this.file.size > 10000000) { this.toastService.presentToast('Maximum upload file size 10 MB', 'error'); event.target.value = ''; return; }
    const reader = new FileReader();

    reader.onload = async (ev) => {
      if (this.imgConversion.isHeicImgType(type)) {
        const conversionResponse = await this.imgConversion.convertHeic2Jpeg(reader, this.file.name);
        this.putPicturesLocally(this.localBuildingPictures, conversionResponse[1], this.file.name);
        this.file = conversionResponse[0];
        this.pictureToAdd = this.file;
        this.setWrapperStatus(false);
      } else {
        this.putPicturesLocally(this.localBuildingPictures, reader.result, this.file.name);
        this.pictureToAdd = this.file;
        this.setWrapperStatus(false);
      }
    }

    reader.readAsDataURL(this.file);
    event.target.value = '';
  }

  putPicturesLocally(pictures, result, name) {
    pictures.push({ data: result, name: name });
  }

  deletePictures(localPics, picture) {
    this[localPics] = this[localPics].filter(item => item !== picture);
    this.pictureToAdd = null;
  }

  toggleIcon() {
    this.showTimeZonesList = !this.showTimeZonesList;
  }

  getChosenZone(event, timeZoneNum) {
    this.chosenTimeZone = event.target.innerText;
    this.chosenTimeZoneNum = timeZoneNum;
    this.showTimeZonesList = !this.showTimeZonesList;
  }

  addManagerLocally(manager, managersToShow) {
    managersToShow.push(manager);
    this.buildingService.managersToAdd = managersToShow;
    this.searchInput.setFocus();
    this.clearInputValueCloseDropdown();
  }

  deleteManagerLocally(manager, managersToShow) {
    const index = managersToShow.indexOf(manager);
    index > -1 && managersToShow.splice(index, 1);

    this.clearInputValueCloseDropdown();
  }

  clearInputValueCloseDropdown() {
    this.searchInput.value = '';
    this.buildingService.showManagersList = false;
  }

  async uploadPicture(buildingId) {
    const response = await this.getUploadUrl(buildingId);

    if (response) {
      try {
        await this.requestService.uploadRequest(response.uploadURL, this.pictureToAdd).toPromise();
        return response.Key.split('/')[2];
      } catch (err) {
        this.loaderService.dismissLoader();
        this.toastService.presentToast(err.message, 'error');
      }
    }
  }

  async getUploadUrl(buildingId) {
    try {
      return await this.requestService.getRequest(`file-uploader?path=buildings/${buildingId}`).toPromise() as any;
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      return null;
    }
  }

  async buildingCreationWithImage() {
    const buildingRequest = this.requestService.postAuthRequest('buildings', this.maps.buildingData);

    try {
      const imageLink = await buildingRequest.pipe(
        switchMap((building: any) => {
          this.building = building;
          return this.building && this.uploadPicture(this.building.id)
        })).toPromise();
      return imageLink;
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      return null;
    }
  }

  async updateImageCreateBuildingAdmins(pictureLink) {
    const updateBuildingRequest = this.buildingService.updateBuilding(this.building.id, { picture: pictureLink }) as any;

    const body = this.buildingService.managersToAdd && this.buildingService.managersToAdd.map(manager => {
      return { userId: manager.id, buildingId: this.building.id }
    });

    const createManagersRequest = this.requestService.postAuthRequest('building-admins', body);

    try {
      const response = this.buildingService.managersToAdd ? await forkJoin([updateBuildingRequest, createManagersRequest]).toPromise() : await updateBuildingRequest;
      this.loaderService.dismissLoader();
      this.closeModal(true);
    } catch (err) {
      this.loaderService.dismissLoader();
      this.toastService.presentToast(err.message, 'error');
      return null;
    }
  }

  async onSubmit(data) {
    this.maps.buildingData.name = data.buildingName;
    this.maps.buildingData.timeZone = this.chosenTimeZoneNum;

    await this.loaderService.presentLoader();
    const imageLink = await this.buildingCreationWithImage();
    imageLink && await this.updateImageCreateBuildingAdmins(imageLink);
  }

  closeModal(afterBuildingCreationRequest = false) {
    this.buildingService.clearManagersState();
    this.setPrevPaginationState();

    if (this.modalService.isMobileView) {
      this.modalService.dismiss();
      afterBuildingCreationRequest && this.events.onCloseModal(false);
    } else {
      this.modalService.closeDesktopModal('BuildingCreationModal');
      this.close.emit();
    }
  }

  closeDesktopModal() {
    this.setPrevPaginationState();
    this.buildingService.clearManagersState();
    this.modalService.closeDesktopModal('BuildingCreationModal');
  }

  setPrevPaginationState() {
    this.pagination.currentPagination.currentPage = this.pagination.buildingsPaginationState.currentPage;
    this.pagination.currentPagination.totalPages = this.pagination.buildingsPaginationState.totalPages;
    this.pagination.currentPagination.isBuildingsSelected = true;
  }

  back() {
    this.modalService.dismiss();
    this.buildingService.clearManagersState();
  }
}
