import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loader: any;
  isLoaderPresented = false;

  constructor(
    public loadingController: LoadingController
  ) { }

  async presentLoader() {
    this.loader = await this.loadingController.create({
      message: 'Loading..',
    });
    this.isLoaderPresented = true;
    return this.loader.present();
  }

  async dismissLoader() {
    return this.loader?.dismiss();
  }
}
