import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InputSearchService {

  isInputChange = false;

  constructor() { }

  onKey(inputValue, listToFilter) {
    if (!inputValue.length) { this.isInputChange = false; return; }

    this.isInputChange = true;
      return listToFilter.filter(listItem => {
        return listItem.name.split(' ').some((word: any) => {
          return inputValue.toLowerCase().split(' ').some((eventWord: any) => {
            if (word.toLowerCase().includes(eventWord) && eventWord.length) {
              return true;
            }
          });
        });
      });
    }
}
